<template>
    <main>
        <phone_head title="過去の入力" :path="{path:'/report/list/',query: {property_id: this.$route.query.property_id}}"></phone_head>
        <div class="wrapper">
            <div class="input_overview">
                <p>　日付：{{ info.date }}</p>
                <p>担当者：{{ info.person }}</p>
                <p>物件名：{{ info.name }}</p>
            </div>
            <!-- 施設項目 -->
            <ul id="item_info" v-for="(institution,key) in institutions" :key="institution">
                <input_item :institution="institution" :past_institution="past_institutions[key]"
                    :past_report_check="past_report_flag"></input_item>
            </ul>
            <!-- その他写真 -->
            <div class="title" @click="flg_change('others_photo_flg')">
                <h2>その他写真</h2>
                <button class="flg_btn">{{others_photo_flg_text}}</button>
            </div>
            <div v-if="others_photo_flg">
                <ul>
                    <li v-for="photo of others_photo" :key="photo">
                        <img :src='photo'>
                    </li>
                </ul>
            </div>
            <!-- 共有事項 -->
            <div class="title margin" @click="flg_change('share_flg')">
                <h2>共有事項</h2>
                <button class="flg_btn">{{share_flg_text}}</button>
            </div>
            <div v-if="share_flg">
                <ul>
                    <li v-for="(share_item,index) of share" :key="share_item">
                        <p>写真{{index+1}}</p>
                        <img :src="share_item">
                    </li>
                </ul>
                <p>{{share_text}}</p>
                <h3>社内向け共有事項</h3>
                <p>{{internal_memo}}</p>
            </div>
            <!-- ボタン -->
            <div id="btn_list">
                <button class="orange_btn" @click="update_report()">修正する</button>
                <button class="back_btn" @click="this.$router.push({path: '/report/list',query:{ property_id : this.$route.query.property_id}})">戻る</button>
            </div>
        </div>
    </main>
</template>
<script scoped>
import { Mixin } from "../mixin";
import input_item from "./report_input_past_component.vue"
import phone_head from "./phone_header.vue"
import { db } from "../db";
import Dexie from 'dexie'

export default ({
    mixins: [Mixin],
    components: {
        input_item,
        phone_head
    },
    data() {
        return {
            info: {},
            institutions: {},
            past_institutions: {},
            flg_btn_text: "＋",
            others_photo: [],
            others_photo_flg: false,
            others_photo_flg_text: "＋",
            share: [],
            share_text: '',
            share_flg: false,
            share_flg_text: "＋",
            photo_num: 0,
            past_photo_num: 0,
            past_report_flag: true,
        }
    },
    methods: {
        flg_change(flg) {
            this[flg] = !this[flg]
            if (this[flg + '_text'] == "＋") {
                this[flg + '_text'] = "－";
            } else {
                this[flg + '_text'] = "＋";
            }
        },
        async update_report() {
            db.delete()
            db.open()
            try {
                // Put the report_info
                await db.report_db.put({
                    info:Dexie.deepClone(this.master_data.report.info),
                    institutions: Dexie.deepClone(this.master_data.report.institutions),
                    others_photo: {
                        photo:Dexie.deepClone(this.master_data.report.others_photo),
                        photo_name:[]
                    },
                    share: {photo:Dexie.deepClone(this.master_data.report.share.photo),
                            photo_name:[],
                            text:this.master_data.report.share.text,
                    },
                    status: this.master_data.report.status,
                    internal_memo: this.internal_memo
                });
                this.$router.push({ path: '/report/input/update', query: { property_id:this.$route.query.property_id,report_id:this.$route.query.report_id}});
            } catch (error) {
                console.log(error)
            }
        },

    },
    created: async function () {
        const param = {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + this.$store.state.login_token,
            }
        };
        // 報告書情報取得
        const res = await fetch(process.env.VUE_APP_API_BASE_URL + "/report/" + this.$route.query.property_id + this.$route.query.report_id, param)
        if (res.status == 200) {
            var data = await res.json()
            var json_data = JSON.stringify(data)
            this.master_data = JSON.parse(json_data)
            // console.log(this.master_data)
            // console.log(data);
            this.info = data.report.info;
            // 施設ごとの処理
            for (let institution in data.report.institutions) {
                var institution_flg = false
                // 1要素ごとの処理
                for (let i = 0; i < data.report.institutions[institution].items.length; i++) {
                    // 報告書に記載の場合写真番号をインクリメント
                    if (data.report.institutions[institution].items[i].select) {
                        data.report.institutions[institution].items[i].photo_num = this.photo_num
                        this.photo_num++
                    }
                    // 全要素が空でない時表示させる
                    if (data.report.institutions[institution].items[i].photo || data.report.institutions[institution].items[i].text || data.report.institutions[institution].items[i].select
                        || data.report.institutions[institution].items[i].phrase) {
                        institution_flg = true;
                    }
                }
                // メモが空でない時表示させる
                if (data.report.institutions[institution].memo) {
                    institution_flg = true
                }
                // 全要素、メモが空でなければ表示させる
                if (institution_flg) {
                    this.institutions[institution] = data.report.institutions[institution]
                }
            }
            this.others_photo = data.report.others_photo
            this.share_text = data.report.share.text
            // internal_memoプロパティがない（既存の報告書）場合の処理
            if(!data.report.hasOwnProperty('internal_memo')){
                this.internal_memo = ''
            }else{
                this.internal_memo = data.report.internal_memo
            }
            
            this.share = data.report.share.photo
            // 共有事項写真に写真番号を付与

            // 前回の報告書情報取得
            this.last_report_info();

        } else if (res.status == 401) {
            this.unauth()
        } else {
            this.other_error(res.status)
        }
    },
})
</script>

<style scoped>
.orange_btn {
    width: 40%;
    margin: 10px 10px 10px 5px;
}
.back_btn{
    color: #fff;
    background-color: #1a3f66ff;
    border-radius: 10px;
    padding: 10px 0;
    width: 40%;
    border: none;
    margin-top: 30px;
}

.wrapper {
    width: 90%;
}

h2 {
    margin: 0;
    padding: 10px 0;
    width: 100%;
    color: #fff;
}

img {
    width: 100%;
}

.title {
    display: flex;
    background-color: #1a3f66ff;
    position: relative;
}

.flg_btn {
    font-size: 40px;
    background-color: #1a3f66ff;
    border: none;
    color: #fff;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 0;
    height: 56px;
    width: 56px;
}

ul {
    padding: 0
}

li {
    list-style: none;
}

a {
    text-decoration: none;
}

.margin {
    margin-top: 15px;
}
</style>