<template>
<div class="margin_top">
    <table style="border-collapse:collapse; width:100%; text-align: center;">
        <!-- 施設名 -->
        <tr>
            <th colspan="2" style="text-align: center;" class="fontsize">{{ institution.ja_name }}</th>
        </tr>
        <!-- 一枚目、二枚目 -->
        <tr id="photo_items">
            <td class="photo_item" v-for="img of institutions_12.items" :key="img">
                <img width="200" :src=img.photo>
                <p style="text-align: center">{{ phrase_ja_name(img.phrase) }}</p>
                <p style="text-align: center">{{ img.text }}</p>
            </td>
            <td v-if="photo_null_12"></td>
        </tr>
        <!-- 三枚目、四枚目 -->
        <tr id="photo_items">
            <td class="photo_item" v-for="img of institutions_34.items" :key="img">
                <img width="200" :src=img.photo>
                <p style="text-align: center">{{ phrase_ja_name(img.phrase) }}</p>
                <p style="text-align: center">{{ img.text }}</p>
            </td>
            <td v-if="photo_null_34"></td>
        </tr>
        <!-- 五枚目、六枚目 -->
        <tr id="photo_items">
            <td class="photo_item" v-for="img of institutions_56.items" :key="img">
                <img width="200" :src=img.photo>
                <p style="text-align: center">{{ phrase_ja_name(img.phrase) }}</p>
                <p style="text-align: center">{{ img.text }}</p>
            </td>
            <td v-if="photo_null_56"></td>
        </tr>
        <!-- 七枚目、八枚目 -->
        <tr id="photo_items">
            <td class="photo_item" v-for="img of institutions_78.items" :key="img">
                <img width="200" :src=img.photo>
                <p style="text-align: center">{{ phrase_ja_name(img.phrase) }}</p>
                <p style="text-align: center">{{ img.text }}</p>
            </td>
            <td v-if="photo_null_78"></td>
        </tr>
    </table>
    <!-- 改ページ用タグ -->
    <!-- <div class="pdf-pagebreak-before" style="color:white;">aaa</div> -->
</div>
</template>

<script scoped>

export default ({
props: {
    institution:{
    },
},
data() {
    return {
        institutions_12:{},
        institutions_34:{},
        institutions_56:{},
        institutions_78:{},
        photo_null_12:false,
        photo_null_34:false,
        photo_null_56:false,
        photo_null_78:false,
    }
},
methods:{
    phrase_ja_name(phrase_num) {
            switch (phrase_num) {
                case "0":
                    return "異常なし"
                case "1":
                    return "掲示物の掲示"
                case "2":
                    return "私物放置のため短冊貼り付け"
                case "3":
                    return "違法駐輪のため短冊貼り付け"
                case "4":
                    return "粗大ごみ放置のため短冊貼り付け"
                case "5":
                    return "ごみ、汚れがあったため対応済み"
            }
        }

},
created() {
    // console.log(this.institution)
    if(this.institution.items.length != 0){

        // 値渡し(一枚目、二枚目)
        this.institutions_12 = JSON.parse(JSON.stringify(this.institution))
        this.institutions_34 = JSON.parse(JSON.stringify(this.institution))
        this.institutions_56 = JSON.parse(JSON.stringify(this.institution))
        this.institutions_78 = JSON.parse(JSON.stringify(this.institution))

        // 各項目の処理
        // 一枚目、二枚目以外の削除
        this.institutions_12.items.splice(2, Number.MAX_VALUE)
        if(this.institutions_12.items.length == 1 ){
            this.photo_null_12 = !this.photo_null_12
        }
        
        // 三枚目、四枚目以外の削除
        this.institutions_34.items.splice(4, Number.MAX_VALUE)
        this.institutions_34.items.splice(0, 2)
        if(this.institutions_34.items.length == 1 ){
            this.photo_null_34 = !this.photo_null_34
        }
        
        // 五枚目、六枚目以外の削除
        this.institutions_56.items.splice(6, Number.MAX_VALUE)
        this.institutions_56.items.splice(0, 4)
        if(this.institutions_56.items.length == 1 ){
            this.photo_null_56 = !this.photo_null_56
        }
        
        // 七枚目、八枚目以外の削除
        this.institutions_78.items.splice(0, 6)
        if(this.institutions_78.items.length == 1 ){
            this.photo_null_78 = !this.photo_null_78
        }
    }
},
})
</script>

<style scoped>
.margin_top{
    margin-top: 50px;
}
.fontsize{
    font-size: 22px;
}
h2 {
margin: 0;
padding: 10px 0;
width: 100%;
color: #1a3f66ff;
}

ul{
padding: 0;
}

li{
list-style: none;
}

img{
width: 100%;
}

.title {
display: flex;
background-color: #1a3f66ff;
position: relative;
height: 56px;
}

.font_14{
font-size: 14px;
}

#photo_items{
width: 100%;
display: flex;
flex-wrap: wrap;
justify-content: start;
}

.photo_item{
width: 45%;
margin:0.5%;
border: solid 5px rgba(26, 63, 102, 0.823);
border-radius: 10px;
}

</style>