<template>
<div>
    <pc_head title="送信先詳細" path="/pc/destination/list"></pc_head>
    <div class="wrapper">
        <div class="destination_info">
            <div class="flex">
                <div id="name_title" class="">送信先名称：{{ destination.name }}</div>
                <div id="name_title" class="">登録日付：{{ destination.date }}</div>
            </div>
            <ul class="address-list">
                <li v-for="(address, index) in destination.to_addresses" :key="index">
                  {{ `宛先${index + 1}：${address}` }}
                </li>
            </ul>
            <ul class="address-list">
                <li v-for="(cc, index) in destination.cc_addresses" :key="index">
                    {{ `CC${index + 1}：${cc}` }}
                </li>
            </ul>              
            <ul class="property_list">
                <li class="property_item" v-for="(property, index) in destination.destination_properties" :key="index">
                    <p style="width:50%">{{ `登録物件${index + 1}:${property.name}` }}</p>
                    <input class="property_name" type="checkbox" v-model="property.monthly_flg" disabled>月次報告書
                    <input class="property_name" type="checkbox" v-model="property.patrol_flg" disabled>巡回報告書
                </li>
            </ul>

        </div>
    </div>
    <div class="btn_list">
        <!-- メール送信画面へ遷移 -->
        <button class="orange_btn orange_btn_list" @click="move_screen_mail()">メールを送信する</button>
        <!-- 編集画面へ遷移 -->
        <button class="orange_btn orange_btn_list" @click="move_screen({path:'/pc/destination/update', query: { destination_id: this.destination.destination_id}})">編集する</button>
        <!-- 月次フォルダ作成モーダル -->
        <button class="orange_btn orange_btn_list" @click="monthly_flg_change()">月次フォルダ作成</button>
        <div v-if="monthly_flg" class="modal">
            <div class="modal_content" style="height:fit-content;">
                <h2 class="modal_title">月次フォルダを作成する</h2>
                <p id="delete_info">{{ `${folder_name}/${destination.name}/物件名/${formattedDate}/月次報告書フォルダ` }}</p>
                <p>選択したフォルダ：{{ folder_name }}<button @click="folder_select()">フォルダを選択</button></p>
                <!-- 年月選択 -->
                <input type="month" class="month_select" v-model="select_month" required><br>
                <!-- ボタン -->
                <button class="modal_btn orange_btn" @click="create_monthly_folder()">作成する</button>
                <button class="modal_close" @click="monthly_flg_change()">キャンセル</button>
            </div>
        </div>
        <!-- 削除モーダル -->
        <button id="delete_btn" class="orange_btn orange_btn_list" style="background-color: #1a3f66ff;" @click="delete_flg_change()">削除する</button>
        <div v-if="delete_flg" class="modal">
            <div class="modal_content" style="height:fit-content;">
                <h2 class="modal_title">送信先を削除する</h2>
                <h3 style="margin-top: 10%;">送信先：{{ destination.name }}</h3>
                <p id="delete_info" style="color:red;">上記の送信先を削除しますがよろしいですか？</p>
                <button class="modal_btn orange_btn" @click="delete_destination()">削除</button>
                <button class="modal_close" @click="delete_flg_change()">キャンセル</button>
            </div>
        </div>
    </div>
    <loading_circle :show_modal="show_modal"></loading_circle>
</div>
</template>

<script scoped>
import { Mixin } from "../mixin";
import pc_head from "./pc_header.vue";
import loading_circle from "./loading_circle.vue"
import { get, set } from 'idb-keyval';

export default ({
    components: {
        pc_head,
        loading_circle
    },
    mixins: [Mixin],
    data() {
        return {
            destination: {},
            delete_flg: false,
            monthly_flg: false,
            isID: false,
            folder_name: "",
            select_month: "",
            show_modal:false,
        }
    },
    methods: {
        // もし登録物件がない場合、メール送信画面へ遷移しない
        move_screen_mail: function () {
            if (this.destination.destination_properties.length == 0) {
                alert("登録物件がありません。")
                return
            }
            this.$router.push({path:'/pc/destination/mail',query:{destination_id: this.destination.destination_id}})
        },
        // 月次報告書フォルダ作成
        create_monthly_folder: async function () {
            try {
                if(this.select_month == "") {
                    alert("年月を選択してください。")
                    return
                }
                this.show_modal = true;
                // 年月を成形
                let year = this.select_month.slice(0, 4);
                let month = this.select_month.slice(5, 7).replace(/^0+/, '');
                let formattedDate = `${year}年${month}月`;

                // Indexed Database から FileSystemDirectoryHandle オブジェクトを取得
                let dh = await get('dir');
                if (dh) {
                    // すでにユーザーの許可が得られているかをチェック
                    let permission = await dh.queryPermission({ mode: 'readwrite' });
                    if (permission !== 'granted') {
                        // ユーザーの許可が得られていないなら、許可を得る（ダイアログを出す）
                        permission = await dh.requestPermission({ mode: 'readwrite' });
                        if (permission !== 'granted') {
                            this.show_modal = false;
                            throw new Error('ユーザーの許可が得られませんでした。');
                        }
                    }
                } else {
                    // ディレクトリ選択ダイアログを表示
                    alert(`Odriveの「${process.env.VUE_APP_ODRIVE_FOLDER_NAME}」選択してください。`)
                    dh = await window.showDirectoryPicker();
                }
                // Odriveの「process.env.VUE_APP_ODRIVE_FOLDER_NAME」フォルダを選択していない場合,エラーを表示。
                if(dh.name !== process.env.VUE_APP_ODRIVE_FOLDER_NAME){
                    this.show_modal = false;
                    return alert(`失敗しました。Odriveの「${process.env.VUE_APP_ODRIVE_FOLDER_NAME}」フォルダを選択してください。`)
                }
                // FileSystemDirectoryHandle オブジェクトを Indexed Database に保存
                await set('dir', dh);

                this.$store.commit('folder_name', dh.name);
                this.folder_name = this.$store.state.folder_name;

                for (let i = 0; i < this.destination.destination_properties.length; i++) {
                    if (this.destination.destination_properties[i].monthly_flg) {
                        // 送信先フォルダ
                        let destination_directory = await dh.getDirectoryHandle(this.destination.name, { create: true });
                        // 物件フォルダ
                        let property_directory = await destination_directory.getDirectoryHandle(this.destination.destination_properties[i].name, { create: true });
                        // 年月フォルダの作成
                        let month_year_directory = await property_directory.getDirectoryHandle(formattedDate, { create: true });
                        // 月次報告書フォルダの作成
                        await month_year_directory.getDirectoryHandle("月次報告書", { create: true });
                        await month_year_directory.getDirectoryHandle("元データ", { create: true });
                        await month_year_directory.getDirectoryHandle("その他", { create: true });
                    }
                }       
                // フォルダ名
                let folder_hierarchy_name = `${dh.name}/${this.destination.name}/物件名/${formattedDate}/月次報告書`;
                alert(`${folder_hierarchy_name}を作成しました。`);

                this.monthly_flg_change();
                this.show_modal = false;
            } catch (e) {
                this.show_modal = false;
                // ファイル選択をキャンセルした時などにここに飛ぶ
                console.error(e)
                alert(e)
            }
        },
        async folder_select() {
            alert(`Odriveの「${process.env.VUE_APP_ODRIVE_FOLDER_NAME}」選択してください。`)
            // Indexed Database から FileSystemDirectoryHandle オブジェクトを取得
            let dh = await get('dir');
            // ディレクトリ選択ダイアログを表示
            dh = await window.showDirectoryPicker();
            // Odriveの「process.env.VUE_APP_ODRIVE_FOLDER_NAME」フォルダを選択していない場合,エラーを表示。
            if(dh.name !== process.env.VUE_APP_ODRIVE_FOLDER_NAME){
                return alert(`失敗しました。Odriveの「${process.env.VUE_APP_ODRIVE_FOLDER_NAME}」フォルダを選択してください。`)
            }
            // FileSystemDirectoryHandle オブジェクトを Indexed Database に保存
            await set('dir', dh);

            this.$store.commit('folder_name', dh.name);
            this.folder_name = this.$store.state.folder_name;
        },
        // 削除メゾット
        async delete_destination() {
            const delete_destination_id = { destination_id: this.destination.destination_id };
            const param = {
                method: "PUT",
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + this.$store.state.login_token
                },
                body: JSON.stringify({ destination: delete_destination_id })
            };
            // 送信先削除
            const res = await fetch(process.env.VUE_APP_API_BASE_URL + "/destination/delete/" + this.$route.query.destination_id, param)
            if (res.status == 200) {
                this.move_screen('/pc/destination/list');
            } else if (res.status == 401) {
                this.unauth()
            } else {
                this.other_error(res.status)
            }
        },
        // 削除モーダル表示、非表示
        delete_flg_change() {
            this.delete_flg = !this.delete_flg
        },
        // 月次フォルダ作成モーダル表示、非表示
        monthly_flg_change() {
            // 登録物件がない場合
            if (this.destination.destination_properties.length == 0) {
                alert("登録物件がありません。")
                return
            }
            // もし、登録物件に月次報告書を送信する物件がない場合、アラートを出す
            const hasMonthlyProperty = this.destination.destination_properties.some(property => property.monthly_flg);
            if (!hasMonthlyProperty) {
                alert("月次報告書を送信する物件が登録されていません");
                return;
            }
            this.monthly_flg = !this.monthly_flg
        },
    },
    created: async function () {
        if(this.$store.state.folder_name){
            this.folder_name = this.$store.state.folder_name;
        } else {}
        // console.log(this.folder_name)
        const param = {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + this.$store.state.login_token
            },
        };
        // 送信先情報取得
        const res = await fetch(process.env.VUE_APP_API_BASE_URL + "/destination/" + this.$route.query.destination_id, param)
        if (res.status == 200) {
            var data = await res.json()
            // console.log(data)
            this.destination = data.destination
        } else if (res.status == 401) {
            this.unauth()
        } else {
            this.other_error(res.status)
        }
        // 物件情報取得
        const res_property = await fetch(process.env.VUE_APP_API_BASE_URL + "/property/list", param)
        if (res_property.status == 200) {
            var data = await res_property.json();
            for (var i = 0; i < data.property.length; i++) {
                for (var j = 0; j < this.destination.destination_properties.length; j++) {
                    if (data.property[i].property_id == this.destination.destination_properties[j].property_id) {
                        this.destination.destination_properties[j].name = data.property[i].info.name;
                    }
                }
            }
        } else if (res_property.status == 401) {
            this.unauth()
        } else {
            this.other_error(res_property.status)
        }
    },
    computed: {
        formattedDate() {
            // 年月を成形
            let year = this.select_month.slice(0, 4);
            let month = this.select_month.slice(5, 7).replace(/^0+/, '');
            return `${year}年${month}月`;
        }
    }
})
</script>
    
<style scoped>
li{
    list-style: none;
}
.wrapper {
    width: 100%;
    max-width: 960px;
    margin: 0 auto;
    padding: 20px;
    box-sizing: border-box;
}

.destination_info {
    background-color: #f2f2f2;
    padding: 20px;
    border-radius: 5px;
    margin-bottom: 20px;
}

.flex {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 10px;
}

#name_title {
    font-weight: bold;
    font-size: 16px;
}

[v-for] {
    margin-bottom: 5px;
}

input[type="checkbox"] {
    margin-left: 10px;
}

.month_select{
    width: 90%;
    height: 40px;
    margin-bottom: 10px;
}
.property_list {
    padding: 0px;
}

.property_item {
    display: flex;
    justify-content: space-around;
    align-items: center;
    border: 2px gray solid;
    border-radius: 5px;
    width: 100%;
    list-style: none;
    margin-top: 5px;
}
/* btn_list内の4つのボタンを2×2で表示する */
.btn_list{
    margin: 0 auto;
    max-width: 960px;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}
.orange_btn_list {
    width: 40%;
    margin: 10px;
}
</style>