<template>
<div>
    <phone_head title="過去報告書" :path="{path:'/report/list/',query: {property_id: this.$route.query.property_id}}"></phone_head>
    <div class="wrapper">
        <div class="title" style="text-align: center;">
            <h1>巡回報告書</h1>
            <table border="1" style="border-collapse: collapse;width:100%">
                <tr>
                    <th>巡回日</th>
                    <td>{{info.date}}</td>
                </tr>
                <tr>
                    <th>物件名</th>
                    <td>{{info.name}}</td>
                </tr>
                <tr>
                    <th>所在地</th>
                    <td>{{info.address}}</td>
                </tr>
                <tr>
                    <th>実施者</th>
                    <td>{{info.practitioner}}</td>
                </tr>
                <tr>
                    <th>担当</th>
                    <td>{{info.person}}</td>
                </tr>
            </table>
        </div>
        <div>
            <h2>【報告事項】</h2>
            <ul v-for="institution in institutions" :key="institution">
                <past_item :institution=institution></past_item>
            </ul>
            <p>今後ともマンションの美化向上のためご協力の程、何卒よろしくお願いいたします</p>
            <h2>【共有事項】</h2>
            <share_item :share_photo="share_photo"></share_item>
            <p class="text_left">{{share_text}}</p>
        </div>
        <p>上記について、今後入居者様から問い合わせ等が発生する可能性がございます。その際は弊社までご連絡いただけますと幸いです</p>
        <p style="text-align: right;">以上</p>
        <!-- ボタンリスト -->
        <div id="btn_list">
            <button class="orange_btn" @click="update_report()">修正する</button>
            <button class="orange_btn" @click="this.$router.push({path:'/report/list/',query: {property_id: this.$route.query.property_id}})"
                style="background-color:rgba(26, 63, 102) ;">戻る</button>
        </div>
    </div>
</div>
</template>
<script scoped>
import { Mixin } from "../mixin";
import phone_head from "./phone_header.vue"
import past_item from "./report_report_component.vue"
import share_item from "./report_report_share_component.vue"
import { db } from "../db";
import Dexie from 'dexie'

export default ({
    components: {
        phone_head,
        past_item,
        share_item
    },
    mixins: [Mixin],
    data() {
        return {
            info: {},
            institutions: {},
            photo_num: 0,
            share_photo: [],
            share_text: "",
            approve_flg: false,
            fix_flg: false,
            data: {},        }
    },
    methods: {
        flg_change(flg) {
            this[flg] = !this[flg]
        },
        async update_report() {
            db.delete()
            db.open()
            try {
                // Put the report_info
                await db.report_db.put({
                    info:Dexie.deepClone(this.master_data.report.info),
                    institutions: Dexie.deepClone(this.master_data.report.institutions),
                    others_photo: {
                        photo:Dexie.deepClone(this.master_data.report.others_photo),
                        photo_name:[]
                    },
                    share: {photo:Dexie.deepClone(this.master_data.report.share.photo),
                            photo_name:[],
                            text:this.master_data.report.share.text, 
                    },
                    internal_memo: this.master_data.report.internal_memo,
                    status: this.master_data.report.status
                });
                this.$router.push({ path: '/report/input/update', query: { property_id:this.$route.query.property_id,report_id:this.$route.query.report_id}});
            } catch (error) {
                console.log(error)
            }
        },
    },
    created: async function () {
        const param = {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + this.$store.state.login_token
            },
        };
        // 報告書情報更新
        const res = await fetch(process.env.VUE_APP_API_BASE_URL + "/report/" + this.$route.query.property_id + this.$route.query.report_id, param)
        if (res.status == 200) {
            var data = await res.json()
            var json_data = JSON.stringify(data)
            this.master_data = JSON.parse(json_data)
            // console.log(this.master_data)
            // internal_memoプロパティがない（既存の報告書）場合の処理
            if(!this.master_data.report.hasOwnProperty('internal_memo')){
                this.master_data.report.internal_memo = ''
            }
            this.data = data;
            this.info = data.report.info;
            this.institutions = data.report.institutions;
            for (let institution in this.institutions) {
                if (this.institutions[institution].items.length !== 0) {
                    // 1要素ごとの処理
                    for (let i = this.institutions[institution].items.length -1; i >= 0 ; i--) {
                        // 報告書に記載の項目抽出
                        if (!this.institutions[institution].items[i].select) {
                            this.institutions[institution].items.splice(i,1)
                            console.log('写真を削除しました')
                        }
                    }
                }
                // itemsが空の時は項目ごと削除する
                if (typeof this.institutions[institution].items[0] === 'undefined') {
                    delete this.institutions[institution]
                }
            }
            this.share_text = data.report.share.text
            this.share_photo = data.report.share.photo
        } else if (res.status == 401) {
            this.unauth()
        } else {
            this.other_error(res.status)
        }
    },
})
</script>

<style scoped>
.wrapper {
    width: 95%;
    margin: 0 auto;
}

th {
    width: 20%;
}

.wid70 {
    width: 70%;
}

td {
    width: 70%;
    text-align: left;
}

ul {
    padding: 0;
}

li {
    list-style: none;
}

img {
    width: 99%;
}

h3 {
    margin: 50px 0 30px;
}

.text_left {
    text-align: left;
}

#share_items {
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    width: 100%;
}

.share_item {
    width: 48%;
    margin: 0.5%;
    border: solid 5px rgba(26, 63, 102);
    border-radius: 10px;
}

#btn_list {
    display: flex;
    justify-content: space-around;
}

.orange_btn {
    margin: 15px;
    width: 45%;
}
</style>