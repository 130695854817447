<template>
<div>
    <pc_head title="以下の内容で保存しますか"
        :path="{ path: '/pc/report/input/update', query: { property_id: this.$route.query.property_id, report_id: this.$route.query.report_id, data_keep_flg: 'true'} }">
    </pc_head>
    <div class="wrapper">
        <div class="title" style="text-align: center;">
            <h1>巡回報告書</h1>
            <table border="1" style="border-collapse: collapse;width:100%">
                <tr>
                    <th>巡回日</th>
                    <td>{{info.date}}</td>
                </tr>
                <tr>
                    <th>物件名(所在地)</th>
                    <td>{{info.name}}({{info.address}})</td>
                </tr>
                <tr>
                    <th>実施者（担当者）</th>
                    <td>{{info.practitioner}}（{{info.person}}）</td>
                </tr>
            </table>
        </div>
        <div>
            <!-- <h2>【報告事項】</h2> -->
            <ul v-for="institution in institutions" :key="institution">
                <past_item :institution=institution></past_item>
            </ul>
            <!-- <h2>【共有事項】</h2> -->
            <share_item :share="share_photo"></share_item>
            <p class="white-space" style="text-align: center;">{{ share_text }}</p>
            <p>今後ともマンションの美化向上のためご協力の程、何卒よろしくお願いいたします</p>
        </div>
        <p>上記について、今後入居者様から問い合わせ等が発生する可能性がございます。その際は弊社までご連絡いただけますと幸いです</p>
        <p style="text-align: right;">以上</p>
        <!-- ボタンリスト -->
        <div id="btn_list">
            <button class="orange_btn" @click="update_report()" :disabled="btn_clicked">確定する</button>
            <button class="orange_btn"
                @click="this.$router.push({ path: '/pc/report/input/update', query: { property_id:this.$route.query.property_id, report_id: this.$route.query.report_id,data_keep_flg: 'true'} } )"
                style="background-color:rgba(26, 63, 102) ;">戻る</button>
        </div>
    </div>
    <loading_circle :show_modal="show_modal"></loading_circle>
</div>
</template>
<script scoped>
import { Mixin } from "../mixin";
import pc_head from "./pc_header.vue";
import past_item from "./pc_report_past_component.vue";
import share_item from "./pc_report_past_share_component.vue";
import { db } from "../db";
import loading_circle from "./loading_circle.vue";

export default ({
    components: {
        pc_head,
        past_item,
        share_item,
        loading_circle,
    },
    mixins: [Mixin],
    data() {
        return {
            info: {},
            institutions: [],
            photo_num: 0,
            others_photo_name: [],
            others_photo: [],
            share_photo_name: [],
            share_photo: [],
            share_text: "",
            approve_flg: false,
            fix_flg: false,
            data: {},
            filter_institutions: {},
            btn_clicked:false,
            show_modal:false,
        }
    },
    methods: {
        flg_change(flg) {
            this[flg] = !this[flg]
        },
        async update_report() {
            this.show_modal = true;
            this.btn_clicked = true;
            var submit_data = await db.report_db.get(1)
            // console.log(submit_data)
            // 写真データを分離(institution)
            var photo_data_name = []
            var photo_data = []
            for (let institution in submit_data.institutions) {
                var data = [];
                var data_name = [];
                for (var s = 0; s < submit_data.institutions[institution].items.length; s++) {
                    // photo_nameがある物のみ以下の処理を行う
                    if (submit_data.institutions[institution].items[s].photo_name) {
                        // 写真データ+名称取り出し
                        data.push(submit_data.institutions[institution].items[s].photo);
                        data_name.push(submit_data.institutions[institution].items[s].photo_name);
                        // 写真キー削除
                        delete submit_data.institutions[institution].items[s].photo;
                        delete submit_data.institutions[institution].items[s].photo_name;
                    }
                }
                photo_data.push(data);
                photo_data_name.push(data_name);
            }

            // その他写真の追加部分の写真データ分離
            if (submit_data.others_photo.photo_name !== undefined && submit_data.others_photo.photo_name.length > 0) {
                var others_photo_data_name = []
                var others_photo_data = []
                for (var t = submit_data.others_photo.photo_name.length - 1; t >= 0; t--) {
                    if (typeof submit_data.others_photo.photo_name[t] !== 'undefined') {
                        console.log('その他写真を削除しました')
                        // 写真データ+名称取り出し
                        others_photo_data.push(submit_data.others_photo.photo[t]);
                        others_photo_data_name.push(submit_data.others_photo.photo_name[t]);
                        // 写真の削除
                        submit_data.others_photo.photo.splice(t, 1);
                        submit_data.others_photo.photo_name.splice(t, 1);
                    }
                }
                //  配列の順番を逆にする
                Array.prototype.reverse.call(others_photo_data)
                Array.prototype.reverse.call(others_photo_data_name)
            }

            // 共有事項写真の追加部分の写真データ分離
            if (submit_data.share.photo_name !== undefined && submit_data.share.photo_name.length > 0) { 
                var share_photo_data_name = []
                var share_photo_data = []
                for (var u = submit_data.share.photo_name.length - 1; u >= 0; u--) {
                    if (typeof submit_data.share.photo_name[u] !== 'undefined') {
                        console.log("共有事項写真を削除しました")
                        // 写真データ+名称取り出し
                        share_photo_data.push(submit_data.share.photo[u]);
                        share_photo_data_name.push(submit_data.share.photo_name[u]);
                        // 写真の削除
                        submit_data.share.photo.splice(u, 1)
                        submit_data.share.photo_name.splice(u, 1)
                    }
                }
                // 配列の順番を逆にする
                Array.prototype.reverse.call(share_photo_data)
                Array.prototype.reverse.call(share_photo_data_name)
            }

            // 報告書情報送信
            const param = {
                method: "PUT",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + this.$store.state.login_token
                },
                // 送信データは施設情報＋共有メモ
                body: JSON.stringify({
                    report: {
                        institutions: submit_data.institutions,
                        others_photo: submit_data.others_photo.photo,
                        share: {
                            text: submit_data.share.text,
                            photo: submit_data.share.photo
                        },
                        internal_memo: submit_data.internal_memo,
                        date: submit_data.info.date,
                        status: this.status
                    }
                })
            }
            const res = await fetch(process.env.VUE_APP_API_BASE_URL + "/report/update/" + this.$route.query.property_id + this.$route.query.report_id, param)
            if (res.status == 200) {
                // 報告一覧に戻る
            } else if (res.status == 401) {
                this.unauth()
            } else {
                this.other_error(res.status)
            }

            // 施設の数＋写真データがあるものだけを送る
            var i = 0;
            for (let institution in submit_data.institutions) {
                // 報告書情報送信
                var params = {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + this.$store.state.login_token
                    },
                    // 送信データは施設情報＋共有メモ
                    body: JSON.stringify({
                        institution: institution,
                        photo: photo_data[i],
                        name: photo_data_name[i]
                    })
                }
                // 写真データがあるものだけを送信する
                if (photo_data[i] !== undefined && photo_data[i].length > 0) {
                    console.log("photo trans");
                    const res = await fetch(process.env.VUE_APP_API_BASE_URL + "/photo/add/" + this.$route.query.property_id + this.$route.query.report_id, params)
                    if (res.status == 200) {
                        // 一回でも送信に失敗したら停止
                        console.log("Photo trans success");
                    } else if (res.status == 401) {
                        console.log('失敗しました')
                        this.unauth()
                    } else {
                        console.log('失敗しました')
                        this.other_error(res.status)
                    }
                }
                i++;
            }
            // その他写真
            // 写真データがあるものだけを送信する
            if (others_photo_data !== undefined && others_photo_data.length > 0) {
                var params = {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + this.$store.state.login_token
                    },
                    // 送信データは施設情報＋共有メモ
                    body: JSON.stringify({
                        institution: "others_photo",
                        photo: others_photo_data,
                        name: others_photo_data_name
                    })
                }
                const res = await fetch(process.env.VUE_APP_API_BASE_URL + "/photo/add/" + this.$route.query.property_id + this.$route.query.report_id, params)
                if (res.status == 200) {
                    // 一回でも送信に失敗したら停止
                    console.log("trans success");
                } else if (res.status == 401) {
                    this.unauth()
                } else {
                    this.other_error(res.status)
                }
            }
            
            // 共有
            // 写真データがあるものだけを送信する
            if (share_photo_data !== undefined && share_photo_data.length > 0) {
                var params = {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + this.$store.state.login_token
                    },
                    // 送信データは施設情報＋共有メモ
                    body: JSON.stringify({
                        institution: "share",
                        photo: share_photo_data,
                        name: share_photo_data_name
                    })
                }
                // console.log(this.$route.query.property_id + this.$route.query.report_id) 
                const res = await fetch(process.env.VUE_APP_API_BASE_URL + "/photo/add/" + this.$route.query.property_id + this.$route.query.report_id, params)
                if (res.status == 200) {
                    // 一回でも送信に失敗したら停止
                    console.log("trans success");
                } else if (res.status == 401) {
                    this.unauth()
                } else {
                    this.other_error(res.status)
                }
            }
            this.move_screen('/pc/report/list');
        }
    },
    created: async function () {
        // storeからデータの取得
        const back_data = await db.report_db.get(1)
        var json_data = JSON.stringify(back_data)
        var report_data = JSON.parse(json_data)
        // console.log(report_data)
        this.info = report_data.info
        this.institutions = report_data.institutions
        this.share_photo_name = report_data.share.photo_name
        this.share_photo = report_data.share.photo
        this.share_text = report_data.share.text.replace( /\n/g , "\r\n")
        this.internal_memo = report_data.internal_memo
        this.others_photo_name = report_data.others_photo.photo_name
        this.others_photo = report_data.others_photo.photo
        this.status = report_data.status
        for (let institution in this.institutions) {
            if (this.institutions[institution].items.length !== 0) {
                // 1要素ごとの処理
                for (let i = this.institutions[institution].items.length -1; i >= 0 ; i--) {
                    // ""の状態だとpdf出力の時に写真が反映されないため、" "を挿入した。
                    if (!this.institutions[institution].items[i].text) {
                        this.institutions[institution].items[i].text = " ";
                    }
                    // 報告書に記載の項目抽出
                    if (!this.institutions[institution].items[i].select) {
                        this.institutions[institution].items.splice(i, 1)
                    }
                }
            }
            // itemsがない時は項目を削除する
            if (this.institutions[institution].items.length == 0) {
                delete this.institutions[institution]
            }
        }
    },
})
</script>

<style scoped>
th {
    width: 20%;
}

.wid70 {
    width: 70%;
}


td {
    width: 70%;
    text-align: left;
}

ul {
    padding: 0;
}

li {
    list-style: none;
}

img {
    width: 99%;
}

h3 {
    margin: 50px 0 30px;
}

.text_left {
    text-align: left;
}

#share_items {
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    width: 100%;
}

.share_item {
    width: 48%;
    margin: 0.5%;
    border: solid 5px rgba(26, 63, 102);
    border-radius: 10px;
}

#btn_list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.orange_btn {
    margin: 10px 60px;
}
</style>