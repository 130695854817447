<template>
<div>
  <pc_head title="システム管理TOP" path="/pc/top" style="margin-bottom: 100px;"></pc_head>
  <div class="container" id="top_page">
    <div class="next_screen_button">
      <button id="user_management_btn" class="btn top_pc_btn" @click="move_screen('/pc/destination/list')">送信先管理</button>
      <button id="property_management_btn" class="btn top_pc_btn" @click="move_screen('/pc/property/list')">物 件 管 理</button>
      <button id="user_management_btn" class="btn top_pc_btn" @click="move_screen('/pc/user/list')">ユーザー管理</button>
    </div>
  </div>
</div>
</template>
<script>
import { Mixin } from '../mixin'
import pc_head from "./pc_header.vue"


export default ({
  components: {
    pc_head
  },
  mixins: [Mixin],
  data() {
    return {
    }
  },
  computed: {
    new_token() {
      return this.$store.state.login_token
    }
  }
})

</script>

<style scoped>
html{
  min-width: 816px;
  width: 100%;
}
.next_screen_btn {
  display: flex;
}

.btn {
  font-size: 36px;
  margin: 0 5%;
  width: 25%;
  height: 200px;
}

.logout {
  margin-top: 100px;
}

#logout_btn {
  font-size: 40px;
  width: 20%;
}

@media screen and (max-width: 912px)
 {
  .btn{
    font-size: 32px;
  }
}
</style>
