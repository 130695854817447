<template>
    <main>
        <pc_head title="報告入力画面（編集)" path="/pc/report/list"></pc_head>
        <div class="wrapper">
            <div  class="pc_input_overview">
                <p>　日付：<input class="date" type="date" v-model="info.date" style="height:50px; width:150px" autofocus required /></p>
                <p>担当者：{{ info.person }}</p>
                <p>物件名：{{ info.name }}</p>
            </div>
            <ul id="item_info" v-for="(institution, key) in institutions" :key="institution">
                <input_item_update :institution="institution" :past_institution="past_institutions[key]"
                    :past_report_check="past_report_flag"></input_item_update>
            </ul>
            <!-- その他写真 -->
            <div class="title">
                <h2>その他写真</h2>
                <button class="flg_btn" @click="flg_change('others_photo_flg')">{{ others_photo_flg_text }}</button>
            </div>
            <div v-if="others_photo_flg">
                <ul class="photo_items" id="others_photo_items">
                    <li class="item" v-for="(photo, index) of others_photo" :key="photo">
                        <label class="file_label"><input class="photo_input file_select" type="file" ref="preview"
                                @change="input_base64($event.target.files[0], this.others_photo, this.others_photo_name, index, $event)">ファイルを選択</label>
                        <img class="photo" :src="photo">
                        <button class="delete_btn" @click="delete_photo(others_photo, others_photo_name ,index)">写真を消去します</button>
                    </li>
                </ul>
                <!-- その他写真、複数枚選択 -->
                <label class="file_label" v-if="others_photo.length < 8">
                    <input class="file_select" type="file" @change="add_photo($event, others_photo, others_photo_name)" multiple="multiple">写真を選択
                </label>
            </div>

            <!-- 共有事項 -->
            <div class="title margin">
                <h2>共有事項</h2>
                <button class="flg_btn" @click="flg_change('share_flg')">{{ share_flg_text }}</button>
            </div>
            <div v-if="share_flg">
                <ul class="photo_items" id="share_items">
                    <li class="item" v-for="(share_item, index) of share_photo" :key="share_item">
                        <label class="file_label"><input class="photo_input file_select" type="file" ref="preview"
                            @change="input_base64($event.target.files[0], this.share_photo, this.share_photo_name, index, $event)">ファイルを選択</label>
                        <img class="photo" :src="share_item">
                        <p v-if="share_item">写真{{ index + 1 }}</p>
                        <button class="delete_btn" @click="delete_photo(share_photo, share_photo_name, index)">写真を消去します</button>
                    </li>
                </ul>
                <!-- 共有事項、複数枚選択 -->
                <label class="file_label" v-if="share_photo.length < 8">
                    <input class="file_select" type="file" @change="add_photo($event, share_photo, share_photo_name)" multiple="multiple">写真を選択
                </label>

                <h3>共有事項内容</h3>
                <textarea rows="5" v-model="share_text" placeholder="共有事項の内容を記述してください"
                    style="width: 90%;border: solid 3px grey ;border-radius: 5px;" maxlength="1000"></textarea>
                <h3>社内向け共有事項</h3>
                <p class="font14">※こちらの内容は報告書には記載されません</p>
                <textarea rows="5" v-model="internal_memo" placeholder="社内向け共有事項を記述してください"
                    style="width: 90%;border: solid 1px grey ;" maxlength="1000"></textarea>
            </div>
            <div id="btn_list">
                <button class="orange_btn" @click="update_report">確定する</button>
                <button class="orange_btn" style="background-color:#1a3f66ff;"
                    @click="move_screen('/pc/report/list')">キャンセル</button>
            </div>
        </div>
    </main>
</template>
<script scoped>
import { Mixin } from "../mixin";
import input_item_update from "./pc_report_input_update_component.vue"
import pc_head from "./pc_header.vue"
import { db } from "../db";
import Dexie from 'dexie'

export default ({
    mixins: [Mixin],
    data() {
        return {
            info: {},
            institutions: {},
            past_institutions: {},
            flg_btn_text: "＋",
            others_photo: [],
            others_photo_name: [],
            others_photo_flg: false,
            others_photo_flg_text: "＋",
            share_photo: [],
            share_text: '',
            share_photo_name: [],
            share_flg: false,
            share_flg_text: "＋",
            past_report_flag: true,
            photo_blank_flg: true,
        }
    },
    methods: {
        flg_change(flg) {
            this[flg] = !this[flg]
            if (this[flg + '_text'] == "＋") {
                this[flg + '_text'] = "－";
            } else {
                this[flg + '_text'] = "＋";
            }
        },
        async update_report() {
            // 写真が選択されていない項目があるか確認
            this.photo_blank_flg = true
            // その他写真確認
            this.photo_blank_check(this.others_photo)
            // 共有事項写真確認
            this.photo_blank_check(this.share_photo)
            if(this.info.date == ""){
                return window.alert("日付を入力してください")
            }
            if (this.photo_blank_flg) {
                db.delete()
                db.open()
                try {
                    // Put the report_info
                    await db.report_db.put({
                        info: Dexie.deepClone(this.info),
                        institutions: Dexie.deepClone(this.institutions),
                        others_photo: {
                            photo: Dexie.deepClone(this.others_photo),
                            photo_name: Dexie.deepClone(this.others_photo_name)
                        },
                        share: {
                            photo: Dexie.deepClone(this.share_photo),
                            photo_name: Dexie.deepClone(this.share_photo_name),
                            text: this.share_text,
                        },
                        internal_memo: this.internal_memo,
                        status: this.status
                    });
                    this.move_screen({ path: '/pc/report/past/confirm', query: { property_id: this.$route.query.property_id, report_id: this.$route.query.report_id } })
                } catch (error) {
                    console.log(error)
                }
            } else {
                alert('写真が選択されていない項目があります')
            }
        },
    },
    created: async function () {
        // 物件情報取得API
        const param = {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + this.$store.state.login_token
            }
        };
        var institutions_flg = {}
        const res = await fetch(process.env.VUE_APP_API_BASE_URL + "/property/" + this.$route.query.property_id, param)
        if (res.status == 200) {
            var data = await res.json()
            // console.log(data)
            // 施設の有無情報の取得
            institutions_flg = data.property.institutions
            // 報告書情報取得
            // console.log(this.$route.query.data_keep_flg)

            if (this.$route.query.data_keep_flg == 'true') {
                // vuexから情報取得
                console.log('indexedDBからデータを取得しております')
                var report_data = await db.report_db.get(1)
                // console.log(report_data)
                this.info = report_data.info
                this.institutions = report_data.institutions
                this.others_photo = report_data.others_photo.photo
                this.others_photo_name = report_data.others_photo.photo_name
                this.share_photo = report_data.share.photo
                this.share_photo_name = report_data.share.photo_name
                this.share_text = report_data.share.text
                this.internal_memo = report_data.internal_memo
                this.status = report_data.status
            } else {
                // API通信による情報取得
                console.log('APIから情報取得しています')
                const res = await fetch(process.env.VUE_APP_API_BASE_URL + "/report/" + this.$route.query.property_id + this.$route.query.report_id, param)
                var data = await res.json()
                // console.log(data)
                if (res.status == 200) {
                    this.info = data.report.info
                    this.others_photo = data.report.others_photo
                    this.share_photo = data.report.share.photo
                    this.share_text = data.report.share.text
                    this.internal_memo = data.report.internal_memo
                    this.status = data.report.status
                    // 施設ごとの処理
                    for (let institution in institutions_flg) {
                        // 施設有無情報を元に項目を選定する。&& 報告書作成後、施設編集で追加された項目をはじく
                        if(institutions_flg[institution] && (data.report.institutions[institution] !== undefined)){
                            this.institutions[institution] = data.report.institutions[institution]
                        }
                        // 物件編集で施設項目を減らした場合でも、過去にデータがあれば編集できる。
                        if(!institutions_flg[institution] && data.report.institutions[institution]){
                            this.institutions[institution] = data.report.institutions[institution]
                        }
                    }
                    // console.log(this.institutions)
                } else if (res.status == 401) {
                    this.unauth()
                } else {
                    this.other_error(res.status)
                }

            }
            // 前回の報告書情報取得
            this.last_report_info();
        } else if (res.status == 401) {
            this.unauth()
        } else {
            this.other_error(res.status)
        }
    },
    components: {
        input_item_update,
        pc_head
    },
})
</script>

<style scoped>
p {
    margin: 0;
}
h2 {
    margin: 0;
    padding: 10px 0;
    width: 100%;
    color: #fff;
}

.title {
    display: flex;
    background-color: #1a3f66ff;
    position: relative;
}

.flg_btn {
    font-size: 40px;
    background-color: #1a3f66ff;
    border: none;
    color: #fff;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 0;
    height: 56px;
    width: 56px;
}

ul {
    padding: 0
}

li {
    list-style: none;
}

a {
    text-decoration: none;
}

.margin {
    margin-top: 15px;
}

#share_items {
    display: flex;
}

.photo_items {
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
}

.item {
    border: solid 5px #1a3f66ff;
    border-radius: 10px;
    margin: 0 10px 10px 0;
    width: 22%;

}

.photo_input {
    margin: 20px 0;
}

.photo {
    width: 22%;
    margin: 0.5%;
}

img.photo {
    width: 90%;
}

.delete_btn {
    margin: 30px;
    background-color: #1a3f66ff;
    font-size: 16px;
    color: #fff;
    border: none;
    border-radius: 5px;
    margin-bottom: 5px;
}

.file_select{
    display: none;
}
.file_label{
    display: inline-block;
    padding: 5px;
    margin-top: 15px;
    margin-bottom: 15px;
    color: black;
    background-color: lightgray;
    cursor: pointer;
    border: 1px solid black;
    border-radius: 5px;
}
.pc_input_overview{
    margin:5px 0 0 30%; 
    text-align: left;
}
</style>