<template>
<div>
    <pc_head title="物件一覧" path="/pc/system/management"></pc_head>
    <div class="wrapper">
        <div id="filter_wrapper_title">
            <h2>検索フィルタ</h2>
            <button id="filter_flg_btn" @click="filter_flg_change">{{filter_flg_text}}</button>
        </div>
        <div class="filter_wrapper" v-if="filter_flg">
            <div class="input_search">
                <p class="input_search_title">検索キーワード</p>
                <input type="text" class="input_text" placeholder="検索したいキーワードを入力してください" v-model="search_keyword">
            </div>
            <div class="pulldown_search">
                <div class="flex_box">
                    <p class="filter_title">分類ステータス</p>
                    <select id="" class="item_select" v-model="selected_status" @change="status_filter">
                        <option value="" hidden>担当エリアを選択</option>
                        <option v-for="section_status in section_status_list" :key="section_status">
                            {{ section_status }}
                        </option>
                    </select>
                </div>
                <div class="flex_box">
                    <p class="filter_title">担当者</p>
                    <select id="" class="item_select" v-model="selected_manager">
                        <option value="" hidden>担当者を選択</option>
                        <option v-for="manager in managers" :key="manager">
                            {{ manager }}
                        </option>
                    </select>
                </div>
                <button id="search" @click="property_search()" class="search_button flex_box">検索(リセット)</button>
                <div class="flex_box">
                    <p class="filter_title">都道府県</p>
                    <select id="" class="item_select" v-model="selected_prefecture">
                        <option value="" hidden>都道府県を選択</option>
                        <option v-for="prefecture in prefectures" :key="prefecture">
                            {{ prefecture }}
                        </option>
                    </select>
                </div>
                <div class="flex_box">
                    <p class="filter_title">市区町村</p>
                    <select id="" class="item_select" v-model="selected_municipality">
                        <option value="" hidden>市区町村を選択</option>
                        <option v-for="municipality in municipalities" :key="municipality">
                            {{ municipality }}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <div class="btn_list">
            <button class="btn_item" @click="export_flg_change">テンプレートエクスポート</button>
            <div v-if="export_flg" class="modal">
                <div class="modal_content">
                    <h2 class="modal_title">テンプレートをエクスポートする</h2>
                    <p><button class="modal_btn orange_btn" @click="csv_export()">テンプレートをダウンロードする</button></p>
                    <button class="modal_close" @click="export_flg_change">戻る</button>
                </div>
            </div>
            <button class="btn_item" @click="import_flg_change">CSVインポート</button>
            <div v-if="import_flg" class="modal">
                <div class="modal_content">
                    <h2 class="modal_title">CSVファイルをインポートする</h2>
                    <p><input class='import_file' type="file" @change="load_csv_file"></p>
                    <p v-if="file_message">{{file_message}}</p>
                    <button class="modal_close" @click="import_flg_change">戻る</button>
                </div>
            </div>

            <button class="property_add_btn btn_item" @click="move_screen('/pc/property/add')">物件追加</button>
            <select id="canceled_menu" v-model='selected_canceled_flg'>
                <option :value = 'true' >解約済み</option>
                <option :value = 'false'>契約中</option>
            </select>
        </div>
        <ul class="property_list">
            <li class="property_item" v-for="property in filtered_properties" :key="property"
                @click="move_description_screen(property)">
                <a class="property_name">{{ property.info.name }}</a>
                <a class="property_manager">担当者：{{ property.info.person }}</a>
            </li>
        </ul>
    </div>
</div>
</template>
<script scoped>
import { Mixin } from '../mixin'
import pc_head from "./pc_header.vue"



export default ({
    components: {
        pc_head
    },
    mixins: [Mixin],
    data() {
        return {
            search_keyword: "",
            section_status_list: [],
            managers: [],
            prefectures: [],
            municipalities: [],
            selected_status: "",
            selected_manager: "",
            selected_prefecture: "",
            selected_municipality: "",
            selected_canceled_flg: false,
            master_properties: [],
            properties: [],
            filter_flg_text: "＋",
            filter_flg: false,
            export_flg: false,
            import_flg: false,
            file_message: "",
            file_properties: [],
            csv_properties:[],
            csv_text: "",
            csv_data: [
                ["物件番号", "物件名", "郵便番号", "都道府県", "市区町村", "番地", "分類ステータス", "エリア", "沿線", "最寄り駅", "物件担当", "巡回頻度", "巡回実施予定月", "オーナー名", "下請け先（日常清掃）", "オートロック番号", "キーボックスの場所", "特記事項"]
            ],
        }
    },
    methods: {
        csv_export() {
            this.text = this.csv_data.map(e1 => e1.join(",")).join("\n");
            let blob = new Blob([this.text], { type: 'text/plain' });
            let blobURL = window.URL.createObjectURL(blob);
            let obj = document.createElement('a');
            obj.href = blobURL;
            obj.download = `template.csv`
            document.body.appendChild(obj);
            obj.click();
            obj.parentNode.removeChild(obj);
        },
        load_csv_file(e) {
            let file = e.target.files[0];
            if (!file.type.match("text/csv")) {
                this.file_message = "CSVファイルを選択してください";
                console.log("csvファイルを選択してください");
                return;
            } else {
                this.file_message = ""
            }
            let reader = new FileReader();
            reader.readAsText(file);
            reader.onload = async () => {
                let lines = reader.result.split("\r\n");
                let linesArr = [];
                var object_keys = ["number", "name", "post_code", "prefecture", "municipality", "address", "section", "area", "line", "station", "person", "frequency",
                    "month", "owner", "subcontractor", "auto_lock", "key_box", "remarks"]
                var add_count = 0;
                // 一物件ごとの処理
                for (let i = 1; i < lines.length; i++) {
                    linesArr[i] = lines[i].split(",");
                    const obj = {}
                    // 物件の情報にキーを付与
                    for (let j = 0; j < linesArr[i].length; j++) {
                        obj[object_keys[j]] = linesArr[i][j]
                    }

                    // 解約済みステータスを追加
                    obj['canceled_flg'] = false ;

                    // 物件名が記入されているかつ
                    if(obj.name !== undefined && obj.name.length > 0 && this.csv_properties.indexOf(obj.name) == -1){
                        this.file_properties.push(obj)
                        add_count++;
                    }
                }
                // 物件インポートAPI
                const param = {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + this.$store.state.login_token
                    },
                    body: JSON.stringify({ property: this.file_properties })
                };
                // 物件情報送信
                const res = await fetch(process.env.VUE_APP_API_BASE_URL + "/property/import", param)
                if (res.status == 200) {
                    this.file_message = add_count + "件の物件を追加しました。5秒後にリロードをします。"
                    setTimeout(() => {location.reload()}, 5000);
                } else if (res.status == 401) {
                    this.unauth()
                } else {
                    this.other_error(res.status)
                }
            };
        },
        property_search() {
            this.properties = []
            for (let j = 0; j < this.master_properties.length; j++) {
                this.properties.push(this.master_properties[j])
            }
            for (let i = this.properties.length - 1; i >= 0; i--) {
                if (this.properties[i].info.section.indexOf(this.selected_status) == -1 || this.properties[i].info.person.indexOf(this.selected_manager) == -1
                    || this.properties[i].info.prefecture.indexOf(this.selected_prefecture) == -1 || this.properties[i].info.municipality.indexOf(this.selected_municipality) == -1) {
                    this.properties.splice(i, 1);
                }
            }
            this.selected_status = "";
            this.selected_manager = "";
            this.selected_prefecture = "";
            this.selected_municipality = ""
        },
        move_description_screen(property) {
            this.$router.push({ path: '/pc/property', query: { property_id: property.property_id } })
        },
        filter_flg_change() {
            this.filter_flg = !this.filter_flg
            if (this.filter_flg_text == "＋") {
                this.filter_flg_text = "－";
            } else {
                this.filter_flg_text = "＋";
            }
        },
        export_flg_change() {
            this.export_flg = !this.export_flg
        },
        import_flg_change() {
            this.import_flg = !this.import_flg
        },
        // 並べ替えメソッド
    },
    created: async function () {
        const param = {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + this.$store.state.login_token
            },
        };
        // 物件情報取得
        const res = await fetch(process.env.VUE_APP_API_BASE_URL + "/property/list", param)
        if (res.status == 200) {
            // レスポンス情報の取得
            var data = await res.json()
            // console.log(data)
            for (var i = 0; i < data.property.length; i++) {
                // 削除されていないかつ、テンプレートでない場合表示させる
                if (!data.property[i].delete_flg && data.property[i].property_id !== '0000') {
                    this.properties.push(data.property[i]);
                    this.master_properties.push(data.property[i]);
                    this.csv_properties.push(data.property[i].info.name)
                    //プルダウンフィルター項目の取得
                    if (this.section_status_list.indexOf(data.property[i].info.section) == -1) {
                        this.section_status_list.push(data.property[i].info.section);
                    }
                    if (this.managers.indexOf(data.property[i].info.person) == -1) {
                        this.managers.push(data.property[i].info.person);
                        data.property[i]
                    }
                    if (this.prefectures.indexOf(data.property[i].info.prefecture) == -1) {
                        this.prefectures.push(data.property[i].info.prefecture);
                    }
                    if (this.municipalities.indexOf(data.property[i].info.municipality) == -1) {
                        this.municipalities.push(data.property[i].info.municipality);
                    }
                }
            }
            // 並べ替え
            this.section_status_list.sort();
            this.managers.sort();
            this.prefectures.sort();
            this.municipalities.sort();
        } else if (res.status == 401) {
            this.unauth()
        } else {
            this.other_error(res.status)
        }
        // console.log(this.csv_properties)
    },
    computed: {
        filtered_properties() {
            var filtered_properties = [];
            // キーワード検索＋契約状態によるフィルター
            for (let i in this.properties) {
                if (this.properties[i].info.name.indexOf(this.search_keyword) !== -1 && this.properties[i].info.canceled_flg == this.selected_canceled_flg) {
                    filtered_properties.push(this.properties[i])
                }
            }
            return filtered_properties;
        }
    }
})
</script>

<style scoped>
h2 {
    margin: 0;
    padding: 10px 0;
    width: 100%;
    color: #fff;
}

a {
    text-decoration: none;
    width: 50%;
    text-align: left;
    margin-left: 10%;
}

#filter_wrapper_title {
    display: flex;
    background-color: #1a3f66ff;
    position: relative;
}

#filter_flg_btn {
    font-size: 40px;
    background-color: #1a3f66ff;
    border: none;
    color: #fff;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
    height: 56px;
    width: 56px;
}

.filter_wrapper {
    margin: 0 auto;
    text-align: center;
    border: solid 2px gray;
}

.input_search {
    margin-bottom: 20px;
}

.input_search_title {
    font-size: 18px;
}

.input_text {
    width: 95%;
    padding: 0.5em;
    border: 1px solid #999;
    background: #f5f5f5;
}

.pulldown_search {
    width: 90%;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.pulldown_search::after {
    content: "";
    display: block;
    width: 30%;
}

.item_select {
    text-align: center;
    font-size: 16px;
    width: 100%;
    padding: 10px 0px;
    border-radius: 0px 0px 5px 5px;
}

.flex_box {
    width: 30%;
    margin-bottom: 20px;
}

.search_button {
    color: #fff;
    border: 1px none;
    border-radius: 10px;
    background-color: #eb6100;
    font-size: 20px;
    padding: 15px 0;
}

.search_button:active {
    background-color: rgb(241, 127, 127);
}

.filter_title {
    background-color: #1a3f66ff;
    margin: 0;
    width: 100%;
    padding: 10px 0px;
    color: #fff;
    border-radius: 5px 5px 0px 0px;

}

.btn_list {
    text-align: center;
    width: 90%;
    display: flex;
    margin: 0 auto;
    margin-top: 20px;
    justify-content: space-between;
}

.btn_item {
    width: 20%;
    background-color: #1a3f66ff;
    color: #fff;
    padding: 10px;
    border-radius: 10px;

}

.modal_title {
    border-radius: 20px 20px 0px 0px;
    margin-bottom: 100px;
}

.modal_btn {
    padding: 20px;
    margin: 0;
    width: 40%;
}

.import_file {
    font-size: 16px;
}

.flex_btn {
    width: 30%;
}

#canceled_menu{
    width: 20%;
    margin-top: 1%;
    background-color: #e4e4e4;
    color: black;
    text-align: center;
}

.property_list {
    padding: 0px;
    margin-bottom: 500px;
}

.property_item {
    display: flex;
    justify-content: space-around;
    align-items: center;
    border: 2px gray solid;
    border-radius: 5px;
    width: 100%;
    height: 30px;
    list-style: none;
    margin-top: 5px;
}

.property_item:hover {
    background-color: rgba(26, 63, 102, 0.100);
}

a.property_item {
    font-size: 24px;

}
</style>