<template>
  <div>
    <h2>テストページ1</h2>
    <router-link to="/pc/login">pc版ログイン画面へ</router-link>
    <br>
    <router-link to="/login">スマホ版ログイン画面へ</router-link>
    <br>
    <router-link to="/pc/env">PCテスト</router-link>
  </div>
</template>

<script>
  import { Mixin } from '../mixin'
  export default({
    mixins: [Mixin],
    mounted: function(){
        //画面に入った瞬間にリダイレクト
        if (navigator.userAgent.match(/iPhone|Android.+Mobile/)) {
           // スマホ版ログイン画面
          this.move_screen('/login')
        } else {
           // PC版ログイン画面
          this.move_screen('/pc/login')
        }
    },
  })
</script>