<template>
<!-- ヘッダー -->
<header class="flex-header">
  <a class="header_back" @click="back_page()" v-show="header_back"><img src="@\assets\icons8-左-50.png" alt=""></a>
  <a class="header_back" v-show="!header_back"><div class="block"></div></a>
  <h1 class="header_title">{{ title }}</h1>
  <!-- 三点リーダー -->
  <div class="hamburger_menu">
    <img class="three_leader" src="@\assets\three_leader_icon.png" @click="open_modal()">
    <!-- モーダル部分 -->
    <div id="overlay" v-show="show_modal">
      <div id="content">

        <div class="modal_title_">
          <h2>ログアウト</h2>
        </div>

        <div class="btn">
          <p>ログアウトしますか？</p>
          <div class="btn_flex">
            <input class="header_logout" type="button" value="ログアウト" @click="logout()">
            <input class="logout_cancel" type="button" value="閉じる" @click="close_modal()">
          </div>
        </div>

      </div>
    </div>
  </div>
</header>
</template>

<script scoped>
import { Mixin } from '../mixin'

export default ({
  mixins: [Mixin],
  data() {
    return {
      show_modal: false,
    }
  },
  methods: {
    open_modal(){
      this.show_modal = true;
    },
    close_modal(){
      this.show_modal = false;
    },
    logout(){

      // ログアウト処理(Token破棄)
      this.$store.commit('change_token', "")

      // ログイン画面に戻す
      this.$router.push('/login');
    },
    back_page(){
      if(this.history_back){
        this.$router.go(-1)
      }else{
        this.$router.push(this.path)
      }
    }
  },
  props: {
    title: {
      type: String,
      required: true
    },
    header_back: {
      type: Boolean,
      default: true
    },
    history_back:{
      type: Boolean,
      default:false
    },
    path: {
    }
  }
})
</script>

<style scoped>
/* header_css */
.block{
  width: 50px;
  height: 50px;
}
.flex-header{
  display: flex;
  justify-content: flex-end;
  text-align: center;
  align-items: center;
  position: sticky;
  z-index: 5;
  height: 60px;
  top: 0;
  left: 0;
  background-color: #EEEEEE;
  border-bottom: 1px black solid;
  padding-right: 20px;
  gap: 40px;
}
.header_back{
  margin-left: 10px;
  margin-top: 4px;
  font-weight: 900;
}
.header_title{
  width: 60%;
  font-size: 1.8em;
  margin: auto;
}
.three_leader{
  width: 30px;
  margin-top: 5px;
}
/* ハンバーガーメニュー */
.btn_leader{
  border: none;
  font-weight: 1000;
  font-size: 20px;
}
#overlay{
  /* 要素を重ねた時の順番 */
  z-index:2;

  /* 画面全体を覆う設定 */
  position:fixed;
  top:0;
  left:0;
  width:100%;
  height:100%;
  background-color:rgba(0,0,0,0.5);

  /* 画面の中央に要素を表示させる設定 */
  display: flex;
  align-items: center;
  justify-content: center;
}
#content{
  z-index:3;
  overflow-y: auto;
  max-height: 90%;
  height: 170px;
  width:77%;
  background:#fff;
  position: relative;
  border-radius: 5px;
}
.modal_title_{
  position: fixed;
  border-radius: 5px;
  width: 77%;
  margin: 0 auto;
  background-color: rgb(255, 255, 255);
}
.btn{
  margin-top: 75px;
}
.btn_flex{
  display: flex;
  gap: 10px;
  margin:20px 20px 0 0;
  justify-content: flex-end;  
}
.btn_flex input{
  width: auto;
  padding: 7px 21px;
  border-radius: 5px;
}
.logout_cancel{
  background-color: #fff;
  border: 1px solid gray;
  height: 40px;
}
.header_logout{
  background-color: #1a3f66ff;
  border: 1px solid #1a3f66ff;
  color: #fff;
  height: 40px;
}

</style>