<template>
<div>
    <div class="title">
        <h2>{{ institution.ja_name }}</h2>
        <button class="flg_btn" @click="flg_change('item_flg')">{{ item_flg_text }}</button>
    </div>
    <div class="institution_info" v-if=item_flg>
        <div class="past_report" v-show="past_report_check">
            <button class="orange_btn" @click="past_flg_change()" v-if="past_data_check(past_institution)"
                v-show="isPast">前回の確認</button>
            <div v-if="past_flg_test == true && item_flg == true" class="modal">
                <div class="modal_content">
                    <h3 class="modal_title">前回報告書確認</h3>
                    <div class="modal_info">
                        <past_item :institution="past_institution"></past_item>
                        <button class="modal_close" @click="past_flg_change()">閉じる</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="photo_title">
            <h2>写真</h2>
            <button class="photo_flg_btn" @click="flg_change('photo_flg')">{{ photo_flg_text }}</button>
        </div>
        <div v-if="photo_flg">
            <p class="font_14">※施設のチェック内容がこちらに記載されています<br>
                （写真上のチェックがついているものが報告書に添付されます）</p>
            <ul id="photo_items">
                <li class="photo_item" v-for="(item, index) of institution.items" :key="item">
                    <!-- 報告書に添付するかチェックボックス -->
                    <label class="margin_top" :for='"photo_" + index'>
                        <input type="checkbox" :id='"photo_" + index' @change="reCountPhotoNum(index, item.select)"
                            v-model="item.select" :disabled=Boolean(!item.photo)>報告書に添付
                    </label>
                    <!-- 写真の選択と表示 -->
                    <br>
                    <label class="file_label">
                        <input class="margin_top file_select" type="file" ref="preview"
                            @change="institution_input_base64($event.target.files[0], institution, index, $event)">ファイルを選択
                    </label>
                    <br>
                    <img class="margin_top" :src="item.photo">
                    <!-- <p v-if=item.select style="margin-bottom: 0;">写真{{ photoNumView(index) }}</p> -->
                    <!-- 定型文の選択 -->
                    <select v-model="item.phrase" style="width:90%;margin-top: 20px;">
                        <option value="0">異常なし</option>
                        <option value="1">掲示物の掲示</option>
                        <option value="2">私物放置のため短冊貼り付け</option>
                        <option value="3">違法駐輪のため短冊貼り付け</option>
                        <option value="4">粗大ごみ放置のため短冊貼り付け</option>
                        <option value="5">ごみ、汚れがあったため対応済</option>
                    </select>
                    <!-- 内容（テキスト） -->
                    <p>内容</p>
                    <textarea class="free_text" v-model="item.text" placeholder="内容を記述してください" rows="5"
                        maxlength="140"></textarea>
                    <button class="delete_btn" v-if="institution.items[index]"
                       @click="delete_institution_item(institution, index)">この項目を消去</button>
                </li>
            </ul>
            <!-- 複数選択 -->
            <label class="file_label" v-if="institution.items.length < 8">
                <input class="file_select" type="file" @change="add_institution_photo($event, institution)" multiple="multiple">写真を選択
            </label>
        </div>
        <div class="memo_title">
            <h2>社内メモ</h2>
            <button class="memo_flg_btn" @click="flg_change('memo_flg')">{{ memo_flg_text }}</button>
        </div>
        <div v-if="memo_flg">
            <p class="font_14">※社内で共有したい内容はこちらに書いてください</p>
            <textarea class="free_text" v-model="institution.memo" rows="5" cols="100" placeholder="メモ内容（※報告書には記載されません）"
                maxlength="140"></textarea>
        </div>
    </div>      
</div>
</template>

<script scoped>
import past_item from "./pc_report_input_before_component.vue"
import { Mixin } from "../mixin"


export default ({
    data() {
        return {
            item_flg: false,
            item_flg_text: "＋",
            photo_flg: false,
            photo_flg_text: "＋",
            memo_flg: false,
            memo_flg_text: "＋",
            info: this.institution,
            past_flg_test: false,
            photoCountArray: [],
            isPast: true,
        }
    },
    mixins: [Mixin],
    components: {
        past_item
    },
    props: {
        institution: {
        },
        past_institution: {
        },
        past_report_check: {
        },
    },
    created: function () {
        if (this.institution) {
            for (let item of this.institution.items) {
                this.photoCountArray.push(item.select)
            }
        }
    },
    methods: {
        past_flg_change() {
            this.past_flg_test = !this.past_flg_test
        },
        past_data_check(data) {
            if (data === undefined) {
                return false
            } else {
                if (data.items.length == 0) {
                    this.isPast = false;
                }
                return true
            }
        },
        flg_change(flg) {
            this[flg] = !this[flg]
            if (this[flg + '_text'] == "＋") {
                this[flg + '_text'] = "－";
            } else {
                this[flg + '_text'] = "＋";
            }
        },
        reCountPhotoNum(index, selected) {
            this.photoCountArray[index] = selected;
        },
        photoNumView(index) {
            var count = 0;
            for (let i = 0; i < index + 1; i++) {
                if (this.photoCountArray[i] == true) {
                    count++;
                }
            }
            return count;
        },
    },

})
</script>

<style scoped>
h2 {
    margin: 0;
    padding: 10px 0;
    width: 100%;
    color: #fff;
}

ul {
    padding: 0;
}

li {
    list-style: none;
}

img {
    width: 100%;
}

.title {
    display: flex;
    background-color: #1a3f66ff;
    position: relative;
    height: 56px;
}

.font_14 {
    font-size: 14px;
}

.margin_top {
    margin-top: 20px;
}

.flg_btn {
    font-size: 40px;
    background-color: #1a3f66ff;
    border: none;
    color: #fff;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 0;
    height: 56px;
    width: 56px;
}

.photo_title {
    display: flex;
    background-color: rgba(26, 63, 102, 0.823);
    position: relative;
}

.photo_flg_btn {
    font-size: 40px;
    background-color: rgba(26, 63, 102, 0);
    border: none;
    color: #fff;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 0;
    height: 56px;
    width: 56px;
}

.free_text {
    width: 90%;
}

.memo_title {
    display: flex;
    background-color: rgba(26, 63, 102, 0.823);
    position: relative;
}

.memo_flg_btn {
    font-size: 40px;
    background-color: rgba(26, 63, 102, 0);
    border: none;
    color: #fff;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 0;
    height: 56px;
}

#photo_items {
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    background-color: white;
}

.photo_item {
    width: 22%;
    margin: 0.5%;
    border: solid 5px rgba(26, 63, 102, 0.823);
    border-radius: 10px;
}

.orange_btn {
    margin: 20px;
}

.delete_btn {
    margin: 30px;
    background-color: #1a3f66ff;
    font-size: 16px;
    color: #fff;
    border: none;
    border-radius: 5px;
    margin-bottom: 5px;
}

.modal_content {
    height: 70%;
}

.modal_info {
    background-color: #fff;
}

.file_select {
    display: none;
}

.file_label {
    display: inline-block;
    padding: 5px;
    margin-top: 15px;
    color: black;
    background-color: lightgray;
    cursor: pointer;
    border: 1px solid black;
    border-radius: 5px;
}
</style>