<template>
<div>
    <pc_head title="物件詳細" path="/pc/property/list"></pc_head>
    <div class="wrapper">
        <div class="property_details">
            <div class="property_info">
                <h2 class="info_title">物件名：{{receive_info.name}}</h2>
                <div class="info_list">
                    <div id="number_title" class="box_left">物件番号</div>
                    <div id="number" class="box_right">{{receive_info.number}}</div>
                    <div id="class_title" class="box_left">分類ステータス</div>
                    <div id="class" class="box_right">{{receive_info.section}}</div>
                    <div id="post_code_title" class="box_left">郵便番号</div>
                    <div id="post_code" class="box_right">{{receive_info.post_code}}</div>
                    <div id="prefecture_title" class="box_left">都道府県</div>
                    <div id="prefecture" class="box_right">{{receive_info.prefecture}}</div>
                    <div id="municipality_title" class="box_left">市区町村</div>
                    <div id="municipality" class="box_right">{{receive_info.municipality}}</div>
                    <div id="address_title" class="box_left">番地</div>
                    <div id="address" class="box_right">{{receive_info.address}}</div>
                    <div id="area_title" class="box_left">エリア</div>
                    <div id="area" class="box_right">{{receive_info.area}}</div>
                    <div id="line_title" class="box_left">沿線</div>
                    <div id="line" class="box_right">{{receive_info.line}}</div>
                    <div id="station_title" class="box_left">最寄り駅</div>
                    <div id="station" class="box_right">{{receive_info.station}}</div>
                    <div id="manager_title" class="box_left">物件担当</div>
                    <div id="manager" class="box_right">{{receive_info.person}}</div>
                    <div id="frequency_title" class="box_left">巡回頻度</div>
                    <div id="frequency" class="box_right">{{receive_info.frequency}}</div>
                    <div id="month_title" class="box_left">巡回実施予定月</div>
                    <div id="month" class="box_right">{{receive_info.month}}</div>
                    <div id="owner_title" class="box_left">オーナー名</div>
                    <div id="owner" class="box_right">{{receive_info.owner}}</div>
                    <div id="subcontractor_title" class="box_left">下請け先</div>
                    <div id="subcontractor" class="box_right">{{receive_info.subcontractor}}</div>
                    <div id="canceled_title" class="box_left">契約状態</div>
                    <div v-if = 'receive_info.canceled_flg' id="canceled" class="box_right">解約済み</div>
                    <div v-else class="box_right">契約中</div>
                    <div id="auto_lock_title" class="box_left">オートロック番号</div>
                    <div id="auto_lock" class="box_right">{{receive_info.auto_lock}}</div>
                    <div id="key_box_title" class="box_left">キーボックスの場所</div>
                    <div id="key_box" class="box_right">{{receive_info.key_box}}</div>
                    <div id="remarks_title" class="box_left">特記事項</div>
                    <textarea id="remarks" class="box_right" cols="5" disabled>{{receive_info.remarks}}</textarea>
                </div>
            </div>
            <div class="property_institution">
                <h1 id=" institution_title">施設一覧</h1>
                <div id="institution_list">
                    <div class="institution_item" v-for="institution of institution_items" :key="institution">
                        {{institution}}</div>
                </div>
            </div>
        </div>
    </div>
    <div class="btn_list">
        <button id="delete_btn" class="orange_btn" @click="flg_change('delete_flg')">削除する</button>
        <div v-if="delete_flg" class="modal">
            <div class="modal_content">
                <h2 class="modal_title">物件を削除する</h2>
                <p id="delete_info">物件名:{{receive_info.name}} <br />
                    物件番号:{{receive_info.number}}<br />
                    を削除しますがよろしいですか？
                </p>
                <button class="modal_btn orange_btn" @click="delete_property">削除</button>
                <button class="modal_close" @click="flg_change('delete_flg')">戻る</button>
            </div>
        </div>
        <button v-if="!this.receive_info.canceled_flg" class="orange_btn" @click="flg_change('canceled_modal_flg')">解約済み</button>
        <div v-if="canceled_modal_flg" class="modal">
            <div class="modal_content">
                <h2 class="modal_title">物件を解約済みする</h2>
                <p id="delete_info">物件名:{{receive_info.name}} <br />
                    の契約状態を解約済みにしますがよろしいでしょうか？ <br />
                    <a style="color: red;">※報告業務がすべて行われたことを確認してください</a>
                </p>
                <button id ='canceled_comfirm' class="modal_btn orange_btn" @click="canceled_property">解約済み</button>
                <button class="modal_close" @click="flg_change('canceled_modal_flg')">戻る</button>
            </div>
        </div>
        <button id="edit_btn" class="orange_btn"
            @click="move_screen({path:'/pc/property/update',query: {property_id: this.$route.query.property_id}})">編集する</button>
    </div>
</div>
</template>

<script>
import { Mixin } from '../mixin'
import pc_head from "./pc_header.vue"


export default {
    components: {
        pc_head
    },
    mixins: [Mixin],
    data() {
        return {
            receive_info: {},
            receive_institution: {},
            institution_items: [],
            delete_flg: false,
            canceled_modal_flg: false
        }
    },
    methods: {
        flg_change(target_flg) {
            this[target_flg] = !this[target_flg]
        },
        async delete_property() {
            const delete_property_id = { property_id: this.receive_info.property_id };
            const param = {
                method: "PUT",
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + this.$store.state.login_token
                },
                body: JSON.stringify({ property: delete_property_id })
            };
            // 物件情報削除
            const res = await fetch(process.env.VUE_APP_API_BASE_URL + "/property/delete/" + this.$route.query.property_id, param)
            if (res.status == 200) {
                this.move_screen('/pc/property/list')
            } else if (res.status == 401) {
                this.unauth()
            } else {
                this.other_error(res.status)
            }
        },
        async canceled_property(){
            this.receive_info.canceled_flg = true;
            const update_info = { info: this.receive_info, institutions: this.receive_institution };
                const param = {
                    method: "PUT",
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + this.$store.state.login_token
                    },
                    body: JSON.stringify({ property: update_info }),
                };
            // 解約済みステータスに変更
            const res = await fetch(process.env.VUE_APP_API_BASE_URL + "/property/update/" + this.$route.query.property_id, param)
            if (res.status == 200) {
                location.reload()            
            } else if (res.status == 401) {
                this.unauth()
            } else {
                this.other_error(res.status)
            }
        },

    },
    created: async function () {
        const param = {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + this.$store.state.login_token
            },
        };
        // 物件情報取得
        const res = await fetch(process.env.VUE_APP_API_BASE_URL + "/property/" + this.$route.query.property_id, param)
        if (res.status == 200) {
            // レスポンス情報の取得
            var data = await res.json()
            this.receive_info = data.property.info;
            this.receive_institution = data.property.institutions;

            // 施設情報の有無を判断
            if (data.property.institutions.elevator) {
                this.institution_items.push("エレベーター");
            }
            if (data.property.institutions.hallway) {
                this.institution_items.push("共用廊下");
            }
            if (data.property.institutions.outside_stairs) {
                this.institution_items.push("外階段");
            }
            if (data.property.institutions.planting) {
                this.institution_items.push("植栽");
            }
            if (data.property.institutions.mechanical_parking) {
                this.institution_items.push("機械式駐車場");
            }
            if (data.property.institutions.flat_parking) {
                this.institution_items.push("平置き駐車場");
            }
            if (data.property.institutions.trash) {
                this.institution_items.push("ゴミ庫");
            }
            if (data.property.institutions.entrance) {
                this.institution_items.push("エントランス");
            }
            if (data.property.institutions.rack_b_parking) {
                this.institution_items.push("ラック式駐輪場");
            }
            if (data.property.institutions.flat_b_parking) {
                this.institution_items.push("平置き駐輪場");
            }
            if (data.property.institutions.flat_m_parking) {
                this.institution_items.push("平置きバイク置き場");
            }
            if (data.property.institutions.board) {
                this.institution_items.push("掲示板・掲示物");
            }
            if (data.property.institutions.mailbox) {
                this.institution_items.push("メールボックス");
            }
            if (data.property.institutions.trash_stocker) {
                this.institution_items.push("ゴミストッカー");
            }
            if (data.property.institutions.delivery_box) {
                this.institution_items.push("宅配ボックス");
            }
            if (data.property.institutions.janitor) {
                this.institution_items.push("管理室");
            }
            if (data.property.institutions.tools) {
                this.institution_items.push("清掃道具");
            }
            if (data.property.institutions.receiving_tank) {
                this.institution_items.push("受水槽");
            }
            if (data.property.institutions.cesspool) {
                this.institution_items.push("汚水槽");
            }
            if (data.property.institutions.septic_tank) {
                this.institution_items.push("浄化槽");
            }
            if (data.property.institutions.elevated_tank) {
                this.institution_items.push("高架水槽");
            }
            if (data.property.institutions.booster_pump) {
                this.institution_items.push("増圧ポンプ");
            }
            if (data.property.institutions.drainage_pump) {
                this.institution_items.push("排水ポンプ");
            }
            if (data.property.institutions.firefight_equipment) {
                this.institution_items.push("消防設備");
            }
            if (data.property.institutions.air_conditioner) {
                this.institution_items.push("空調設備");
            }
            if (data.property.institutions.electrical_work) {
                this.institution_items.push("電気工作物");
            }
            if (data.property.institutions.emergency_elevator) {
                this.institution_items.push("非常用エレベーター");
            }
            if (data.property.institutions.disposer) {
                this.institution_items.push("ディスポーザー");
            }
            if (data.property.institutions.distribution_board) {
                this.institution_items.push("分電盤");
            }
            if (data.property.institutions.mdf_board) {
                this.institution_items.push("MDF盤");
            }
            if (data.property.institutions.inner_staircase) {
                this.institution_items.push("内階段");
            }
            if (data.property.institutions.inner_corridor) {
                this.institution_items.push("内廊下");
            }
            if (data.property.institutions.rooftop) {
                this.institution_items.push("屋上");
            }
            if (data.property.institutions.basement) {
                this.institution_items.push("地下室");
            }
            if (data.property.institutions.store) {
                this.institution_items.push("店舗");
            }
            if (data.property.institutions.office) {
                this.institution_items.push("事務所");
            }
            if (data.property.institutions.kids_room) {
                this.institution_items.push("キッズルーム");
            }
            if (data.property.institutions.assembly_room) {
                this.institution_items.push("集会室");
            }
            if (data.property.institutions.locker_room) {
                this.institution_items.push("ロッカールーム");
            }
            if (data.property.institutions.play_lot) {
                this.institution_items.push("プレイロット");
            }
            if (data.property.institutions.aed) {
                this.institution_items.push("AED");
            }
            if (data.property.institutions.vending_machine) {
                this.institution_items.push("自動販売機");
            }
            if (data.property.institutions.outer_circumference) {
                this.institution_items.push("外回り");
            }
            if (data.property.institutions.toilet) {
                this.institution_items.push("トイレ");
            }
        } else if (res.status == 401) {
            this.unauth()
        } else {
            this.other_error(res.status)
        }
    }
}
</script>

<style scoped>
.title_pc {
    margin-bottom: 0;
}

.property_info {
    margin-bottom: 50px;
}

.info_list {
    margin: 0 auto;
    display: grid;
    align-items: flex-end;
    font-weight: bold;
    grid-template-rows: repeat(8, 40px);
    grid-template-columns: repeat(6, 1fr);
    grid-template-areas:
        "area11 area12 area13 area14 area15 area16"
        "area21 area22 area23 area24 area25 area26"
        "area31 area32 area33 area34 area35 area36"
        "area41 area42 area43 area44 area45 area46"
        "area51 area52 area53 area54 area55 area56"
        "area61 area62 area63 area64 area64 area64"
        "area71 area72 area72 area72 area72 area72"
        "area71 area72 area72 area72 area72 area72";
}

.box_right {
    font-weight: normal;
    margin-right: 5px;
    text-align: left;
}

.box_right::after {
    background-color: rgb(203, 203, 203);
    border-radius: 0px 5px 5px 0px;
    content: "";
    display: block;
    height: 3px;
}

.box_left::after {
    background-color: rgb(203, 203, 203);
    border-radius: 5px 0px 0px 5px;
    content: "";
    display: block;
    height: 3px;
}

#institution_list {
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
}

.institution_item {
    border-bottom: 5px solid rgb(203, 203, 203);
    width: 16%;
    margin: 0 0.3% 5px;
}

.modal_content {
    width: 40%;
}

#delete_info {
    margin-top: 50px;
}

#canceled_comfirm {
    font-size: 10px;
}

.modal_btn {
    padding: 10px 40px;
}


/* 以下情報詳細項目CSS */
#number_title {
    display: inline;
    grid-area: area11;
}

#number {
    grid-area: area12;
}

#class_title {
    grid-area: area13;
}

#class {
    grid-area: area14;
}

#post_code_title {
    grid-area: area15;
}

#post_code {
    grid-area: area16;
}

#prefecture_title {
    grid-area: area21;
}

#prefecture {
    grid-area: area22;
}

#municipality_title {
    grid-area: area23;
}

#municipality {
    grid-area: area24;
}

#address_title {
    grid-area: area25;
}

#address {
    grid-area: area26;
}

#area_title {
    grid-area: area31;
}

#area {
    grid-area: area32;
}

#line_title {
    grid-area: area33;
}

#line {
    grid-area: area34;
}

#station_title {
    grid-area: area35;
}

#station {
    grid-area: area36;
}

#manager_title {
    grid-area: area41;
}

#manager {
    grid-area: area42;
}

#frequency_title {
    grid-area: area43;
}

#frequency {
    grid-area: area44;
}

#month_title {
    grid-area: area45;
}

#month {
    grid-area: area46;
}

#owner_title {
    grid-area: area51;
}

#owner {
    grid-area: area52;
}

#subcontractor_title {
    grid-area: area53;
}

#subcontractor {
    grid-area: area54;
}

#canceled_title {
    grid-area: area55;
}

#canceled {
    grid-area: area56;
}

#auto_lock_title {
    grid-area: area61;
}

#auto_lock {
    grid-area: area62;
}

#key_box_title {
    grid-area: area63;
}

#key_box {
    grid-area: area64;
}

#remarks_title {
    grid-area: area71;
}

#remarks {
    grid-area: area72;
    background-color: #fff;
    font-size: 20px;
    font-weight: bold;
    resize: none;
    border-bottom: solid rgb(203, 203, 203) 3px;
    height: 80%;
}
</style>