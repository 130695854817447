<template>
<header class="wrapper">
    <button v-bind:class="{hidden: back_active}" class="back_btn" @click="back_screen(flg)">戻る</button>
    <h1>{{title}}</h1>
    <button class="logout_btn" @click="logout()">ログアウト</button>
</header>
</template>

<script scoped>
import { Mixin } from '../mixin'

export default ({
    mixins: [Mixin],
    data() {
        return {

        }
    },
    methods: {
        logout() {
            this.$store.commit('change_token',"");
            this.$router.push('/pc/login');
        },
        back_screen(flg){
            if(flg){
                // console.log(flg)
                this.$router.go(-1)
                console.log("ヒストリーを参考にしております")
                
            }else{
                this.$router.push(this.path)
            }
        }
    },
    props: {
        title: {
            type: String,
            required: true
        },
        back_active: {
            type:Boolean,
            default:false
        },
        flg:{
            type:Boolean,
            default:false
        },
        path:{
        }
    }
})
</script>

<style scoped>
html{
    margin: 60px auto 0 auto;
}

header {
    margin: 60px auto 20px auto;
    display: flex;
    justify-content: space-between;
}

header button{
    width: 20%;
    height: fit-content;
    margin-top: 20px;
    padding: 7px 0;
    border-radius: 10px;
}

header h1{
    width: 54%;
}

.back_btn{
    color: #fff;
    background-color: #2c3e50;
}

.logout_btn{
    color: black;
    background-color: rgba(226, 226, 226, 0.884);
}

.hidden{
    visibility:hidden;
}


</style>