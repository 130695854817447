<template>
<div>
    <pc_head title="TOP画面" :back_active="true"></pc_head>
    <div id="top_page">
        <button id="report_list_btn" class="btn top_pc_btn" @click="move_screen('/pc/report/list')">過去報告一覧</button>
        <button v-if="btn_flg" id="system_management_btn" class="btn top_pc_btn" @click="move_screen('/pc/system/management')">システム管理</button>
    </div>
</div>
</template>
<script>
import {Mixin} from '../mixin'
import pc_head from "./pc_header.vue"


export default ({
    mixins:[Mixin],
    data() {
        return {
            authority_id:'',
            btn_flg:false
        }
    },
    components:{
        pc_head
    },
    computed: {
        new_token() {
            return this.$store.state.login_token
        }
    },
    created:function(){
        this.btn_flg = true
    }
})

</script>


<style scoped>

#top_page{
    margin-top: 100px;
}
html{
    min-width: 33%;
}

.btn{
    font-size: 48px;
    margin: 0 5%;
    width: 25%;
    height: 200px;
}

@media screen and (max-width: 816px) {
    .btn{
        font-size: 28px;
        margin: 0 5%;
        width: 25%;
        height: 200px;
    }
}
@media screen and (max-width: 912px) {
    .btn{
        font-size: 28px;
        margin: 0 5%;
        width: 25%;
        height: 200px;
    }
}
@media screen and (max-width: 1200px) {
    .btn{
        font-size: 36px;
        margin: 0 5%;
        width: 25%;
        height: 200px;
    }
}
</style>
