import { createRouter, createWebHistory } from 'vue-router'
import router_test_first from '@/components/router_test_first.vue'

// スマホ用import
import login_vue from '@/components/login_vue.vue'
import property_list from '@/components/property_list.vue'
import property_top from '@/components/property_top.vue'
import property_description from '@/components/property_description.vue'
import report_list from '@/components/report_list.vue'
import report_input from '@/components/report_input.vue'
import report_input_past from '@/components/report_input_past.vue'
import report_input_update from '@/components/report_input_update.vue'
import report_report from '@/components/report_report.vue'
import report_report_confirm from '@/components/report_report_confirm.vue'

// PC用import
import pc_login from '@/components/pc_login.vue'
import pc_top from '@/components/pc_top.vue'
import pc_system_management from '@/components/pc_system_management.vue'
import pc_report_list from '@/components/pc_report_list.vue'
import pc_report_past from '@/components/pc_report_past.vue'
import pc_report_past_confirm from '@/components/pc_report_past_confirm.vue'
import pc_report_input_past from '@/components/pc_report_input_past.vue'
import pc_report_input_update from '@/components/pc_report_input_update.vue'
import pc_property_list from '@/components/pc_property_list.vue'
import pc_property_description from '@/components/pc_property_description.vue'
import pc_property_update from '@/components/pc_property_update.vue'
import pc_property_add from '@/components/pc_property_add.vue'
import pc_user_list from '@/components/pc_user_list.vue'
import pc_user_description from '@/components/pc_user_description.vue'
import pc_destination_list from '@/components/pc_destination_list.vue'
import pc_destination_description from '@/components/pc_destination_description.vue'
import pc_destination_mail from '@/components/pc_destination_mail.vue'
import pc_destination_add from '@/components/pc_destination_add.vue'
import pc_destination_update from '@/components/pc_destination_update.vue'

const routes = [
  // スマホ用route
  {
    path: '/',
    name: 'router_test_first',
    component: router_test_first,
    meta: { title: '分岐テストページ', desc: 'PC/スマホページの分岐ページ' }
  },
  {
    path: '/login',
    name: 'login_vue',
    component: login_vue
  },
  {
    path: '/property/list',
    name: 'property/list',
    component: property_list
  },
  {
    path: '/property/top/:property_id?',
    name: 'property_top',
    component: property_top
  },
  {
    path: '/property/:property_id?',
    name: 'property_description',
    component: property_description
  },
  {
    path: '/report/list/:property_id?',
    name: 'report_list',
    component: report_list
  },
  {
    path: '/report/input/:report_id?',
    name: 'report_input',
    component: report_input
  },
  {
    path: '/report/input/past/:report_id?',
    name: 'report_input_past',
    component: report_input_past
  },
  {
    path: '/report/input/update/:report_id?',
    name: 'report_update',
    component: report_input_update
  },
  {
    path: '/report/report/:report_id?',
    name: 'report_report',
    component: report_report
  },  
  {
    path: '/report/report/confirm',
    name: 'report_report_confirm',
    component: report_report_confirm
  },  

  // pc用route

  {
    path: '/pc/login',
    name: 'pc_login',
    component: pc_login
  },
  {
    path: '/pc/top',
    name: 'pc_top',
    component: pc_top
  },
  {
    path: '/pc/system/management',
    name: 'pc_system_management',
    component: pc_system_management
  },

  {
    path: '/pc/property/list',
    name: 'pc_poperty_list',
    component: pc_property_list
  },
  {
    path: '/pc/property/:property_id?',
    name: 'pc_property_description',
    component: pc_property_description
  },
  {
    path: '/pc/property/update/:property_id?',
    name: 'pc_property_update',
    component: pc_property_update
  },
  {
    path: '/pc/property/add',
    name: 'pc_property_add',
    component: pc_property_add
  },
  {
    path: '/pc/user/list',
    name: 'pc_user_list',
    component: pc_user_list
  },
  {
    path: '/pc/user/:user_id?',
    name: 'pc_user_description',
    component: pc_user_description
  },
  {
    path: '/pc/report/list',
    name: 'pc_report_list',
    component: pc_report_list
  },
  {
    path: '/pc/report/past/:report_id?',
    name: 'pc_report_past',
    component: pc_report_past
  },
  {
    path: '/pc/report/past/confirm/:report_id?',
    name: 'pc_report_past_confirm',
    component: pc_report_past_confirm
  },
  {
    path: '/pc/report/input/past/:report_id?',
    name: 'pc_report_input_past',
    component: pc_report_input_past
  },
  {
    path: '/pc/report/input/update/:report_id?',
    name: 'pc_report_input_update',
    component: pc_report_input_update
  },
  {
    path: '/pc/destination/list',
    name: 'pc_destination_list',
    component: pc_destination_list
  },
  {
    path: '/pc/destination/:destination_id?',
    name: 'pc_destination_description',
    component: pc_destination_description
  },
  {
    path: '/pc/destination/mail/:destination_id?',
    name: 'pc_destination_mail',
    component: pc_destination_mail
  },
  {
    path: '/pc/destination/add',
    name: 'pc_destination_add',
    component: pc_destination_add
  },
  {
    path: '/pc/destination/update/:destination_id?',
    name: 'pc_destination_update',
    component: pc_destination_update
  },
]
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router