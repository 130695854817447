<template>
  <div id="overlay" v-show="show_modal">
    <div id="content">
      <img width="100" src="@\assets\712-88.gif" alt="">
    </div>
  </div>
</template>

<script>
export default {
  props:{
    show_modal:{
      type:Boolean,
    },
  }
}
</script>

<style scoped>

/* CSS */
#overlay {
  /* 要素を重ねた時の順番 */
  z-index: 9;

  /* 画面全体を覆う設定 */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);

  /* 画面の中央に要素を表示させる設定 */
  display: flex;
  align-items: center;
  justify-content: center;
}

#content {
  z-index: 10;
  overflow-y: auto;
  max-height: 95%;
  width: 30%;
  position: relative;
}
</style>