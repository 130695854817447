<template>
  <div>
      <pc_head title="送信先一覧" path="/pc/system/management"></pc_head>
      <!-- 顧客リストをフリーワード検索 -->
      <div class="flex_box">
          検索：<input type="text" name="search" size="30" maxlength="30" placeholder="フリーワード検索" v-model="search_keyword">
      </div>
      <!-- 顧客リスト -->
      <div class="wrapper">
          <ul class="destination_list">
              <li class="destination_item" v-for="destination in filtered_destinations" :key="destination" @click="move_description_screen(destination)">
                  <a class="destination_name">{{ destination.name }}</a>
                  <a class="destination_email" v-if="destination.to_addresses.length>1">{{ destination.to_addresses[0] }}...その他</a>
                  <a class="destination_email" v-else>{{ destination.to_addresses[0] }}</a>
              </li>
          </ul>
      </div>
      <button class="orange_btn add_orange_btn" style="width:30%" @click="move_screen(path='/pc/destination/add')">送信先を追加する</button>
  </div>
  </template>
  <script scoped>
  import { Mixin } from "../mixin";
  import pc_head from "./pc_header.vue"

  export default ({
      mixins: [Mixin],
      components: {
          pc_head
      },
      data() {
          return {
              master_destinations: [],          // 送信先一覧情報master
              destination: {},                  // テンプレート＆追加後情報
              data:"",
              search_keyword: "",
          }
      },
      methods: {
          move_description_screen(destination) {
            this.$router.push({ path: '/pc/destination', query: { destination_id: destination.destination_id } })
          },
      },
      created: async function () {
          const param = {
              method: "GET",
              headers: {
                  'Content-Type': 'application/json',
                  Authorization: 'Bearer ' + this.$store.state.login_token
              },
          };
          // 送信先一覧の情報取得
          const res = await fetch(process.env.VUE_APP_API_BASE_URL + "/destination/list", param)
          if (res.status == 200) {
              // レスポンス情報の取得
              this.data = await res.json()
                // console.log(this.data)
              for (let i = 0; i < this.data.destination.length; i++) {
                  if (!this.data.destination[i].delete_flg) {
                      this.master_destinations.push(this.data.destination[i])
                  }
              }
          } else if (res.status == 401) {
              this.unauth()
          } else {
              this.other_error(res.status)
          }
      },
      computed: {
        // 顧客リストをフリーワード検索,this.serach_wordを含む顧客のみリストに表示
        filtered_destinations() {
          let filtered_destinations = [];
          if (!this.search_keyword) {
            return this.master_destinations;
          } else {}
          for (let i in this.master_destinations) {
            if (this.master_destinations[i].name.toLowerCase().includes(this.search_keyword.toLowerCase())) {
                filtered_destinations.push(this.master_destinations[i]);
            }
          }
          return filtered_destinations;
        }
      }
  })
  </script>
  <style scoped>
  .add_btn {
      margin: 0;
      height: 50px;
  }
  
  .modal_content {
      height: 70%;
  }
  
  header {
      display: flex;
      margin-top: 60px;
  }
  
  .add_orange_btn {
      height: 50px;
  }
  
  .update_list {
      margin-top: 50px;
  }
  
  .flex {
      display: flex;
      justify-content: space-between;
      margin-top: 15px;
  }
  
  .update_left {
      width: 30%
  }
  
  .update_right {
      width: 70%;
  }
  
  .modal_close {
      margin-top: 20px;
  }
  
  .destination_list {
      padding: 0px;
  }
  
  .destination_item {
      display: flex;
      justify-content: space-around;
      align-items: center;
      border: 2px gray solid;
      border-radius: 5px;
      width: 100%;
      height: 30px;
      list-style: none;
      margin-top: 5px;
  }
  
  .destination_id,
  .destination_name,
  .destination_email {
      width: 30%;
  }
  
  .destination_item:hover {
      background-color: rgba(26, 63, 102, 0.100);
  }
  </style>