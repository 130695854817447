<template>
  <div>
    <pc_head title="送信先編集" :path="{path:'/pc/destination', query:{ destination_id: this.$route.query.destination_id }}"></pc_head>
    <div class="wrapper">
      <div class="destination_info">
        <div class="update_list">
          <div id="" class="flex" style="margin-bottom: 3%;">
            <div class="half-width">送信先名称：<input id="" class="" maxlength="100" v-model="destination.name" @input="destination.name = destination.name.replace(/[\\/:*?&quot;<>|]/g, '')"></div>
            <div id="" class="half-width">登録日：{{ destination.date }}</div>
          </div>
          <div class="flex">
            <!-- 宛先 -->
            <ul id="update_email" class="" style="width:45%;">
              <li v-for="(address,index) in destination.to_addresses" :key="index">
                 宛先{{ index+1 }}：<input type="email" class="half-width" maxlength="250" v-model="destination.to_addresses[index]" @input="destination.to_addresses[index] = destination.to_addresses[index].replace(/[\s!=()&quot;<>,;:\\]/g, '')">
              </li>
            </ul>
            <!-- CC -->
            <ul id="update_email" class="" style="width:45%;">
              <li v-for="(address,index) in destination.cc_addresses" :key="index">
                CC{{ index+1 }}：<input type="email" class="half-width" maxlength="250" v-model="destination.cc_addresses[index]" :disabled="index === 0" @input="destination.cc_addresses[index] = destination.cc_addresses[index].replace(/[\s!=()&quot;<>,;:\\]/g, '')">
              </li>
            </ul>
          </div>
        </div><br>
        <!-- 登録物件 -->
        <div class="box">
          <h3>登録物件</h3>
          <!-- 物件を一覧から選択モーダルを表示 -->
          <button class="" @click="property_add_modal()">物件を追加する</button>
          <div v-if="property_add_flg" class="modal" @click="outside_click">
            <div class="modal_content" @click.stop>
              <div class="header">
                <button class="close_button" @click="property_add_modal()">✕</button>
                <h2>物件を追加する</h2>
                <!-- フリーワード検索 -->
                <div class="input_search">
                  検索キーワード：<input type="text" class="input_text" maxlength="30" placeholder="物件名で検索" v-model="search_keyword">
                </div>
              </div>
              <!-- 物件リスト -->
              <ul class="property_modal_list">
                <li class="property_item" v-for="property in filtered_properties" :key="property" @click="add_property(property)">
                  <a class="property_name" style="width:50%">{{ property.info.name }}</a>
                  <a class="property_manager" style="width:50%">{{ property.info.owner }}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <ul class="property_list">
          <li class="property_item" v-for="(property, index) in destination.destination_properties" :key="index">
            <p style="width:50%">{{ `登録物件${index + 1}:${property.name}` }}</p>
            <label>
              <input class="property_name large-checkbox" type="checkbox" v-model="property.monthly_flg">月次報告書
            </label>
            <label>
              <input class="property_name large-checkbox" type="checkbox" v-model="property.patrol_flg">巡回報告書            
            </label>
            <!-- 削除ボタン -->
            <img src="@/assets/trash.png" alt="" width="25" height="25" @click="property_delete(property)">
          </li>
        </ul>
      </div>
      <button class="orange_btn modal_orange_btn" @click="edit_destination()">確定する</button>
    </div>
  </div>
</template>
  
<script>
import { Mixin } from "../mixin";
import pc_head from "./pc_header.vue"

export default {
  components: {
    pc_head
  },
  mixins: [Mixin],
  data() {
    return {
      destination: {},
      property_add_flg: false,
      properties: [],
      search_keyword: "",
    }
  },
  methods: {
    onInputDestinationName(event){
      // 入力された文字列に特定の文字が含まれている場合にはじく
      if (/[\\/:*?"<>|]/.test(event.target.value)) {
          this.destination.name = event.target.value.replace(/[\\/:*?"<>|]/g, "");
      }
    },
    property_delete(property){
      // destination.destination_propertiesから削除
      for (var i = 0; i < this.destination.destination_properties.length; i++) {
        if (this.destination.destination_properties[i].property_id == property.property_id) {
          this.destination.destination_properties.splice(i, 1);
        }
      }
    },
    property_add_modal() {
      this.property_add_flg = !this.property_add_flg;
    },
    outside_click(event) {
      if (!event.target.closest('.modal_content')) {
        this.property_add_modal();
      }
    },
    add_property(property) {
      // 既に登録されている物件は追加できない
      for (var i = 0; i < this.destination.destination_properties.length; i++) {
        if (this.destination.destination_properties[i].property_id == property.property_id) {
          alert("既に登録されている物件です。");
          return;
        }
      }
      alert(`「${property.info.name}」を追加しました。ただし、確定するボタンを押さないと反映されません。`);
      // destination.destination_propertiesに追加
      this.destination.destination_properties.push({
        property_id: property.property_id,
        name: property.info.name,
        monthly_flg: false,
        patrol_flg: false
      });
    },
    async edit_destination() {
      // this.destination.nameがデータ入力されていない場合はエラー
      if (this.destination.name == "") {
        alert("送信先名称を入力してください。");
        return;
      }
      // this.destination.to_addressesの配列に一つもデータ入力されていない場合はエラー
      if (this.destination.to_addresses[0] == "" && this.destination.to_addresses[1] == "" && this.destination.to_addresses[2] == "" && this.destination.to_addresses[3] == "" && this.destination.to_addresses[4] == "") {
        alert("宛先を入力してください。");
        return;
      }
      // this.destination.to_addressesの配列にEmail形式でないものがある場合はエラー
      for (var i = 0; i < this.destination.to_addresses.length; i++) {
        if (this.destination.to_addresses[i] !== "" && !this.destination.to_addresses[i].match(/.+@.+\..+/g)) {
          alert("宛先にEmail形式でないものがあります。");
          return;
        }
      }
      // this.destination.cc_addressesの配列にEmail形式でないものがある場合はエラー
      for (var i = 0; i < this.destination.cc_addresses.length; i++) {
        if (this.destination.cc_addresses[i] !== "" && !this.destination.cc_addresses[i].match(/.+@.+\..+/g)) {
          alert("CCにEmail形式でないものがあります。");
          return;
        }
      }
      // this.destination.destination_properties.monthly_flgとpatrol_flgがどちらもfalseの場合はエラー
      for (var i = 0; i < this.destination.destination_properties.length; i++) {
        if (!this.destination.destination_properties[i].monthly_flg && !this.destination.destination_properties[i].patrol_flg) {
          alert("登録物件にチェックがついていないものがあります。");
          return;
        }
      }
      // this.destination.destination_properties[i].nameを削除
      for (var i = 0; i < this.destination.destination_properties.length; i++) {
        delete this.destination.destination_properties[i].name;
      }
      // this.destination.to_addresses[i]の空文字をfillterで削除
      this.destination.to_addresses = this.destination.to_addresses.filter(function (value) {
        return value !== "";
      });
      // this.destination.cc_addresses[i]の空文字をfillterで削除
      this.destination.cc_addresses = this.destination.cc_addresses.filter(function (value) {
        return value !== "";
      });
      // 編集データの送信   
      const param = {
        method: "PUT",
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.$store.state.login_token,
        },
        body: JSON.stringify({ destination: this.destination }),
      };
      // 送信先情報更新
      const res = await fetch(process.env.VUE_APP_API_BASE_URL + "/destination/update/" + this.$route.query.destination_id, param)
      if (res.status == 200) {
        var data = await res.json()
        // console.log(data)
        // 送信先詳細に戻る
        this.$router.push({ path: '/pc/destination', query: { destination_id: this.$route.query.destination_id } })
      } else if (res.status == 401) {
        this.unauth()
      } else {
        this.other_error(res.status)
      }
    },
  },
  created: async function () {
    const param = {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.$store.state.login_token
      },
    };
    // 送信先情報取得
    const res = await fetch(process.env.VUE_APP_API_BASE_URL + "/destination/" + this.$route.query.destination_id, param)
    if (res.status == 200) {
      var data = await res.json()
      // console.log(data)
      this.destination = data.destination
      // data.destination.to_addresses.lengthを必ず5にする。足りない部分は空文字を入れる
      for(;this.destination.to_addresses.length < 5;){
        this.destination.to_addresses.push("");
      }
      for(;this.destination.cc_addresses.length < 5;){
        this.destination.cc_addresses.push("");
      }
    } else if (res.status == 401) {
      this.unauth()
    } else {
      this.other_error(res.status)
    }

    // 物件情報取得
    const res_property = await fetch(process.env.VUE_APP_API_BASE_URL + "/property/list", param)
    if (res_property.status == 200) {
      var data = await res_property.json();
      // console.log(data);
      this.properties = [];
      for (var i = 0; i < data.property.length; i++) {
        // 削除されていないかつ、テンプレートでない場合表示させる
        if (!data.property[i].delete_flg && data.property[i].property_id !== '0000') {
          this.properties.push(data.property[i]);
        }
      }
      // 物件名をdestination.destination_properties[i].nameに格納
      for (var i = 0; i < data.property.length; i++) {
        for (var j = 0; j < this.destination.destination_properties.length; j++) {
          if (data.property[i].property_id == this.destination.destination_properties[j].property_id) {
            this.destination.destination_properties[j].name = data.property[i].info.name;
          }
        }
      }
    } else if (res_property.status == 401) {
      this.unauth()
    } else {
      this.other_error(res_property.status)
    }
  },
  computed: {
    filtered_properties() {
      var filtered_properties = [];
      // キーワード検索＋契約状態によるフィルター
      for (let i in this.properties) {
        if (this.properties[i].info.name.indexOf(this.search_keyword) !== -1) {
          filtered_properties.push(this.properties[i])
        }
      }
      return filtered_properties;
    }
  }

}
</script>
  
<style scoped>
li{
  list-style: none;
}
.close_button {
  position: absolute;
  top: 0;
  right: 0;
  height: 50px;
  width: 50px;
  padding: 10px 10px;
  background-color: #a5a5a5;
  border: 1px solid #ddd;
  border-radius: 5px 5px 0 0;
  margin: 0 0 0 0;
  font-size: 40px;
  font-weight: normal;
}

.modal_content {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 80%;
  height: 80%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 10px;
  padding: 10px;
  z-index: 2;
  overflow-y: unset;
  max-height: 515px;
}

.property_list {
  padding: 0px;
}

.header {
  position: fixed;
  top: 3;
  left: 0;
  width: 97%;
  border-radius: 5px;
  height: 100px;
  background-color: #fff;
  padding: 10px;
  z-index: 9999;
}
.property_modal_list{
  padding: 0px;
  overflow-y: scroll;
  max-height: 400px;
  margin-top: 120px;
  width: 100%;
}

.property_item {
  display: flex;
  justify-content: space-around;
  align-items: center;
  border: 2px gray solid;
  border-radius: 5px;
  width: 99%;
  list-style: none;
  margin-top: 5px;
}

.flex {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 10px;
}

.half-width {
  width: 75%;
}

.box {
  position: relative;
}

.box button {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 10px 10px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 5px 5px 0 0;
  margin: 0 0 0 0;
  font-size: 16px;
  font-weight: normal;
}

.box button:active {
  background-color: #f2f2f2;
}</style>