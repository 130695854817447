<template>
    <div>
        <table id="photo_items" style="border-collapse:collapse; width:100%; text-align: center;">
            <!-- タイトル -->
            <tr>
                <th colspan="2" style="text-align: center;" class="fontsize">共有事項</th>
            </tr>
            <!-- 零枚目 -->
            <tr>
                <td colspan="2" v-if="photo_null_0">今回の共有事項の写真はありませんでした。</td>
            </tr>
            <!-- 一枚目、二枚目 -->
            <tr id="share_items">
                <td class="share_item" v-for="(img,index) of institutions_12" :key="img">
                    <img width="250" :src=img>
                    <p style="text-align: center">写真{{ index + 1 }}</p>
                </td>
                <td v-if="photo_null_12"></td>
            </tr>
            <!-- 三枚目、四枚目 -->
            <tr id="share_items">
                <td class="share_item" v-for="(img,index) of institutions_34" :key="img">
                    <img width="250" :src=img>
                    <p style="text-align: center">写真{{ index + 3 }}</p>
                </td>
                <td v-if="photo_null_34"></td>
            </tr>
            <!-- 五枚目、六枚目 -->
            <tr id="share_items">
                <td class="share_item" v-for="(img,index) of institutions_56" :key="img">
                    <img width="250" :src=img>
                    <p style="text-align: center">写真{{ index + 5 }}</p>
                </td>
                <td v-if="photo_null_56"></td>
            </tr>
            <!-- 七枚目、八枚目 -->
            <tr id="share_items">
                <td class="share_item" v-for="(img,index) of institutions_78" :key="img">
                    <img width="250" :src=img>
                    <p style="text-align: center">写真{{ index + 7 }}</p>
                </td>
                <td v-if="photo_null_78"></td>
            </tr>
        </table>
    </div>
</template>

<script scoped>
    
export default ({
    props: {
        share:{
        },
        photo_num:{
            type: Number
        },
    },
    data() {
        return {
            institutions_12:{},
            institutions_34:{},
            institutions_56:{},
            institutions_78:{},
            photo_null_0: false,
            photo_null_12: false,
            photo_null_34: false,
            photo_null_56: false,
            photo_null_78: false,
        }
    },
    beforeUpdate() {
        // console.log(this.share)
        // console.log(this.share.photo.length)

        if(this.share.length != 0){
            // 値渡し(一枚目、二枚目)
            this.institutions_12 = JSON.parse(JSON.stringify(this.share));
            this.institutions_34 = JSON.parse(JSON.stringify(this.share));
            this.institutions_56 = JSON.parse(JSON.stringify(this.share));
            this.institutions_78 = JSON.parse(JSON.stringify(this.share));

            // 各項目の処理
            // 一枚目、二枚目以外の削除
            this.institutions_12.splice(2, Number.MAX_VALUE);
            if(this.institutions_12.length == 1 ){
                this.photo_null_12 = !this.photo_null_12
            }

            // 三枚目、四枚目以外の削除
            this.institutions_34.splice(4, Number.MAX_VALUE);
            this.institutions_34.splice(0, 2);
            if(this.institutions_34.length == 1 ){
                this.photo_null_34 = !this.photo_null_34
            }

            // 五枚目、六枚目以外の削除
            this.institutions_56.splice(6, Number.MAX_VALUE);
            this.institutions_56.splice(0, 4);
            if(this.institutions_56.length == 1 ){
                this.photo_null_56 = !this.photo_null_56
            }

            // 七枚目、八枚目以外の削除
            this.institutions_78.splice(0, 6);
            if(this.institutions_78.length == 1 ){
                this.photo_null_78 = !this.photo_null_78
            }
        }

    },
})
</script>

<style scoped>
h2 {
    margin: 0;
    padding: 10px 0;
    width: 100%;
    color: #1a3f66ff;
}

ul{
    padding: 0;
}

li{
    list-style: none;
}

img{
    width: 100%;
}

.title {
    display: flex;
    background-color: #1a3f66ff;
    position: relative;
    height: 56px;
}

.font_14{
    font-size: 14px;
}

#share_items{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
}

.share_item{
    width: 45%;
    margin:0.5%;
    border: solid 5px rgba(26, 63, 102, 0.823);
    border-radius: 10px;
}
  
</style>