<template>
  <div class="html">
    <phone_head title="物件一覧" :header_back = false></phone_head>
    <div class="flex_filter_button">
      <p>{{ num_of_report }}件</p>
      <a @click="filter_open()" class="filter_open" v-show="!iconDisplay">絞り込み<img src="@\assets\under.png" alt=""
          class="arrow_icon"></a>
      <a @click="filter_close()" class="filter_open" v-show="iconDisplay">絞り込み<img src="@\assets\top.png" alt=""
          class="arrow_icon"></a>
    </div>
    <!-- フィルター -->
    <div class="wrapper">
      <div class="search_filter" v-show="iconDisplay">
        <div>
          <div class="keyword_search">
            <img class="search_icon" src="@/assets/検索アイコン.png" alt="">
            <input type="text" class="txt" v-model="keyword" placeholder="物件名" maxlength="50">
          </div>
          <div>
            <div class="grid">
              <div class="filter_item flex">
                <p class="item_p">担当エリア</p>
                <select name="" id="" class="item_select" v-model="selected_status1" v-bind="conditionSearchViewStatus">
                  <option value="" hidden>担当エリアを選択</option>
                  <option v-for="filter1 in selected1_filter" :key="filter1">{{ filter1 }}</option>
                </select>
              </div>
              <div class="filter_item flex">
                <p class="item_p">担当者</p>
                <select name="" id="" class="item_select" v-model="selected_status2" v-bind="conditionSearchViewStatus">
                  <option value="" hidden>担当者を選択</option>
                  <option v-for="filter2 in selected2_filter" :key="filter2">{{ filter2 }}</option>
                </select>
              </div>
              <div class="filter_item flex">
                <p class="item_p">都道府県</p>
                <select name="" id="" class="item_select" v-model="selected_status3" v-bind="conditionSearchViewStatus">
                  <option value="" hidden>都道府県を選択</option>
                  <option v-for="filter3 in selected3_filter" :key="filter3">{{ filter3 }}</option>
                </select>
              </div>
              <div class="filter_item flex">
                <p class="item_p">市区町村</p>
                <select name="" id="" class="item_select" v-model="selected_status4" v-bind="conditionSearchViewStatus">
                  <option value="" hidden>市区町村を選択</option>
                  <option v-for="filter4 in selected4_filter" :key="filter4">{{ filter4 }}</option>
                </select>
              </div>
            </div>
            <div class="search_btn_flex">
              <button id="property_search_reset" @click="property_search_reset()"
                class="search_reset_btn">検索条件をクリア</button>
              <button id="property_search" @click="property_search()" class="search_button"
                v-bind="searchButtonViewStatus">検索する</button>
            </div>
          </div>
        </div>
      </div>
      <!-- 物件一覧 -->
      <div>
        <ul>
          <li class="property_list" v-for="property_state in property_list" :key="property_state" @click=" move_description_screen(property_state) ">
            <a class=""><p class="property_name margin-left"><img class="home_icon" src="@\assets\home.png" alt="">{{ property_state.info.name }}</p></a><br>
            <a class="margin-left">担当者<span style="color:white;">あ</span>：{{ property_state.info.person }}</a><br>
            <a class="margin-left">最寄り駅：{{ property_state.info.station }}</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { Mixin } from "../mixin"
import phone_head from "./phone_header.vue"   // ヘッダー

export default ({
  components: {
    phone_head,
  },
  mixins: [Mixin],
  data() {
    return {
      iconDisplay: false,
      num_of_report: '',
      keyword: '',
      master_property_list: [],
      property_list: [],
      property_name: [],
      selected1_filter: [],
      selected2_filter: [],
      selected3_filter: [],
      selected4_filter: [],
      selected_status1: "",
      selected_status2: "",
      selected_status3: "",
      selected_status4: ""
    }
  },
  methods: {
    move_description_screen(property_state) {
      var property_name = property_state.info.name;
      this.$store.commit('property_name', property_name);
      // 物件TOP画面に遷移
      this.$router.push({ path: '/property/top/', query: { property_id: property_state.property_id } })
    },
    filter_open() {
      // 検索ボタン、フィルタ機能を表示
      this.iconDisplay = true;
    },
    filter_close() {
      // 検索ボタン、フィルタ機能を非表示
      this.iconDisplay = false;
    },
    property_search_reset() {
      // 検索欄リセット
      this.property_list = [];
      for (let j = 0; j < this.master_property_list.length; j++) {
        this.property_list.push(this.master_property_list[j])
      }
      this.keyword = "";
      this.selected_status1 = "";
      this.selected_status2 = "";
      this.selected_status3 = "";
      this.selected_status4 = "";
    },
    property_search() {
      // 検索
      this.property_list = [];
      if (!this.keyword && !this.selected_status1 && !this.selected_status2 && !this.selected_status3 && !this.selected_status4) {
        // 全ての入力値が空だった場合
        console.log("検索条件が全て空")

      } else if (!this.keyword) {

        console.log('条件検索');
        // プルダウンメニューフィルタで検索(リストを初期化)
        this.property_list = []

        // 完全合致で検索
        for (let i = 0; i < this.master_property_list.length; i++) {
          var flg1 = true;
          var flg2 = true;
          var flg3 = true;
          var flg4 = true;
          if (this.selected_status1 != ""
            && this.master_property_list[i].info.section != ""
            && this.master_property_list[i].info.section == this.selected_status1) {
            flg1 = true
          } else if (this.selected_status1 != ""
            && this.master_property_list[i].info.section != "" &&
            this.master_property_list[i].info.section != this.selected_status1) {
            flg1 = false
          } else if (this.selected_status1 != "" && this.master_property_list[i].info.section == "") {
            flg1 = false
          }

          if (this.selected_status2 != ""
            && this.master_property_list[i].info.person != ""
            && this.master_property_list[i].info.person == this.selected_status2) {
            flg2 = true
          } else if (this.selected_status2 != ""
            && this.master_property_list[i].info.person != ""
            && this.master_property_list[i].info.person != this.selected_status2) {
            flg2 = false
          } else if (this.selected_status2 != "" && this.master_property_list[i].info.person == "") {
            flg2 = false
          }

          if (this.selected_status3 != ""
            && this.master_property_list[i].info.prefecture != ""
            && this.master_property_list[i].info.prefecture == this.selected_status3) {
            flg3 = true
          } else if (this.selected_status3 != ""
            && this.master_property_list[i].info.prefecture != ""
            && this.master_property_list[i].info.prefecture != this.selected_status3) {
            flg3 = false
          } else if (this.selected_status3 != "" && this.master_property_list[i].info.prefecture == "") {
            flg3 = false
          }

          if (this.selected_status4 != ""
            && this.master_property_list[i].info.municipality != ""
            && this.master_property_list[i].info.municipality == this.selected_status4) {
            flg4 = true
          } else if (this.selected_status4 != ""
            && this.master_property_list[i].info.municipality != ""
            && this.master_property_list[i].info.municipality != this.selected_status4) {
            flg4 = false
          } else if (this.selected_status4 != "" && this.master_property_list[i].info.municipality == "") {
            flg4 = false
          }

          // And検索
          if (flg1 && flg2 && flg3 && flg4) {

            // 条件合致のものだけを追加
            this.property_list.push(this.master_property_list[i])

          }
        }

        // 総数カウント修正
        this.num_of_report = this.property_list.length
      } else {
        // キーワード検索
        console.log('キーワード検索');
        this.property_list = [];
        for (let i = 0; i < this.master_property_list.length; i++) {
          var property = this.master_property_list[i];
          // この下の条件にnull or false or num が一個でも入っていたら検索できなくなる。文字列はOK.
          if (property.info.name.indexOf(this.keyword) !== -1) {
            this.property_list.push(property);
          }
        }
      }
    },
  },
  created: async function () {

    // 画面表示時に物件一覧を取得
    const param = {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.$store.state.login_token
      }
    };
    const res = await fetch(process.env.VUE_APP_API_BASE_URL + "/property/list", param)
    if (res.status == 200) {
      var data = await res.json()
      // レスポンス情報から物件情報を取り出す
      for (var i = 0; i < data.property.length; i++) {
        // 論理削除されていないものを表示
        if (!data.property[i].delete_flg && data.property[i].property_id != '0000') {
          // 表示・マスターリストに物件を格納
          this.property_list.push(data.property[i]);
          this.master_property_list.push(data.property[i]);
          // 条件フィルターに値を格納(担当エリア)
          if (this.selected1_filter.indexOf(data.property[i].info.section) == -1 && data.property[i].info.section != "") {
            this.selected1_filter.push(data.property[i].info.section);
          }
          // 条件フィルターに値を格納(担当者)
          if (this.selected2_filter.indexOf(data.property[i].info.person) == -1 && data.property[i].info.person != "") {
            this.selected2_filter.push(data.property[i].info.person);
          }

          // 条件フィルターに値を格納(県名)
          if (this.selected3_filter.indexOf(data.property[i].info.prefecture) == -1 && data.property[i].info.prefecture != "") {
            this.selected3_filter.push(data.property[i].info.prefecture);
          }

          // 条件フィルターに値を格納(市区町村)
          if (this.selected4_filter.indexOf(data.property[i].info.municipality) == -1 && data.property[i].info.municipality != "") {
            this.selected4_filter.push(data.property[i].info.municipality);
          }
        }
      }
      // 物件総数(初期は総数)
      this.num_of_report = this.property_list.length;
    } else if (res.status == 401) {
      this.unauth()
    } else {
      this.other_error(res.status)
    }
  },
  computed: {
    searchButtonViewStatus() {

      // 検索BOX or 条件検索に値があるか？
      if (!this.keyword && !this.selected_status1 && !this.selected_status2 && !this.selected_status3 && !this.selected_status4) {
        return { disabled: true }
      } else {
        return { disabled: false }
      }
    },
    conditionSearchViewStatus() {

      // 検索BOXに値があるか？
      if (this.keyword.length > 0) {
        return { disabled: true }
      } else {
        return { disabled: false }
      }
    }
  }
})
</script>

<style scoped>
.html {
  background-color: #fff;
}

p {
  margin: 0;
  font-size: 18px;
}

h1 {
  margin: 0;
}

h2 {
  margin: 0;
  padding: 10px 0;
  color: black;
  border-bottom: 1px black solid;
}

.wrapper {
  width: 95vw;
  height: 100vh;
}

.filter_close {
  position: relative;
  right: -80px;
}

.top_icon {
  position: absolute;
  width: 25px;
  top: -4px;
  right: -30px;
}

/* 絞り込みボタン */
.filter_open {
  height: 25px;
  position: relative;
  text-align: center;
  align-content: center;
  top: 0;
  margin: 15.5px 0;
  padding: 0 0 0 10px;
  border-left: 1px solid gray;
}

.arrow_icon {
  position: absolute;
  width: 25px;
  padding: 0;
  top: 0;
  margin-left: 5px;
}

.flex_filter_button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 40px 0 30px;
  background-color: #fcfcfc;
}

/*  */
.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 10px;
  margin-top: 10px;
}

.search_filter {
  margin-top: 15px;
}

.keyword_search {
  position: relative;
  margin-top: 10px;
}

.search_icon {
  width: 30px;
  position: absolute;
  left: 10px;
  top: 3px;
}

.txt {
  display: inline-block;
  width: 95%;
  padding: 0.5em;
  border: 1px solid #999;
  box-sizing: border-box;
  background: #f2f2f2;
  margin: 0 0 0.5em 0;
  text-indent: 25px;
  font-size: 16px;
}

.keyword {
  margin: 0.5em 0 0 0;
  width: 140px;
  margin-right: auto;
}

.item_select {
  width: 95%;
  margin: 5px;
  font-size: 16px;
  height: 20px;
}

.filter_item {
  border: 1px black solid;
  border-radius: 5px;
  width: 90%;
  margin: 0 auto;
}

.search_btn_flex {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 10px;
  margin: 10px 0;
}

.search_reset_btn {
  width: 46%;
  height: 35px;
  border-radius: 20px;
  border: 1px black solid;
}

.search_reset_btn:active {
  background-color: #cbc9c9;
}

.search_button {
  width: 46%;
  height: 35px;
  border: 1px black solid;
  border-radius: 20px;
  padding: 5px 0;
  background-color: #1a3f66ff;
  color: white;
}

.search_button:active {
  background-color: rgb(63, 63, 200);
}

.flex {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  gap: 1.5px;
}

.item_p {
  background-color: #EEEEEE;
  border-radius: 5px 5px 0 0;
  width: 99%;
  padding: 1px;
  color: black;
}

.property_list {
  border: 1px black solid;
  border-radius: 5px;
  width: 95vw;
  height: 100%;
  list-style: none;
  margin: 0 auto 5px auto;
}

.property_name {
  color: #1a3f66ff;
  font-size: 25px;
  font-weight: 500;
}

.home_icon {
  margin-right: 5px;
}

ul {
  padding: 0;
  text-align:left;
}

ul li {
  font-size: 18px;
  padding: 10px 0;
}

.while {
  color: #fff;
}

.margin-left{
  margin-left: 20px;
}
</style>