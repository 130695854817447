<template>
<div>
    <div class="title">
        <h2>{{institution.ja_name}}</h2>
        <button class="flg_btn" @click="flg_change('item_flg')">{{item_flg_text}}</button>
    </div>
    <div class="institution_info" v-if=item_flg>
        <!-- 前回情報取得 -->
        <div class="past_report" v-show="past_report_check">
            <button class="orange_btn" @click="past_flg_change()" v-if="past_data_check(past_institution)" v-show="isPast">前回の確認</button>
            <div v-if="past_flg_test ==true && item_flg ==true " class="modal">
                <div class="modal_content">
                    <h3 class="modal_title">前回報告書確認</h3>
                    <div class="modal_info">
                        <past_item :institution="past_institution"></past_item>
                        <button class="modal_close" @click="past_flg_change()">閉じる</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- 写真 -->
        <div class="photo_title">
            <h2>写真</h2>
            <button class="photo_flg_btn" @click="flg_change('photo_flg')">{{photo_flg_text}}</button>
        </div>
        <div v-if="photo_flg">
            <p class="font_14">※施設のチェック内容がこちらに記載されています<br>
                （写真上のチェックがついているものが報告書に添付されます）</p>
            <ul id="photo_items">
                <li class="photo_item" v-for="(item,index) in institution.items" :key="item">
                    <label :for='"photo_" + index'><input type="checkbox" :checked=item.select :id='"photo_" + index'
                            disabled="disabled">報告書に添付</label>
                    <img class="img" :src=item.photo>
                    <!-- <p v-if=item.select>写真{{photoNumView(index)}}</p> -->
                    <p>{{phrase_ja_name(item.phrase)}}</p>
                    <p>{{item.text}}</p>
                </li>
            </ul>
        </div>
        <!-- 社内メモ -->
        <div class="memo_title">
            <h2>社内メモ</h2>
            <button class="memo_flg_btn" @click="flg_change('memo_flg')">{{memo_flg_text}}</button>
        </div>
        <div v-if="memo_flg">
            <p class="font_14">※社内で共有したい内容はこちらに書いてください</p>
            <p>{{institution.memo}}</p>
        </div>
    </div>
</div>
</template>

<script scoped>
import past_item from "./pc_report_input_before_component.vue"

export default ({
    components: {
        past_item
    },
    props: {
        institution: {
        },
        past_institution: {
        },
        past_report_check: {
        },
    },
    data() {
        return {
            item_flg: false,
            item_flg_text: "＋",
            photo_flg: false,
            photo_flg_text: "＋",
            memo_flg: false,
            memo_flg_text: "＋",
            past_flg_test: false,
            isPast:true,
        }
    },
    methods: {
        past_flg_change() {
            this.past_flg_test = !this.past_flg_test
        },
        past_data_check(data) {
            if (data === undefined) {
                return false
            } else {
                if (data.items.length == 0) {
                    this.isPast = false;
                }
                return true
            }
        },
        flg_change(flg) {
            this[flg] = !this[flg]
            if (this[flg + '_text'] == "＋") {
                this[flg + '_text'] = "－";
            } else {
                this[flg + '_text'] = "＋";
            }
        },
        photoNumView(index) {
            var count = 0;
            for (let i = 0; i < index + 1; i++) {
                if (this.institution.items[i].select == true) {
                    count++;
                }
            }
            return count;
        },
        phrase_ja_name(phrase_num) {
            switch (phrase_num) {
                case "0":
                    return "異常なし"
                case "1":
                    return "掲示物の掲示"
                case "2":
                    return "私物放置のため短冊貼り付け"
                case "3":
                    return "違法駐輪のため短冊貼り付け"
                case "4":
                    return "粗大ごみ放置のため短冊貼り付け"
                case "5":
                    return "ごみ、汚れがあったため対応済み"
            }
        }
    },

})
</script>

<style scoped>
h2 {
    margin: 0;
    padding: 10px 0;
    width: 100%;
    color: #fff;
}

ul {
    padding: 0;
}

li {
    list-style: none;
}

img {
    width: 100%;
    height: 100%;
}

.title {
    display: flex;
    background-color: #1a3f66ff;
    position: relative;
    height: 56px;
}

.font_14 {
    font-size: 14px;
}

.orange_btn {
    margin: 20px;
}

.flg_btn {
    font-size: 40px;
    background-color: #1a3f66ff;
    border: none;
    color: #fff;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 0;
    height: 56px;
    width: 56px;
}

.photo_title {
    display: flex;
    background-color: rgba(26, 63, 102, 0.823);
    position: relative;
}

.photo_flg_btn {
    font-size: 40px;
    background-color: rgba(26, 63, 102, 0);
    border: none;
    color: #fff;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 0;
    height: 56px;
    width: 56px;
}

.memo_title {
    display: flex;
    background-color: rgba(26, 63, 102, 0.823);
    position: relative;
}

.memo_flg_btn {
    font-size: 40px;
    background-color: rgba(26, 63, 102, 0);
    border: none;
    color: #fff;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 0;
    height: 56px;
}

#photo_items {
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
}

.photo_item {
    width: 22%;
    height: 100%;
    margin: 0.5%;
    border: solid 5px rgba(26, 63, 102, 0.823);
    border-radius: 10px;
}

.modal_info{
    background-color: #fff;
}
</style>