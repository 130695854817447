<template>
  <router-view/>
</template>

<script>

export default {
  name: 'App',
  methods : {
      createTitleDesc : function(routeInstance){
        //titleを設定する処理
        if(routeInstance.meta.title){
            var setTitle = '【巡回アプリ】' + routeInstance.meta.title;
            document.title = setTitle;
        } else {
            document.title = '【巡回アプリ】'
        }
      }
  },
  mounted : function(){
      var routeInstance = this.$route;
      this.createTitleDesc(routeInstance);
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

body{
  margin: 0;
}

.wrapper {
    width: 70%;
    margin: 0 auto;
}

.top_pc_btn{
  border: none;
  border-radius: 30px;
  background: #eb6100;
  color: #fff;
  -webkit-box-shadow: 4px 4px 0 #000;
  box-shadow: 4px 4px 0 rgb(207, 207, 207);
}

.top_pc_btn:hover {
  -webkit-box-shadow: -4px -4px 0 #000;
  box-shadow: -4px -4px 0 rgb(207, 207, 207);
}

.wid_20{
  width: 20%;
}

.wid_33{
  width: 33%;
}

.wid_50{
  width: 50%;
}

.wid_60{
  width: 60%;
}

.wid_70{
  width: 70%;
}

.back{
  text-align: left;
}

.title_pc{
  font-size: 40px;
  margin: 0 0 50px 0;
  text-align: center;
}

.orange_btn {
  margin: 50px;
  color: #fff;
  background-color: #eb6100;
  border-radius: 10px;
  padding: 10px 0px;
  width: 20%;
  border: none;
}

.orange_btn:hover {
  color: #fff;
  background: #f56500;
}

.modal {
    z-index: 1;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-y: scroll;
}

.modal_content {
    z-index: 2;
    width: 50%;
    height: 50%;
    background: #fff;
    border-radius: 20px;
}

.modal_title {
    margin: 0;
    padding: 10px 0;
    background-color: #1a3f66ff;
    color: #fff;
    border-radius: 20px 20px 0px 0px;
}

.modal_close {
    border: none;
    background-color: rgba(226, 226, 226, 0.884);
    border-radius: 10px;
    width: 20%;
    padding: 10px 0px;
    margin-top: 50px;
}

.box_right {
    font-weight: normal;
    margin-right: 5px;
}

.box_right::after {
    background-color: rgb(203, 203, 203);
    border-radius: 0px 5px 5px 0px;
    content: "";
    display: block;
    height: 3px;
}

.box_left::after {
    background-color: rgb(203, 203, 203);
    border-radius: 5px 0px 0px 5px;
    content: "";
    display: block;
    height: 3px;
}

input:invalid {
  background-color: rgb(253, 204, 204);
}
input:invalid:focus {
  background-color: rgb(253, 204, 204);
}

.input_overview{
  margin:5px 0 0 25%; 
  text-align: left;
}
.pc_input_overview{
  margin:5px 0 0 30%; 
  text-align: left;
}
.white-space {
  white-space: pre-line;
}
</style>
