<template>
<div>
    <phone_head title="報告書一覧" :path="{path:'/property/top/',query: {property_id: this.$route.query.property_id}}"></phone_head>
    <p class="property_name"><img src="@\assets\home.png" alt="" class="property_name_icon">{{ property_name }}</p>

    <div class="flex_filter_button">
        <p>{{ num_of_report }}件</p>
        <a @click="filter_open()" class="filter_open" v-show="!iconDisplay">絞り込み<img src="@\assets\under.png" alt=""
                class="arrow_icon"></a>
        <a @click="filter_close()" class="filter_open" v-show="iconDisplay">絞り込み<img src="@\assets\top.png" alt=""
                class="arrow_icon"></a>
    </div>

    <div>
        <div class="search_filter" v-show="iconDisplay">
            <div class="flex_filter">
                <p>巡回日</p>
                <select name="" id="" v-model="selected_status2">
                    <option value="" hidden>巡回日を選択してください</option>
                    <option v-for="filter2 in selected2_filter" :key="filter2">{{ filter2 }}</option>
                </select>
            </div>

            <div class="filter_btn">
                <button @click="report_search_reset()" class="search_reset_btn">検索条件をクリア</button>
                <button @click="report_search()" class="search_button">検索</button>
            </div>
        </div>

        <div class="flex_report_and_input">
            <label class="flex_label">
                <input type="radio" name="radio" class="radio" value="報告書形式" checked @click="radio_note()"><img
                    class="icon" src="@\assets\note.png" alt="">
                <p class="label_p">報告書</p>
            </label>
            <label class="flex_label">
                <input type="radio" name="radio" class="radio" value="入力画面形式" @click="radio_pen()"><img class="icon"
                    src="@\assets\pen.png" alt="">
                <p class="label_p">入力画面</p>
            </label>
        </div>

        <div>
            <!-- 報告書形式 -->
            <ul class="property_list" v-show="radio">
                <li v-for="report_item in report_list" :key="report_item" class="flex"
                    @click="move_screen({path: '/report/report/',query:{ property_id : this.$route.query.property_id,report_id : report_item.report_id}})">
                    <img class="icon" src="@\assets\note.png" alt="" v-show="radio">
                    <a class="person">巡回日：{{ report_item.info.date }}</a>
                </li>
            </ul>
            <!-- 入力画面形式 -->
            <ul class="property_list" v-show="!radio">
                <li v-for="report_item in report_list" :key="report_item" class="flex"
                    @click="move_screen({path: '/report/input/past/',query:{ property_id : this.$route.query.property_id,report_id : report_item.report_id}})">
                    <img class="icon" src="@\assets\pen.png" alt="" v-show="!radio">
                    <a class="person">巡回日：{{ report_item.info.date }}</a>
                </li>
            </ul>
        </div>
    </div>
</div>
</template>
<script>
import { Mixin } from "../mixin";
import phone_head from "./phone_header.vue"
export default {
    components: {
        phone_head
    },
    mixins: [Mixin],
    data() {
        return {
            radio: true,
            iconDisplay: false,
            property_name: '',
            num_of_report: '',
            report_list: [],
            master_report_list: [],
            selected1_filter: [],
            selected2_filter: [],
            selected_status1: "",
            selected_status2: "",
        }
    },
    methods: {
        radio_pen() {
            this.radio = false;
        },
        radio_note() {
            this.radio = true;
        },
        filter_open() {
            this.iconDisplay = true;
        },
        filter_close() {
            this.iconDisplay = false;
        },
        report_search() {
            // 検索
            if (this.selected_status1 == "" && this.selected_status2 == "") {
                console.log("条件が選択されていません。")
            } else {
                console.log('条件検索ボタンが押されました。');
                // プルダウンメニューフィルタ
                this.report_list = [];
                for (let i = 0; i < this.master_report_list.length; i++) {
                    var flg1 = true;
                    var flg2 = true;
                    if (this.selected_status1 != ""
                        && this.master_report_list[i].info.practitioner != ""
                        && this.master_report_list[i].info.practitioner == this.selected_status1) {
                        flg1 = true;
                    } else if (this.selected_status1 != ""
                        && this.master_report_list[i].info.practitioner != "" &&
                        this.master_report_list[i].info.practitioner != this.selected_status1) {
                        flg1 = false;
                    } else if (this.selected_status1 != "" && this.master_report_list[i].info.practitioner == "") {
                        flg1 = false;
                    }

                    if (this.selected_status2 != ""
                        && this.master_report_list[i].info.date != ""
                        && this.master_report_list[i].info.date == this.selected_status2) {
                        flg2 = true;
                    } else if (this.selected_status2 != ""
                        && this.master_report_list[i].info.date != ""
                        && this.master_report_list[i].info.date != this.selected_status2) {
                        flg2 = false;
                    } else if (this.selected_status2 != "" && this.master_report_list[i].info.date == "") {
                        flg2 = false;
                    }
                    // And検索
                    if (flg1 && flg2) {
                        // 条件合致のものだけを追加
                        this.report_list.push(this.master_report_list[i]);
                    }
                }
                // 総数カウント修正
                this.num_of_report = this.report_list.length;
            }
        },
        report_search_reset() {
            this.report_list = [];
            for (let j = 0; j < this.master_report_list.length; j++) {
                this.report_list.push(this.master_report_list[j]);
            }
            this.selected_status1 = "";
            this.selected_status2 = "";
            this.num_of_report = this.report_list.length;
        }
    },
    created: async function () {
        // モッグサーバーの読み込み
        const param = {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + this.$store.state.login_token,
            },
            mode: 'cors'
        };
        const params = { property_id: this.$route.query.property_id };
        const query = new URLSearchParams(params);
        const res = await fetch(process.env.VUE_APP_API_BASE_URL + `/report/list?${query}`, param)
        if (res.status == 200) {
            var data = await res.json()
            // console.log(data);
            // 物件名
            this.property_name = this.$store.state.property_name;
            for (var i = 0; i < data.report.length; i++) {
                // テンプレート（report_id = "0000）、または、delete_flg = 1の場合はthis.reportsとthis.master_reportsに追加しない
                if (data.report[i].report_id == "0000" || data.report[i].delete_flg == true) {
                    continue;
                }

                this.report_list.push(data.report[i]);
                this.master_report_list.push(data.report[i]);

                // 条件フィルターに値を格納(担当者)
                if (this.selected1_filter.indexOf(data.report[i].info.practitioner) == -1 && data.report[i].info.practitioner != "") {
                    this.selected1_filter.push(data.report[i].info.practitioner);
                }
                // 条件フィルターに値を格納(巡回日)
                if (this.selected2_filter.indexOf(data.report[i].info.date) == -1 && data.report[i].info.date != "") {
                    this.selected2_filter.push(data.report[i].info.date);
                }
            }
            // 報告書総数
            this.num_of_report = this.master_report_list.length;
        } else if (res.status == 401) {
            this.unauth()
        } else {
            this.other_error(res.status)
        }
    },
}
</script>

<style scoped>
.property_name {
    margin: 0;
    height: 40px;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    background-color: #1a3f66ff;
    color: #fff;
    font: caption;
    font-size: 18px;
    line-height: 1em;
    gap: 10px;
}

/* 絞り込みボタン */
.filter_open {
    height: 25px;
    position: relative;
    text-align: center;
    align-content: center;
    top: 0;
    margin: 15.5px 0;
    padding: 0 0 0 10px;
    border-left: 1px solid gray;
}

.arrow_icon {
    position: absolute;
    width: 25px;
    padding: 0;
    top: 0;
    margin-left: 5px;
}

.flex_filter_button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 40px 0 30px;
    background-color: #fcfcfc;
}

/* フィルターCSS */
.search_filter {
    margin-top: 10px;
    padding-bottom: 10px;
}

.flex_filter {
    display: flex;
    margin: 0 auto;
    margin-bottom: 10px;
}

.flex_filter p {
    width: 22%;
}

.flex_filter select {
    width: 73%;
    border: 1px black solid;
    border-radius: 5px;
}

.search_reset_btn {
    width: 46%;
    height: 45px;
    border-radius: 25px;
    border: 1px black solid;
    margin-right: 5px;
}

.search_reset_btn:active {
    background-color: #cbc9c9;
}

.search_button {
    width: 46%;
    height: 45px;
    border: 1px black solid;
    border-radius: 25px;
    padding: 5px 0;
    background-color: #1a3f66ff;
    color: white;
}

.search_button:active {
    background-color: rgb(63, 63, 200);
}

/* 資料一覧CSS */
ul {
    padding: 0;
    margin: 0;
}

ul li {
    margin-bottom: 5px;
}

.flex {
    display: flex;
    border: 1px black solid;
    border-radius: 5px;
    padding: 10px;
    margin: 0 3px 5px 3px;
    justify-content: center;
}
.flex_report_and_input{
    display: flex;
    padding: 10px;
    justify-content: center;
    margin-right: 30px;
}

.flex_label {
    display: flex;
    width: 190px;
    justify-content: right;
}

.flex label {
    width: 55%;
}

.radio {
    margin: 0;
    height: 48px;
}

.label_p {
    text-align: left;
    margin: auto 0;
    height: 24px;
}

.flex p {
    width: 50%;
}

.person {
    text-indent: 2em;
    margin: auto 0;
}

.icon {
    width: 60px;
    height: 48px;
}
</style>