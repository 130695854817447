<template>
<div>
  <phone_head title="物件TOP" path="/property/list"></phone_head>

  <p class="property_name">
    <img src="@\assets\home.png" alt="" class="property_name_icon">
    {{ property_name }}
  </p>
  <br><br>
  <div>
    <a class="btn btn-custom01" @click="make_report()">
      <span class="btn-custom01-front">報告書作成 ></span>
      <i class="fas fa-angle-right fa-position-right"></i>
    </a><br><br><br>

    <a class="btn btn-custom01"
      @click="move_screen({path: '/report/list',query:{ property_id : this.$route.query.property_id}})">
      <span class="btn-custom01-front">過去報告書 ></span>
      <i class="fas fa-angle-right fa-position-right"></i>
    </a><br><br><br>

    <a class="btn btn-custom01"
      @click="move_screen({path: '/property', query:{ property_id : this.$route.query.property_id}})">
      <span class="btn-custom01-front"> 物 件 詳 細 ></span>
      <i class="fas fa-angle-right fa-position-right"></i>
    </a>

  </div>
</div>
</template>
<script>
import { Mixin } from "../mixin";
import phone_head from "./phone_header.vue"
import { db } from "../db";
import Dexie from 'dexie'

export default ({
  props: ['name'],
  components: {
    phone_head
  },
  mixins: [Mixin],
  data() {
    return {
      property_name: '',
      receive_info: {},
      info: {},
      institutions: {},
      others_photo: [],
      share: [],
      share_text: ""

    }
  },
  methods: {
    move_description_screen(property_state) {
      // 報告一覧画面に遷移
      this.$router.push({ path: '/report/list/', query: { property_id: property_state.property_id } });
    },
    async make_report() {
      const param = {
        method: "GET",
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.$store.state.login_token,
        }
      };
      // 報告書テンプレート取得
      const res = await fetch(process.env.VUE_APP_API_BASE_URL + "/report/template", param)
      if (res.status == 200) {
        var data = await res.json()
        // console.log(data)
      } else if (res.status == 401) {
        this.unauth()
      } else {
        this.other_error(res.status)
      }

      db.delete()
      db.open()
      try {
        // Put the report_info
        await db.report_db.put({
            institutions: Dexie.deepClone(data.report.institutions),
            others_photo: {
                photo:Dexie.deepClone(data.report.others_photo),
            },
            share: {photo:Dexie.deepClone(data.report.share.photo),
                    text:data.report.share.text,
            },
            internal_memo:data.report.internal_memo,
            date:data.report.info.date
        });
      } catch (error) {
          console.log(error)
      }
      this.move_screen({ path: '/report/input/', query: { property_id: this.$route.query.property_id } })
    },
  },
  created: async function () {
    this.property_name = this.$store.state.property_name;
  },
})
</script>
<style scoped>
/* header_css */
html {
  margin-top: 0;
}

.property_name {
  margin: 0;
  height: 40px;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  background-color: #1a3f66ff;
  color: #fff;
  font: caption;
  font-size: 18px;
  line-height: 1em;
  gap: 10px;
}

/* CSS */
*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

html {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 62.5%;
}

.btn,
a.btn,
button.btn {
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 1.5;
  position: relative;
  display: inline-block;
  padding: 1rem 4rem;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  text-align: center;
  vertical-align: middle;
  text-decoration: none;
  letter-spacing: 0.1em;
  color: #fff;
  border-radius: 0.5rem;
}

a.btn-custom01 {
  margin-bottom: 0.5rem;
  padding: 0;
  border-radius: 3rem;
}

.btn-custom01-front {
  position: relative;
  display: block;
  padding: 1.5rem 5rem 1.5rem 5rem;

  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  border-radius: inherit;
  background: #eb6100;
}
</style>