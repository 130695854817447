<template>
<div>
    <pc_head title="過去報告書詳細" path="/pc/report/list"></pc_head>
    <div class="wrapper pdf" ref="capture">
        <div class="title" style="text-align: center;">
            <h2 style="text-align: center">巡回報告書</h2>
            <table border="1" style="border-collapse:collapse; width:100%;">
                <tr>
                    <th style="width:25%; text-align: center;">巡回日</th>
                    <td style="width:75%; text-align: left; text-indent: 0.5em;">{{ info.date }}</td>
                </tr>
                <tr>
                    <th style="width:25%; text-align: center;">物件名(所在地)</th>
                    <td style="width:75%; text-align: left; text-indent: 0.5em;">{{ info.name }}({{ info.address }})</td>
                </tr>
                <tr>
                    <th style="width:25%; text-align: center;">実施者(担当者)</th>
                    <td style="width:75%; text-align: left; text-indent: 0.5em;">{{ info.practitioner }}({{ info.person }})</td>
                </tr>
            </table>
        </div>
        <div>
            <!-- <h2 style="text-align: center">【報告事項】</h2> -->
            <div v-for="institution in institutions" :key="institution">
                <!-- 項目 -->
                <past_item :institution="institution"></past_item>
            </div>

            <!-- <h2 style="text-align: center">【共有事項】</h2> -->
            <share_item :share="share_photo"></share_item>
            <p class="white-space" style="text-align: center;">{{ share_text }}</p>
            <p>今後ともマンションの美化向上のためご協力の程、何卒よろしくお願いいたします</p>
        </div>
        <p>上記について、今後入居者様から問い合わせ等が発生する可能性がございます。その際は弊社までご連絡いただけますと幸いです</p>
        <p style="text-align: right">以上</p>
    </div>
    <!-- ボタンリスト -->
    <div id="btn_list">
        <button class="orange_btn" @click="flg_change('approve_flg')">承認する</button>
        <div v-if="approve_flg" class="modal">
            <div class="modal_content">
                <h3 style="margin-top:50px;">この報告書を承認しますか？</h3>
                <div class="wrapper flex">
                    <p>保存するフォルダ：{{ folder_name }}</p>
                    <button @click="folder_select()">保存フォルダ選択</button>
                </div>
                <button class="orange_btn" @click="pdfmake('output_folder')">承認する</button>
                <button class="modal_close" @click="flg_change('approve_flg')">キャンセル</button>
            </div>
        </div>
        <button class="orange_btn" @click="flg_change('fix_flg')">修正依頼</button>
        <div v-if="fix_flg" class="modal">
            <div class="modal_content">
                <h3 style="margin-top:50px;">修正依頼ステータスに変更しますか？</h3>
                <button class="orange_btn" @click="status_change('fix_flg')">修正依頼する</button>
                <button class="modal_close" @click="flg_change('fix_flg')">キャンセル</button>
            </div>
        </div>
        <button class="orange_btn" @click="pdfmake()">PDFで出力</button>
        <button class="orange_btn" @click="edit_alert(), move_screen({ path: '/pc/report/input/update', query: { property_id: this.$route.query.property_id, report_id: this.$route.query.report_id, data_keep_flg: 'false' } })">修正する</button>
        <button class="orange_btn" @click="flg_change('delete_flg')">削除する</button>
        <div v-if="delete_flg" class="modal">
            <div class="modal_content">
                <h3 class="modal_title">報告書を削除する</h3>
                <h4 style="color:red;">下記の物件を本当に削除しますか？</h4>
                <p>物件名：{{ info.name }}</p>
                <p>日付：{{ info.date }}</p>
                <button class="orange_btn" @click="report_delete()">削除する</button>
                <button class="modal_close" @click="flg_change('delete_flg')">キャンセル</button>
            </div>
        </div>
        <button class="orange_btn" @click="this.$router.go(-1)" style="background-color:rgba(26, 63, 102) ;">戻る</button>
    </div>
    <loading_circle :show_modal="show_modal"></loading_circle>
</div>
</template>
<script scoped>
import { Mixin } from "../mixin"
import pc_head from "./pc_header.vue"
import past_item from "./pc_report_past_component.vue"
import share_item from "./pc_report_past_share_component.vue"
import pdfMake from '../../public/pdfmake/pdfmake';
import pdfFonts from '../../public/pdfmake/vfs_fonts';
import htmlToPdfmake from "html-to-pdfmake";
import loading_circle from "./loading_circle.vue"
import { get, set } from 'idb-keyval';

export default ({
    components: {
        pc_head,
        past_item,
        share_item,
        loading_circle
    },
    mixins: [Mixin],
    data() {
        return {
            info: {},
            institutions: {},
            photo_num: 0,
            share_photo: {},
            share_text: "",
            approve_flg: false,
            fix_flg: false,
            delete_flg: false,
            data: {},
            folder_name:"",
            show_modal:false,
            master_destinations: [],          // 送信先一覧情報master
        }
    },
    methods: {
        folder_select: async function () {
            alert(`Odriveの「${process.env.VUE_APP_ODRIVE_FOLDER_NAME}」選択してください。`)
            // Indexed Database から FileSystemDirectoryHandle オブジェクトを取得
            let dh = await get('dir');
            // ディレクトリ選択ダイアログを表示
            dh = await window.showDirectoryPicker();
            // Odriveの「patrol-project-reports」フォルダを選択していない場合,エラーを表示。
            if(dh.name !== process.env.VUE_APP_ODRIVE_FOLDER_NAME){
                return alert(`失敗しました。Odriveの「${process.env.VUE_APP_ODRIVE_FOLDER_NAME}」フォルダを選択してください。`)
            }
            // FileSystemDirectoryHandle オブジェクトを Indexed Database に保存
            await set('dir', dh);

            this.$store.commit('folder_name', dh.name);
            this.folder_name = this.$store.state.folder_name;
        },
        edit_alert() {
            if(this.$store.state.report_status === '承認済み'){
                alert("これは承認済です。\n編集する場合、PDF出力をしてファイルを置き換えてください。");
            } else {}
        },
        flg_change(flg) {
            this[flg] = !this[flg]
        },
        pdfmake: function (status) {
            this.show_modal = true;

            // DOMをHTMLで取得
            var html = document.getElementsByClassName('pdf')[0].cloneNode(true);

            // href や src に指定されたURLを絶対パスに変換
            var nodes = html.querySelectorAll('[href],[src]');
            for (var i = 0, n = nodes.length; i < n; i++) {
                if (nodes[i].href) { nodes[i].href = nodes[i].href; }
                if (nodes[i].src) { nodes[i].src = nodes[i].src; }

            }

            // ソースコードをテキストで取得
            var src = html.innerHTML.replace(/\n/g, '<br>');

            // 上記の src には DOCTYPE が含まれていないので別途用意
            var name = document.doctype.name;
            var publicId = document.doctype.publicId;
            var systemID = document.doctype.systemId;
            var doctype = '<!DOCTYPE ' + name
                + (publicId ? ' PUBLIC "' + publicId + '"' : '')
                + (systemID ? ' "' + systemID + '"' : '')
                + '>';
            // <html> タグを再構成
            var htmlTag = '<html';
            var attrs = html.attributes;
            for (var i = 0, n = attrs.length; i < n; i++) {
                var attr = attrs[i];
                htmlTag += ' ' + attr.nodeName + (attr.nodeValue ? '="' + attr.nodeValue + '"' : '');
            }
            htmlTag += '>';

            pdfMake.vfs = pdfFonts.pdfMake.vfs;
            pdfMake.fonts = {
                // 日本語＋Robotが使用可能なフォントを設定
                MeiryoWithMircosoftYahei: {
                    normal: 'MeiryoWithMircosoftYahei.ttf',
                    bold: 'MeiryoWithMircosoftYahei.ttf'
                },
                Roboto: {
                    normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
                    bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
                    italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
                    bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
                }
            };
            const defaultStyle = 'MeiryoWithMircosoftYahei';
            const ret = htmlToPdfmake(htmlTag + src + '</html>', {
                imagesByReference: true,
                tableAutoSize:true,
            })
            const docDefinition = {
                content: ret.content,
                images: ret.images,
                defaultStyle: { font: defaultStyle },
                pageSize: 'A4',
                // 改ページ
                // pageBreakBefore: function (currentNode) {
                //     return currentNode.style && currentNode.style.indexOf('pdf-pagebreak-before') > -1;
                // },
            };
            // 項目数の計算
            var table_length = 0;
            for(var j=0;j<docDefinition.content[1].stack.length;j++){
                if(docDefinition.content[1].stack[j].nodeName == 'DIV'){
                    table_length += 1;
                }
            }
            // ヘッダー部分を改ページの際に追加
            for(var i =0;i<table_length-1;i++)  {
                docDefinition.content[1].stack[i].stack[0].stack[0].table.headerRows = 1;
                docDefinition.content[1].stack[i].stack[0].stack[0].table.keepWithHeaderRows = true;
            }    
            // docDefinition.content[1].stack[1].stack[0].stack[0].table.dontBreakRows = true;
            // ヘッダー部分を改ページの際に追加（共有事項）
            var share_table_length = docDefinition.content[1].stack.length;
            docDefinition.content[1].stack[share_table_length-3].stack[0].table.headerRows = 1;
            docDefinition.content[1].stack[share_table_length-3].stack[0].table.keepWithHeaderRows = true;
            // PDF変換
            if(status === 'output_folder'){
                // フォルダに出力の場合の処理
                pdfMake.createPdf(docDefinition).getBuffer((buffer) => {
                    this.idb_keyval(buffer);// 権限チェック
                }); 
            } else {
                // PDF出力ボタンの処理
                pdfMake.createPdf(docDefinition).download("【巡回】" + this.info.name + this.info.date + ".pdf");
                this.show_modal = false;
            }
        },
        // フォルダ選択
        idb_keyval: async function (buffer) {
            try {
                // Indexed Database から FileSystemDirectoryHandle オブジェクトを取得
                let dh = await get('dir');
                if (dh) {
                    // すでにユーザーの許可が得られているかをチェック
                    let permission = await dh.queryPermission({ mode: 'readwrite' });
                    if (permission !== 'granted') {
                        // ユーザーの許可が得られていないなら、許可を得る（ダイアログを出す）
                        permission = await dh.requestPermission({ mode: 'readwrite' });
                        if (permission !== 'granted') {
                            this.flg_change('approve_flg');
                            this.show_modal = false;
                            throw new Error('ユーザーの許可が得られませんでした。');
                        }
                    }
                } else {
                    // ディレクトリ選択ダイアログを表示
                    alert(`Odriveの「${process.env.VUE_APP_ODRIVE_FOLDER_NAME}」選択してください。`)
                    dh = await window.showDirectoryPicker();
                }
                // Odriveの「process.env.VUE_APP_ODRIVE_FOLDER_NAME」フォルダを選択していない場合,エラーを表示。
                if(dh.name !== process.env.VUE_APP_ODRIVE_FOLDER_NAME){
                    this.show_modal = false;
                    return alert(`失敗しました。Odriveの「${process.env.VUE_APP_ODRIVE_FOLDER_NAME}」フォルダを選択してください。`)
                }
                // FileSystemDirectoryHandle オブジェクトを Indexed Database に保存
                await set('dir', dh);
                
                this.$store.commit('folder_name', dh.name);
                this.folder_name = this.$store.state.folder_name;

                // 1.ワンオーナー\owner\property\3.事務\2.報告書関係(RF→顧客)\2023年2月
                // 年月データの成形
                let year = this.info.date.slice(0, 4);
                let month = this.info.date.slice(5, 7).replace(/^0+/, '');
                let formattedDate = `${year}年${month}月`;
                let folder_hierarchy_name = "";
                let destination_name_list = "";
                let fileName = `【巡回】${this.info.name + this.info.date}.pdf`;// ファイル名

                // this.master_destinationsの要素の数だけループ。ループの中で、送信先のフォルダを作成する。
                if(this.master_destinations.length == 0){
                    this.show_modal = false;
                    return alert('どの送信先にも物件が登録されていません。送信先詳細画面から該当の物件を登録してください。')
                }
                // this.master_destinations[i].nameをダイアログで表示して、YesかNoを選択させる。
                for (let i = 0; i < this.master_destinations.length; i++) {
                    destination_name_list += `${this.master_destinations[i].name}\n`;
                }
                // ダイアログの表示
                const userChoice = window.confirm(`下記の送信先に${this.info.name}が登録されています。\n間違いがないか確認してください。\n\n${destination_name_list}`);
                if (userChoice) {
                    console.log('Yes');
                    for (let i = 0; i < this.master_destinations.length; i++) {
                        // フォルダ名の作成
                        folder_hierarchy_name += `${dh.name}/${this.master_destinations[i].name}/${this.info.name}/${formattedDate}/巡回報告書\n`;
                        // 送信先フォルダ
                        let destination_directory = await dh.getDirectoryHandle(this.master_destinations[i].name, { create: true });
                        // 物件名フォルダ
                        let property_directory = await destination_directory.getDirectoryHandle(this.info.name, { create: true });
                        // 「年月」フォルダの作成
                        let month_year_directory = await property_directory.getDirectoryHandle(formattedDate, { create: true });
                        // 「巡回報告書」フォルダの作成
                        let patrol_directory = await month_year_directory.getDirectoryHandle("巡回報告書", { create: true });
                        await month_year_directory.getDirectoryHandle("その他", { create: true });
                        // 選択したフォルダにファイルを作成
                        let fileHandle = await patrol_directory.getFileHandle(fileName, { create: true });
                        let writer = await fileHandle.createWritable();
                        // PDFmakeで出力したデータを入れる。
                        await writer.write(new Blob([buffer], { type: 'application/pdf' }));
                        await writer.close();
                    }
                    alert(`${fileName}のPDF出力に成功しました！\n\n【該当のフォルダ】\n${folder_hierarchy_name}`);
                    this.status_change('approve_flg');
                } else {
                    console.log('No');
                    this.show_modal = false;
                    return alert("キャンセルされました。");
                }
                
            } catch (e) {
                this.show_modal = false;
                // ファイル選択をキャンセルした時などにここに飛ぶ
                console.error(e)
                if(e.message.indexOf('A requested file or directory could not be found at the time an operation was processed.') !== -1){
                    alert('エラーが発生しました。フォルダを再選択してください。')
                }
            }
        },
        async status_change(status) {
            if (status == 'approve_flg') {
                console.log('承認')
                this.master_data.report.status = 2
            } else if(status == 'fix_flg') {
                console.log('修正')
                this.master_data.report.status = 3
            } else {}
            this.show_modal = true
            const param = {
                method: "PUT",
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + this.$store.state.login_token,
                },
                body: JSON.stringify({
                    report: {
                        institutions: this.master_data.report.institutions,
                        others_photo: this.master_data.report.others_photo,
                        share: {
                            photo: this.master_data.report.share.photo,
                            text: this.master_data.report.share.text,
                        },
                        internal_memo: this.master_data.report.internal_memo,
                        date: this.master_data.report.info.date,
                        status: this.master_data.report.status
                    }
                })
            };
            // 報告書情報更新
            const res = await fetch(process.env.VUE_APP_API_BASE_URL + "/report/update/" + this.$route.query.property_id + this.$route.query.report_id, param)
            if (res.status == 200) {
                this.$router.push('/pc/report/list')
            } else if (res.status == 401) {
                this.unauth()
            } else {
                this.other_error(res.status)
            }
        }
    },
    created: async function () {
        if(this.$store.state.folder_name){
            this.folder_name = this.$store.state.folder_name;
        } else {}
        const param = {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + this.$store.state.login_token,
            }
        };
        // 報告書情報取得
        const res = await fetch(process.env.VUE_APP_API_BASE_URL + "/report/" + this.$route.query.property_id + this.$route.query.report_id, param , {cache: "no-store"})
        if (res.status == 200) {
            this.master_data = await res.json()
            var json_data = JSON.stringify(this.master_data)
            var data = JSON.parse(json_data)
            this.info = data.report.info
            this.institutions = data.report.institutions
            this.others_photo = data.report.others_photo
            this.share_photo = data.report.share.photo
            this.share_text = data.report.share.text.replace( /\n/g , "\r\n")
            if(!this.master_data.report.hasOwnProperty('internal_memo')){
                this.master_data.report.internal_memo = ''
                console.log('追加しました')
            }
            for (let institution in this.institutions) {
                if (this.institutions[institution].items.length !== 0) {
                    // 1要素ごとの処理
                    for (let i = this.institutions[institution].items.length -1; i >= 0 ; i--) {
                        // 報告書に記載の項目抽出
                        if (!this.institutions[institution].items[i].select) {
                            this.institutions[institution].items.splice(i, 1)
                        }
                    }
                }
                // itemsが空の時は項目ごと削除する
                if (typeof this.institutions[institution].items[0] === 'undefined') {
                    delete this.institutions[institution]
                }
            }
        } else if (res.status == 401) {
            this.unauth()
        } else {
            this.other_error(res.status)
        }

        
        // 送信先一覧の情報取得
        const res2 = await fetch(process.env.VUE_APP_API_BASE_URL + "/destination/list", param)
        if (res2.status == 200) {
            // レスポンス情報の取得
            this.data = await res2.json()
            for (let i = 0; i < this.data.destination.length; i++) {
                for (let j = 0; j < this.data.destination[i].destination_properties.length; j++) {
                    if (this.data.destination[i].destination_properties[j].property_id == this.$route.query.property_id && this.data.destination[i].delete_flg == false && this.data.destination[i].destination_properties[j].patrol_flg == true) {
                        this.master_destinations.push(this.data.destination[i])
                    }
                }
            }
        } else if (res2.status == 401) {
            this.unauth()
        } else {
            this.other_error(res2.status)
        }
    },
})
</script>

<style scoped>
th {
    width: 20%;
}

td {
    width: 70%;
    text-align: left;
}

ul {
    padding: 0;
}

li {
    list-style: none;
}

img {
    width: 99%;
}


.text_left {
    text-align: left;
}

#share_items {
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    width: 100%;
}

.share_item {
    width: 48%;
    margin: 0.5%;
    border: solid 5px rgba(26, 63, 102);
    border-radius: 10px;
}

#btn_list {
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    width: 70%;
    text-align: center;
    margin: 0 auto;
}


.modal_close {
    margin: 10px 60px;
}

.orange_btn {
    margin: 10px 60px;
}
.flex {
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>