<template>
    <div class="wrapper">
        <pc_head title="報告一覧" path="/pc/top" :back_active="authority_2"></pc_head>
        <div id="filter_wrapper_title">
            <h2>検索フィルタ</h2>
            <button id="filter_flg_btn" @click="filter_flg_change">{{ filter_flg_text }}</button>
        </div>
        <div class="filter_wrapper" v-if="filter_flg">
            <div class="input_search">
                <p class="input_search_title">検索キーワード</p>
                <input type="text" class="input_text" placeholder="検索したいキーワードを入力してください" v-model="search_keyword" maxlength="50">
            </div>
            <div class="pulldown_search">
                <div class="flex_box">
                    <p class="filter_title">書類ステータス</p>
                    <div id='status_checkboxes' >
                        <label><input class="check_status" type="checkbox" v-model="selected_status" value="承認済み">承認済み</label>
                        <label><input class="check_status" type="checkbox" v-model="selected_status" value="承認待ち">承認待ち</label>
                        <label><input class="check_status" type="checkbox" v-model="selected_status" value="修正依頼">修正依頼</label>
                    </div>
                </div>
                <div class="flex_box">
                    <p class="filter_title">担当者</p>
                    <select id="" class="item_select" v-model="selected_person">
                        <option value="" hidden>担当者を選択</option>
                        <option v-for=" person in persons" :key="person">
                            {{ person }}
                        </option>
                    </select>
                </div>
                <div class="flex_box">
                    <p class="filter_title">オーナー</p>
                    <select id="" class="item_select" v-model="selected_owner">
                        <option value="" hidden>オーナーを選択</option>
                        <option v-for=" owner in owners" :key="owner">
                            {{ owner }}
                        </option>
                    </select>
                </div>
                <div class="flex_box">
                    <p class="filter_title">巡回日（巡回月）</p>
                    <ul id='date_items'>
                        <li id='date_item'>
                            <select v-model="selected_year">
                                <option value="" hidden>-</option>
                                <option v-for="n of option_years" :key="n">
                                    {{n}}
                                </option>
                            </select>
                            <a> 年</a>
                        </li>
                        <li id='date_item'>
                            <select v-model="selected_month" :disabled="!selected_year">
                                <option value="" hidden>-</option>
                                <option v-for="m of 12" :key="m">
                                    {{m}}
                                </option>
                            </select>
                            <a> 月</a>
                        </li>
                        <li id='date_item'>
                            <select v-model="selected_day" :disabled="!selected_month">
                                <option value="" hidden>-</option>
                                <option v-for="o of 31" :key="o">
                                    {{o}}
                                </option>
                            </select>
                            <a> 日</a>
                        </li>
                    </ul>        
                </div>
                <button id="search" @click="report_search()" class="search_button flex_box">検索(リセット)</button>
            </div>
        </div>
        <div class="format_select">
            <h3>形式選択ボタン<span>（見たい形式を選択してください）</span></h3>
            <div class="format_btn_list">
                <label for="past_btn"><input id="past_btn" type="radio" value="past" v-model="selected_format" name="format" checked>報告書形式</label>
                <label for="input_btn"><input id="input_btn" type="radio" value="input" v-model="selected_format" name="format">入力画面形式</label>
            </div>
            <p v-if="alert" style="color:red">報告書の形式を選択してください</p>
        </div>

        <div id='canceled_wrapper'>
            <select id="canceled_menu" v-model='selected_canceled_flg'>
                <option :value='true' >解約済み</option>
                <option :value='false'>契約中</option>
            </select>
        </div>
        <ul class="report_list">
            <li class="report" v-for="report in filtered_reports" :key="report">
                <p class="report_item" @click="move_description_screen(report)">
                    <a class="report_info">{{ report.info.name }}</a>
                    <a class="report_info">{{ report.info.person }}</a>
                    <a class="report_info">{{ report.info.date }}</a>
                    <a class="report_info">{{ report.status }}</a>
                </p>
            </li>
        </ul>
    </div>

</template>
<script scoped>
import { Mixin } from "../mixin";
import pc_head from "./pc_header.vue"

export default ({
    components: {
        pc_head
    },
    mixins: [Mixin],
    data() {
        return {
            master_reports: [],
            reports: [],
            search_keyword: "",
            persons: [],
            owners: [],
            selected_person: '',
            selected_owner: '',
            selected_status: [],
            selected_year:'',
            selected_month:'',
            selected_day:'',
            selected_canceled_flg: false,
            canceled_flg_list:{},
            filter_flg_text: "＋",
            filter_flg: false,
            selected_format: "past",
            alert: false,
            authority_2:false,
            option_years: [],
            property_list: []
        }
    },
    methods: {
        report_search() {
            // 検索結果のリセット
            this.reports = []
            for (let j = 0; j < this.master_reports.length; j++) {
                this.reports.push(this.master_reports[j])
            }
            //日付の作成
            var selected_date = '';
            // ゼロパディング
            var selected_month = ('00' + this.selected_month).slice(-2) ;
            var selected_day = ('00' + this.selected_day).slice(-2) ;

            // 日付が一つでも選択されている場合
            if(this.selected_year){
                // 日が選択されているか
                if(this.selected_day){
                    selected_date = this.selected_year + '-' + selected_month + '-' + selected_day ;
                } else if (this.selected_month){
                    selected_date = this.selected_year + '-' + selected_month ;            
                } else{
                    selected_date = this.selected_year
                }
            } else {}
            // 選択された条件の検索（入力文字以外）
            for (let i = this.reports.length - 1; i >= 0; i--) {
                if (this.reports[i].info.person.indexOf(this.selected_person) == -1 ||
                    this.reports[i].info.owner.indexOf(this.selected_owner) == -1 ||
                    this.reports[i].info.date.indexOf(selected_date) == -1) {
                    this.reports.splice(i, 1);
                } else {
                    this.status_search(i)
                }
            }
            this.selected_status = [];
            this.selected_person = "";
            this.selected_owner = "";
            this.selected_year = "";
            this.selected_month = "";
            this.selected_day = "";          
        },
        status_search(num) {
            let flg = false
            if (!this.selected_status.length) {
                flg = true
            }
            for (let k = this.selected_status.length - 1; k >= 0; k--) {
                if (this.reports[num].status.indexOf(this.selected_status[k]) !== -1) {
                    flg = true
                }
            }
            if (!flg) { this.reports.splice(num, 1) }
        },
        move_description_screen(report) {
            this.$store.commit('report_status', report.status);
            if (this.selected_format == "past") {
                this.$router.push({ path: '/pc/report/past', query: { property_id: report.property_id, report_id: report.report_id } })
            } else if (this.selected_format == "input") {
                this.$router.push({ path: '/pc/report/input/past', query: { property_id: report.property_id, report_id: report.report_id } })
                // console.log(report.report_id)
            } else {
                this.alert = true
            }
        },
        filter_flg_change() {
            this.filter_flg = !this.filter_flg
            if (this.filter_flg_text == "＋") {
                this.filter_flg_text = "－";
            } else {
                this.filter_flg_text = "＋";
            }
        },
    },
    created: async function () {
        const param = {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + this.$store.state.login_token
            },
        };

        // 報告書一覧情報取得(繰り返し処理)
        var report = []
        var offset_propertyId = ""
        var offset_reportId = ""
        var data = ""
        var status = 0
        var roop_flag = true
        while(roop_flag){
            if(report.length == 0){
                // 初回処理
                const res = await fetch(process.env.VUE_APP_API_BASE_URL + "/report/list?limit=30", param)
                data = await res.json()
                status = res.status

            }else{
                // 継続処理
                const url = new URL(process.env.VUE_APP_API_BASE_URL+"/report/list");
                url.searchParams.append('limit',30);
                url.searchParams.append('offset',"{\"property_id\": \"" + offset_propertyId + "\", \"report_id\": \"" + offset_reportId + "\"}");
                const res = await fetch(url, param)
                data = await res.json()
                status = res.status
            }

            if (status == 200) {

                // データ蓄積
                if(data.report.length==0){
                    // 0件取得の場合は終了
                    roop_flag = false
                }
                data.report.forEach(function(content){
                    report.push(content)
                });

                // オフセット取得
                if(data.lastEvaluatedKey !== undefined && data.lastEvaluatedKey !== null){
                    offset_propertyId = data.lastEvaluatedKey.property_id
                    offset_reportId = data.lastEvaluatedKey.report_id

                }else{
                    // lastEvaluatedKeyがないと終了
                    roop_flag = false
                }
            }else if (status == 401) {
                // 認証なし
                this.unauth()
                //　終了
                roop_flag = false
            } else {
                // その他エラー
                this.other_error(res.status)
                //　終了
                roop_flag = false
            }
        }
        
        // データ処理
        for (var i = 0; i < report.length; i++) {
            switch (report[i].status) {
                case 1:
                    report[i].status = "承認待ち"
                    break;
                case 2:
                    report[i].status = "承認済み"
                    break;
                case 3:
                    report[i].status = "修正依頼"
                    break;
            }
            // テンプレート（report_id = "0000）、または、delete_flg = 1の場合はthis.reportsとthis.master_reportsに追加しない
            if (report[i].report_id == "0000" || report[i].delete_flg == true) {
                continue;
            }
            this.reports.push(report[i]);
            this.master_reports.push(report[i]);

            //プルダウンフィルター項目の取得
            //担当者
            if (this.persons.indexOf(report[i].info.person) == -1) {
                this.persons.push(report[i].info.person);
            }
            //オーナー
            if (this.owners.indexOf(report[i].info.owner) == -1) {
                this.owners.push(report[i].info.owner);
            }
            //option_yearの取得
            const report_date = report[i].info.date.split('-') ;
            const report_year = report_date[0];
            if(this.option_years.indexOf(report_year) === -1 && report_year.length !== 0){
                this.option_years.push(report_year);
            }
        }

        // 並べ替え
        this.persons.sort();
        this.option_years.sort();

        // 権限が確認者だと戻るボタンを消して、システム管理に行けなくする。
        if(this.$store.state.authority_id == "0002"){
            this.authority_2 = true;
        }
        
        // 物件情報取得
        const res2 = await fetch(process.env.VUE_APP_API_BASE_URL + "/property/list", param)
        if (res2.status == 200) {
            // 物件情報の取得
            const property_data = await res2.json();
            const property_list = property_data.property;
            // 物件IDと契約状態のリストを作成
            for (let i = 0 ; i < property_list.length ; i++){
                this.canceled_flg_list[property_list[i].property_id] = property_list[i].info.canceled_flg ;
            }
            
        } else if (res2.status == 401) {
            this.unauth()
        } else {
            this.other_error(res2.status)
        }
    },
    computed: {
        // キーワード検索、契約状態フィルター機能
        filtered_reports() {
            var filtered_reports = [];
            for (let i in this.reports) {
                // キーワード検索＋契約状態によるフィルター
                if (this.reports[i].info.name.indexOf(this.search_keyword) !== -1 && this.selected_canceled_flg == this.canceled_flg_list[this.reports[i].property_id]) {
                    filtered_reports.push(this.reports[i])
                }
            }
            return filtered_reports;
        },
    }
})
</script>

<style scoped>
h2 {
    margin: 0;
    padding: 10px 0;
    width: 100%;
    color: #fff;
}

li {
    list-style: none;
}

a {
    text-decoration: none;
}

#filter_wrapper_title {
    display: flex;
    background-color: #1a3f66ff;
    position: relative;
}

#filter_flg_btn {
    font-size: 40px;
    background-color: #1a3f66ff;
    border: none;
    color: #fff;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
    height: 56px;
    width: 56px;
}

.filter_wrapper {
    margin: 0 auto;
    text-align: center;
    border: solid 2px gray;
}

.input_search {
    margin-bottom: 20px;
}

.input_search_title {
    font-size: 18px;
}

.input_text {
    width: 95%;
    padding: 0.5em;
    border: 1px solid #999;
    background: #f5f5f5;
}

#status_checkboxes{
    padding: 10px 0px;
}

.pulldown_search {
    width: 90%;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.pulldown_search::after {
    content: "";
    display: block;
    width: 30%;
}

#date_items{
    display: flex;
    justify-content: center;
    border: 1px solid rgb(118,118,118);
    text-align: center;
    margin: 0;
    padding: 10px 0px;
    border-radius: 0px 0px 5px 5px;
}

.item_select {
    text-align: center;
    font-size: 16px;
    width: 100%;
    padding: 10px 0px;
    border-radius: 0px 0px 5px 5px;
}

.flex_box {
    width: 30%;
    margin-bottom: 20px;
}

.search_button {
    color: #fff;
    border: 1px none;
    border-radius: 10px;
    background-color: #eb6100;
    font-size: 20px;
    padding: 15px 0;
}

.search_button:active {
    background-color: rgb(241, 127, 127);
}

.filter_title {
    background-color: #1a3f66ff;
    margin: 0;
    width: 100%;
    padding: 10px 0px;
    color: #fff;
    border-radius: 5px 5px 0px 0px;

}

#canceled_wrapper {
    text-align: right;
}

#canceled_menu {
    width: 20%;
    padding: 0.5%;
    background-color: #e4e4e4;
    color: black;
    text-align: center;
}

.report {
    list-style: none;
    display: flex;
}

.report_list {
    padding: 0px;
    margin-bottom: 100px;
}

.pdf_checkbox {
    transform: scale(2);
    margin: 9px 10px 0 0;
    height: 30px;
}

.report_item {
    display: flex;
    justify-content: space-around;
    align-items: center;
    border: 2px gray solid;
    border-radius: 5px;
    width: 100%;
    height: 30px;
    list-style: none;
    margin-top: 5px;
}

.report_item:hover {
    background-color: rgba(26, 63, 102, 0.100);
}

a.report_item {
    font-size: 24px;
}

.report_info {
    width: 25%;
}
</style>