<template>
  <div>
    <!-- ヘッダー -->
    <pc_head title="メール送信" :path="{path:'/pc/destination', query:{ destination_id: this.$route.query.destination_id }}"></pc_head>
    <!-- メールプレビュー画面 -->
    <div class="flex_box">
      <ul class="mail_title">
        <!-- 送信月 -->
        <li id='date_item'>
          <p style="width:30%">報告書年月：</p>
          <input style="width:70%" type="month" size="30" maxlength="30" v-model="mail_send_month" @input="mail_sentence_edit">
        </li>
        <!-- 赤文字で「報告書年月を選択してください」と表示 -->
        <li id='date_item'>
          <p style="width:30%" v-if="formatted_send_month === '----年--月'"></p>
          <p style="color:red;margin-top: 0;width:70%;text-align:left;" v-if="formatted_send_month === '----年--月'">報告書年月を選択してください</p>
        </li>
        <!-- 差出人Email -->
        <li id='date_item'>
          <p style="width:30%">差出人Email：</p>
          <input style="width:70%" type="text" size="30" maxlength="30" value="rfbm@rearx-find.co.jp;" disabled>
        </li>
        <!-- 宛先 -->
        <li id='date_item'>
          <p style="width:30%">宛先：</p>
          <input style="width:70%" type="text" size="30" maxlength="30" placeholder="宛先は登録されていません" v-model="mail_address" disabled>
        </li>
        <!-- CC -->
        <li id='date_item'>
          <p style="width:30%">CC：</p>
          <input style="width:70%" type="text" size="30" maxlength="30" placeholder="CCは登録されていません" v-model="mail_cc_address" disabled>
        </li>
        <!-- 宛先、CCの編集モーダル -->
        <button style="margin:5px 0;" @click="flg_change('destination_selected_flg')">宛先・CCを選択する</button>
        <div class="modal" v-if="destination_selected_flg">
          <div class="modal_content">
            <h2 class="modal_title">送信先を選択してください。</h2>
            <!-- 宛先 -->
            <ul id="" class="" style="width:45%;">
              <li v-for="(address, index) in destination.to_addresses" :key="index">
                <label>
                  <input type="checkbox" v-model="selected_to_addresses_bool[index]"> 宛先{{ index+1 }}：{{ address }}
                </label>
              </li>
            </ul>
            <!-- CC -->
            <ul id="" class="" style="width:45%;">
              <li v-for="(address, index) in destination.cc_addresses" :key="index">
                <label>
                  <input type="checkbox" v-model="selected_cc_addresses_bool[index]" :disabled="index === 0"> CC{{ index+1 }}：{{ address }}
                </label>
              </li>
            </ul>
            <button class="modal_btn orange_btn" @click="update_address()">確定する</button>
            <button class="modal_close" @click="update_address_cancel">キャンセル</button>
          </div>
        </div>
        <!-- 件名 -->
        <li id='date_item'>
          <p style="width:30%">件名：</p>
          <input style="width:70%" type="text" size="30" maxlength="30" placeholder="年月選択後、自動で入力されます" v-model="mail_subject" :disabled="!disabled_flg">
        </li>
      </ul>
    </div>
    <!-- メール本文,最大1000文字 -->
    <div class="flex_mail">
      <ul class="mail_title">
        <li id='date_item'>
          <p style="width:30%">メール本文：</p>
          <textarea style="width:70%;height:500px;" rows="10" cols="50" placeholder="年月選択後、自動で入力されます" v-model="mail_sentence" :disabled="!disabled_flg"></textarea>
        </li>
      </ul>
    </div>
    <!-- 送信ボタン -->
    <div class="flex_box">
      <button class="btn btn-primary orange_btn" style="width:30%;" @click="flg_change('submit_modal_flg')" v-if="disabled_flg">送信する</button>
      <div v-if="submit_modal_flg" class="modal">
        <div class="modal_content">
          <h2 class="modal_title">メールを送信</h2>
          <p class="modal_text" style="color:red;">本当に送信しますか？</p>
          <button class="modal_btn orange_btn" @click="submit_mail()">送信する</button>
          <button class="modal_close" @click="flg_change('submit_modal_flg')">キャンセル</button>
        </div>
      </div>
    </div>
    <loading_circle :show_modal="show_modal"></loading_circle>
  </div>
</template>

<script>
import { Mixin } from "../mixin";
import pc_head from "./pc_header.vue"
import loading_circle from "./loading_circle.vue"

export default {
  components: {
    pc_head,
    loading_circle
  },
  mixins: [Mixin],
  data() {
    return {
      destination: {},                    // 送信先情報
      submit_modal_flg: false,            // 送信ボタン押下フラグ
      destination_selected_flg: false,    // 宛先、CC選択ボタン押下フラグ
      master_to_addresses_bool: [],       // 宛先のboolean
      selected_to_addresses_bool: [],     // 選択された宛先のboolean
      master_cc_addresses_bool: [],       // CCのboolean
      selected_cc_addresses_bool: [],     // 選択されたCCのboolean
      disabled_flg: false,                // disabledフラグ
      formatted_send_month: '----年--月', // 送信月表示用
      mail_send_month: '',                // 送信月
      mail_address: '',                   // 宛先
      mail_cc_address: '',                // CC
      mail_subject: '',                   // 件名
      mail_sentence:'',                   // メール本文
      url: '',                            // URL
      url_limit_time: '',                 // URL期限
      user_name: "",                      // ユーザー名
      patrol_sentence: "",                // 巡回報告書文言
      empty_patrol_property: "",          // 巡回報告書がない物件名
      empty_monthly_property: "",         // 月次報告書がない物件名
      show_modal:false,                   // ローディング画面表示フラグ
      patrol_subject: false,              // 巡回報告書の有無
      monthly_subject: false,             // 月次報告書の有無
    }
  },
  methods: {
    update_address: function() {
      // selected_to_addresses_boolがすべてfalseの場合、宛先は登録されていませんとアラート表示
      if(this.selected_to_addresses_bool.every(value => value === false)){
        alert("宛先は登録されていません。宛先を選択してください。")
        return
      }
      this.master_to_addresses_bool = this.selected_to_addresses_bool.slice();
      this.master_cc_addresses_bool = this.selected_cc_addresses_bool.slice();
      this.mail_address = this.destination.to_addresses.filter((address, index) => this.master_to_addresses_bool[index]).join("; ");
      this.mail_cc_address = this.destination.cc_addresses.filter((address, index) => this.master_cc_addresses_bool[index]).join("; ");
      this.flg_change('destination_selected_flg')
    }, 
    update_address_cancel: function() {
      this.selected_to_addresses_bool = this.master_to_addresses_bool.slice();
      this.selected_cc_addresses_bool = this.master_cc_addresses_bool.slice();
      this.flg_change('destination_selected_flg')
    }, 
    async mail_sentence_edit () {
      this.show_modal = true;
      // 初期化
      this.url = '';
      this.empty_patrol_property = '';
      this.empty_monthly_property = '';

      alert("メール本文を作成します。少々お待ちください。")
      // 送信月が変更されたら件名、メール本文を変更する
      // this.formatted_send_monthを○○○○年○○月に変換。先頭の0を削除
      var year = this.mail_send_month.slice(0,4)
      var month = this.mail_send_month.slice(5,7)
      if(month.slice(0,1) == 0){
        month = month.slice(1,2)
      }
      this.formatted_send_month = year + "年" + month + "月";
      
      // 登録物件のURLを取得
      for(var i = 0; i < this.destination.destination_properties.length; i++){
        if(this.destination.destination_properties[i].patrol_flg){
          this.patrol_subject = true
        }
        if(this.destination.destination_properties[i].monthly_flg){
          this.monthly_subject = true
        }
        var property_id = this.destination.destination_properties[i].property_id
        var patrol_flg = this.destination.destination_properties[i].patrol_flg
        var monthly_flg = this.destination.destination_properties[i].monthly_flg

        const param = {
          method: "POST",
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + this.$store.state.login_token
          },
          body: JSON.stringify({
            name: this.destination.name,
            destination_property: {
              property_id: property_id,
              patrol_flg: patrol_flg,
              monthly_flg: monthly_flg
            },
            date: this.formatted_send_month
          })
        };
        var res = await fetch(process.env.VUE_APP_API_BASE_URL + "/email/report/confirm", param)
        if (res.status == 200) {
          var data = await res.json()
          // URLを取得
          var property_name = data.report.report_url.name;
          var patrol_url = data.report.report_url.patrol_url;
          var monthly_url = data.report.report_url.monthly_url;
          var others_url = data.report.report_url.others_url;

          const empty_url = "実施項目が無い為、報告書はございません。";

          if(patrol_url !== undefined && patrol_url === null){
              patrol_url = empty_url;
              this.empty_patrol_property += `${property_name}\n`;
          }
          if(monthly_url !== undefined && monthly_url === null){
              monthly_url = empty_url;
              this.empty_monthly_property += `${property_name}\n`;
          }
          if(others_url !== undefined && others_url === null){
              alert("その他のURLが取得できませんでした。\nOdrive内には、PDFファイルが10個、または、Zipファイルが1つ入れられます。\n別の拡張子ファイルが格納されていないか確認してください。\nまたは、odriveのゴミ箱を削除しているか確認してください。");
              this.show_modal = false;
              this.mail_send_month = "";
              return;
          }
          const patrol_sentence = "\n\nまた、巡回報告書をお送りいたしますのでご確認下さい。\nこちらの内容は指摘事項として清掃員に伝えております。";
          // 巡回報告書、月次報告書、その他のURLがある場合、URLをメール本文に追記
          if(patrol_flg == 1 || monthly_flg == 1 || others_url == 1){
            this.url += `\n${property_name}\n`;
            if(patrol_url !== undefined){
              this.url += `巡回報告書：${patrol_url}\n`;
              this.patrol_sentence = patrol_sentence;
            }
            if(monthly_url !== undefined){
              this.url += `月次報告書：${monthly_url}\n`;
            }
            if(others_url !== undefined && others_url !== null){
              this.url += `その他：${others_url}\n`;
            }
            this.url += "\n";
          }
        } else if (res.status == 401) {
          this.unauth()
        } else {
          this.other_error(res.status)
        }
      }

      // 巡回報告書がない物件がある場合、巡回報告書がない旨をメール本文に追記
      if(this.empty_patrol_property !== "" && this.empty_monthly_property !== "") {
        alert(`下記の物件で報告書URLを発行できませんでした。\nOdriveに該当のファイルが格納されているか確認してください。また、odriveのゴミ箱を削除しているか確認してください。\n\n【巡回報告書】\n${this.empty_patrol_property}\n【月次報告書】\n${this.empty_monthly_property}`);
      } else if(this.empty_patrol_property !== "") {
        alert(`下記の物件で報告書URLを発行できませんでした。\nOdriveに該当のファイルが格納されているか確認してください。また、odriveのゴミ箱を削除しているか確認してください。\n\n【巡回報告書】\n${this.empty_patrol_property}`);
      } else if(this.empty_monthly_property !== "") {
        alert(`下記の物件で報告書URLを発行できませんでした。\nOdriveに該当のファイルが格納されているか確認してください。また、odriveのゴミ箱を削除しているか確認してください。\n\n【月次報告書】\n${this.empty_monthly_property}`);
      }
      // もし、this.destination.destination_properties[i].patrol_flgとthis.destination.destination_properties[i].monthly_flgのどちらにもいずれかにtrueが含まれる場合、巡回・月次と表示する
      var patrol_month_subject = "";
      if(this.patrol_subject && this.monthly_subject){
        patrol_month_subject = "月次・巡回";
      } else if(this.patrol_subject){
        patrol_month_subject = "巡回";
      } else if(this.monthly_subject){
        patrol_month_subject = "月次";
      } else {
        patrol_month_subject = "月次";
      }

      // 件名を変更
      this.mail_subject = `${this.formatted_send_month}分の${patrol_month_subject}報告書の送付について`;
      // メール本文を変更
      this.mail_sentence = `${this.destination.name}様\n\nいつもお世話になっております。\n株式会社リアークスファインドの${this.user_name}でございます。\n\n${this.formatted_send_month}分の月次報告書をお送りいたします。${this.patrol_sentence}\n\n報告書につきましては以下URLからご確認お願い致します。\n期限：${this.url_limit_time}\n${this.url}\n\n◇◇◇◇◇◇◇◇◇◇◇◇◇◇◇◇◇◇◇◇\n\n★マンション管理組合運営★\nマンション管理組合・理事長様をご紹介いただけた場合紹介料をお支払いします！\n★マンションのリフォーム・リノベーション相談★\n稼働率向上、マンション購入時に弊社にて営繕工事を承ります！\n株式会社リアークスファインド管理事業部　${this.$store.state.user_name}\nTEL：03-6908-9064（直通）\n〒160-0023\n東京都新宿区西新宿7-7-30　小田急西新宿　O-PLACE 6F\n\n◇◇◇◇◇◇◇◇◇◇◇◇◇◇◇◇◇◇◇◇\n`;

      // disabledフラグをfalseにする
      this.disabled_flg = true;
      this.show_modal = false;
    },
    async submit_mail () {
      this.show_modal = true;
      console.log("送信します。")
      // 宛先、CCを配列に格納
      var selected_to_addresses = this.destination.to_addresses.filter((address, index) => this.master_to_addresses_bool[index]);
      var selected_cc_addresses = this.destination.cc_addresses.filter((address, index) => this.master_cc_addresses_bool[index]);
      // console.log(selected_to_addresses)
      // console.log(selected_cc_addresses)
      
      // メール送信
      const param = {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.$store.state.login_token
        },
        body: JSON.stringify({
          email_info: {
            destination: {
              to_addresses: selected_to_addresses, // 宛先
              cc_addresses: selected_cc_addresses  // CC
            },
            message: {
              body: {
                text: {
                  data: this.mail_sentence,// 本文
                  charset: "utf-8"
                }
              },
              subject: {
                data: this.mail_subject,// 件名
                charset: "utf-8"
              }
            },
            source: "rfbm@rearx-find.co.jp" //送信元(管理部固定メール)
          }
        })
      };
      const res = await fetch(process.env.VUE_APP_API_BASE_URL + "/email/send", param)
      if (res.status == 200) {
        alert("メール送信に成功しました。メールが送信されているか受信ボックスでご確認ください。")
        this.$router.push({ path: '/pc/destination', query: { destination_id: this.$route.query.destination_id } })
      } else if (res.status == 401) {
        this.unauth()
      } else if (res.status == 413) {
        alert("メール送信に失敗しました。文章が10MBを超えています。")
      } else {
        this.other_error(res.status)
      }
      this.show_modal = false;
    },
    flg_change(flg) {
      this[flg] = !this[flg]
    },
  },
  created: async function () {
    // 宛先情報取得
    const param = {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.$store.state.login_token
      },
    };
    // 送信先情報取得
    const res = await fetch(process.env.VUE_APP_API_BASE_URL + "/destination/" + this.$route.query.destination_id, param)
    if (res.status == 200) {
      var data = await res.json()
      // console.log(data)

      // 登録物件がない場合は編集画面に遷移させる。
      if(data.destination.destination_properties.length == 0){
        alert(`「${data.destination.name}」に登録物件がないため編集画面に遷移します。\n登録物件を追加してください。`);
        this.$router.push({ path: '/pc/destination/update', query: { destination_id: this.$route.query.destination_id } })
      } else {
        this.destination = data.destination
      }
      this.master_to_addresses_bool   = Array(data.destination.to_addresses.length).fill(true);
      this.master_cc_addresses_bool   = Array(data.destination.cc_addresses.length).fill(true);
      this.selected_to_addresses_bool = Array(data.destination.to_addresses.length).fill(true);
      this.selected_cc_addresses_bool = Array(data.destination.cc_addresses.length).fill(true);

      this.mail_address = this.destination.to_addresses.filter((address, index) => this.master_to_addresses_bool[index]).join("; ");
      this.mail_cc_address = this.destination.cc_addresses.filter((address, index) => this.master_cc_addresses_bool[index]).join("; ");
      // console.log(this.mail_address)
      // console.log(this.mail_cc_address)

      // this.$store.state.user_nameでユーザー名を取得。半角スペースまたは全角スペースで分割して、一つ目の要素をthis.user_nameに代入
      var user_name = this.$store.state.user_name.split(/\s/);
      this.user_name = user_name[0];

    } else if (res.status == 401) {
      this.unauth()
    } else {
      this.other_error(res.status)
    }
    // 現在時刻から7日後を期限とする
    var dt = new Date();
    dt.setDate(dt.getDate() + 6);
    this.url_limit_time = dt.getFullYear() + "年" + (dt.getMonth() + 1) + "月" + dt.getDate() + "日";
  }
}
</script>
<style scoped>
.modal_content {
  height: fit-content;
  text-align: center;
}
.modal_content ul {
  margin: 0 auto;
  width: 75%;
  text-align: center;
}
ul{
  margin: 0;
  padding: 0;
}
li {
  list-style: none;
}
.flex {
  display: flex;
  justify-content: center;
  margin: 10px auto 0 auto;
  width: 30%;
  text-align: center;
}

.flex_box {
  margin: 0 auto;
  width: 75%;
  text-align: center;
}
.flex_mail {
  margin: 0 auto;
  width: 75%;
  text-align: center;
}
.modal_text{
  margin-top: 40px;
}
.mail_title{
  padding: 0;
}
.mail_title li {
  display: flex;
  justify-content: left;
  margin: 10px auto 0 auto;
  width: 100%;
  text-align: center;
}
#date_items{
    display: flex;
    justify-content: center;
    border-bottom: 5px solid rgb(118,118,118);
    text-align: center;
    margin: 0;
    padding: 10px 0px;
    border-radius: 0px 0px 5px 5px;
}
</style>