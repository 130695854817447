<template>
    <ul id="photo_items">
        <li class="photo_item" v-for="(item,index) of institution.items" :key="item">
            <p v-if='item.select'>写真{{photoNumView(index)}}</p>
            <img :src="item.photo">
            <p>{{phrase_ja_name(item.phrase)}}</p>
            <p>{{item.text}}</p>
        </li>
    </ul>
</template>

<script scoped>

export default ({
    props: {
        institution: {
            type: Object
        }
    },
    methods: {
        phrase_ja_name(phrase_num) {
            switch (phrase_num) {
                case "0":
                    return "異常なし"
                case "1":
                    return "掲示物の掲示"
                case "2":
                    return "私物放置のため短冊貼り付け"
                case "3":
                    return "違法駐輪のため短冊貼り付け"
                case "4":
                    return "粗大ごみ放置のため短冊貼り付け"
                case "5":
                    return "ごみ、汚れがあったため対応済み"
            }
        },
        photoNumView(index) {
            var count = 0;
            for (let i = 0; i < index + 1; i++) {
                if (this.institution.items[i].select == true) {
                    count++;
                }
            }
            return count;
        },
    }
})
</script>

<style scoped>
h2 {
    margin: 0;
    padding: 10px 0;
    width: 100%;
    color: #1a3f66ff;
}

ul {
    padding: 0;
}

li {
    list-style: none;
}

img {
    width: 100%;
}

.title {
    display: flex;
    background-color: #1a3f66ff;
    position: relative;
    height: 56px;
}

.font_14 {
    font-size: 14px;
}

#photo_items {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
}

.photo_item {
    border: solid 5px rgba(26, 63, 102, 0.823);
    border-radius: 10px;
    margin-top: 15px;
}
</style>