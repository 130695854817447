<template>
<div>
  <div class="title">
    <h2>{{ institution.ja_name }}</h2>
    <button class="flg_btn" @click="flg_change('item_flg')">{{ item_flg_text }}</button>
  </div>
  <div class="institution_info" v-if=item_flg>
    <div class="past_report" v-show="past_report_check">
      <button class="orange_btn margin_bottom_0" @click="past_flg_change()" v-if="past_data_check(past_institution)"
        v-show="isPast">前回の確認</button>
      <div v-if="past_flg_test == true && item_flg == true" class="modal">
        <div class="modal_past_content">
          <h3 class="modal_title">前回報告書確認</h3>
          <div class="modal_info">
            <past_item :institution="past_institution"></past_item>
            <button class="modal_close" @click="past_flg_change()">閉じる</button>
          </div>
        </div>
      </div>
    </div>
    <div class="photo_title">
      <ul id="photo_items">
        <li class="photo_item" v-for="(item, index) of institution.items" :key="item">
          <!-- 写真の選択と表示 -->
          <label class="file_label">
            <input class="margin_top file_select" type="file" ref="preview"
              @change="institution_input_base64($event.target.files[0], institution, index, $event)">ファイルを選択
          </label>
          <img :src="item.photo">
          <!-- 定型文の選択 -->
          <select class="phrase" v-model="item.phrase" required>
            <option value="" hidden>定型文を選択してください</option>
            <option value="0">異常なし</option>
            <option value="1">掲示物の掲示</option>
            <option value="2">私物放置のため短冊貼り付け</option>
            <option value="3">違法駐輪のため短冊貼り付け</option>
            <option value="4">粗大ごみ放置のため短冊貼り付け</option>
            <option value="5">ごみ、汚れがあったため対応済</option>
          </select>
          <!-- 内容（テキスト） -->
          <p>内容</p>
          <textarea class="free_text" v-model="item.text" placeholder="内容を記述してください" rows="5" maxlength="140"></textarea>
          <!-- 報告書に添付するかチェックボックス -->
          <label class="select_check_box" :for='"photo_" + index' style="margin-top: 10px;">
            <input class="check_box" type="checkbox" :id='"photo_" + index' v-model="item.select"
              :disabled=Boolean(!item.photo)>
            <p>報告書に添付</p>
          </label>
          <!-- 項目削除 -->
          <br><br>
          <button class="delete_btn" v-if="institution.items[index]" @click="delete_institution_item(institution, index)">この項目を消去</button>
        </li>
      </ul>
      <!-- 複数選択 -->
      <label class="file_label" v-if="institution.items.length < 8">
        <input class="file_select" type="file" @change="add_institution_photo($event, institution)" multiple="multiple">写真を選択
      </label>
    </div>
    <p class="font_14">※社内で共有したい内容はこちらに書いてください</p>
    <textarea class="free_text" v-model="institution.memo" rows="5" cols="100" placeholder="メモ内容（※報告書には記載されません）" maxlength="140"></textarea>
  </div>
</div>
</template>

<script scoped>
import past_item from "./report_input_before_component.vue"
import { Mixin } from "../mixin"

export default ({
  data() {
    return {
      item_flg: false,
      item_flg_text: "＋",
      info: this.institution,
      past_flg_test: false,
      photoCountArray: [],
      isPast: true,
      isSelect: false,
    }
  },
  mixins: [Mixin],
  components: {
    past_item
  },
  props: {
    institution: {
    },
    past_institution: {
    },
    past_report_check: {
    },
  },
  created: function () {
    if (this.institution) {
      for (let item of this.institution.items) {
        this.photoCountArray.push(item.select)
      }
    }
  },
  methods: {
    past_flg_change() {
      this.past_flg_test = !this.past_flg_test
    },
    past_data_check(data) {
      if (data === undefined) {
        return false
      } else {
        if (data.items.length == 0) {
          this.isPast = false;
        }
        return true
      }
    },
    flg_change(flg) {
      this[flg] = !this[flg]
      if (this[flg + '_text'] == "＋") {
        this[flg + '_text'] = "－";
      } else {
        this[flg + '_text'] = "＋";
      }
    },
    reCountPhotoNum(index, selected) {
      this.photoCountArray[index] = selected;
    },
    photoNumView(index) {
      var count = 0;
      for (let i = 0; i < index + 1; i++) {
        if (this.photoCountArray[i] == true) {
          count++;
        }
      }
      return count;
    },
  },

})
</script>

<style scoped>
.margin_bottom_0 {
  margin-bottom: 0 !important;
}

h2 {
  margin: 0;
  padding: 10px 0;
  width: 100%;
  color: #fff;
}

li {
  list-style: none;
}

img {
  width: 100%;
  margin-top: 30px;
}

select:invalid {
  color: red;
}

select option {
  color: black;
}

.select_check_box {
  display: flex;
}

.select_check_box input {
  width: 10%;
  margin: 10px 0 10px 50px;
}

.select_check_box p {
  width: 80%;
  font-size: 22px;
  margin-right: 10px;
}

.title {
  display: flex;
  background-color: #1a3f66ff;
  position: relative;
  height: 56px;
}

input {
  width: 90%;
  margin-top: 20px;
}

.check_box {
  width: 100%;
}

.font_14 {
  font-size: 14px;
}

.margin_top {
  margin-top: 20px;
}

.flg_btn {
  font-size: 40px;
  background-color: #1a3f66ff;
  border: none;
  color: #fff;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0;
  height: 56px;
  width: 56px;
}

.file_select {
  display: none;
}

.free_text {
  margin-bottom: 10px;
  border: 1px grey solid;
  font-size: 16px;
  width: 90%;
}

.phrase {
  width: 90%;
  font-size: 16px;
  margin-top: 20px;
}

#photo_items {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin-bottom: 10px;
  background: #fff;
}

.photo_item {
  border: solid 3px rgba(26, 63, 102, 0.823);
  border-radius: 10px;
  margin-top: 10px;
}

.orange_btn {
  color: #fff;
  background-color: #eb6100;
  border-radius: 10px;
  padding: 10px 0px;
  width: 90%;
  border: none;
  margin: 20px;
}

.delete_btn {
  background-color: #1a3f66ff;
  font-size: 16px;
  color: #fff;
  border: none;
  border-radius: 5px;
  margin-bottom: 5px;
}

.modal_past_content {
  width: 90%;
  background: #fff;
  border-radius: 20px;
  margin: 200px 0 10px;
}

.modal_close {
  border: none;
  background-color: rgba(226, 226, 226, 1.0);
  border-radius: 10px;
  width: 75%;
  padding: 20px 0px;
  margin: 10px 0 50px 0;
}

.file_select {
    display: none;
}

.file_label {
    display: inline-block;
    padding: 5px;
    margin-top: 15px;
    color: black;
    background-color: lightgray;
    cursor: pointer;
    border: 1px solid black;
    border-radius: 5px;
}
</style>