<template>
<div>
    <phone_head title="確定画面" :history_back=true></phone_head>
    <h2>以下の内容で保存しますか?</h2>
    <div class="wrapper pdf" ref="capture">
        <div class="title" style="text-align: center;">
            <h2 style="text-align: center">巡回報告書</h2>
            <table border="1" style="border-collapse:collapse; width:100%; text-align: center;">
                <tr>
                    <th style="width:25%" class="list">巡回日</th>
                    <td class="list">{{ date }}</td>
                </tr>
                <tr>
                    <th style="width:25%" class="list">物件名(所在地)</th>
                    <td class="list">{{ info.name }}({{ info.address }})</td>
                </tr>
                <tr>
                    <th style="width:25%" class="list">実施者(担当者)</th>
                    <td class="list">株式会社リアークスファインド({{ info.person }})</td>
                </tr>
            </table>
        </div>
        <div>
            <!-- <h2>【報告事項】</h2> -->
            <div v-for="institution in institutions" :key="institution">
                <past_item :institution=institution></past_item>
            </div>
            <p>今後ともマンションの美化向上のためご協力の程、何卒よろしくお願いいたします</p>
            <h2 style="text-align: center">【共有事項】</h2>
            <share_item :share_photo="share_photo"></share_item>
            <p class="text_left">{{ share_text }}</p>
        </div>
        <p>上記について、今後入居者様から問い合わせ等が発生する可能性がございます。その際は弊社までご連絡いただけますと幸いです</p>
        <p style="text-align: right;">以上</p>
    </div>
    <!-- <button @click="pdfmake()">PDFを出力</button> -->
    <h3 style="color:red;" v-if="error_text">写真データが登録されていません。</h3>
    <!-- ボタンリスト -->
    <div id="btn_list">
        <button class="orange_btn" @click="update_report()" :disabled="btn_clicked">保存する</button>
        <button class="orange_btn" @click="this.$router.go(-1)" style="background-color:rgba(26, 63, 102) ;">戻る</button>
    </div>
    <loading_circle :show_modal="show_modal"></loading_circle>
</div>
</template>
<script scoped>
import { Mixin } from "../mixin";
import phone_head from "./phone_header.vue"
import past_item from "./report_report_component.vue"
import share_item from "./report_report_share_component.vue"
import { db } from "../db";
import pdfMake from '../../public/pdfmake/pdfmake';
import pdfFonts from '../../public/pdfmake/vfs_fonts';
import htmlToPdfmake from "html-to-pdfmake";
import loading_circle from "./loading_circle.vue";

export default ({
    components: {
        phone_head,
        past_item,
        share_item,
        loading_circle,
    },
    mixins: [Mixin],
    data() {
        return {
            report_data: {},
            info: {},
            institutions: [],
            others_photo_name: [],
            others_photo: [],
            share_photo_name: [],
            share_photo: [],
            share_text: "",
            approve_flg: false,
            fix_flg: false,
            data: {},
            today: '',
            filter_institutions: [],
            btn_clicked: false,
            show_modal: false,
            error_text: false,
            date:"",
        }
    },
    methods: {
        flg_change(flg) {
            this[flg] = !this[flg]
        },
        async update_report() {
            if (!Object.keys(this.institutions).length == true && this.share_photo.length == 0) {
                this.error_text = true;
            } else {
                this.show_modal = true;
                this.btn_clicked = true
                var submit_data = await db.report_db.get(1)
                // console.log(submit_data)
                if (this.$route.query.mode == 'update') {
                    console.log('報告書更新')
                // 報告書更新処理
                    // 写真データを分離(institution)
                    var photo_data_name = []
                    var photo_data = []
                    for (let institution in submit_data.institutions) {
                        var data = [];
                        var data_name = [];
                        for (var s = 0; s < submit_data.institutions[institution].items.length; s++) {
                            // photo_nameがある物のみ以下の処理を行う
                            if (submit_data.institutions[institution].items[s].photo_name) {
                                // 写真データ+名称取り出し
                                data.push(submit_data.institutions[institution].items[s].photo);
                                data_name.push(submit_data.institutions[institution].items[s].photo_name);
                                // 写真キー削除
                                delete submit_data.institutions[institution].items[s].photo;
                                delete submit_data.institutions[institution].items[s].photo_name;
                            }
                        }
                        photo_data.push(data);
                        photo_data_name.push(data_name);
                    }
                    // その他写真の追加部分の写真データ分離
                    if (submit_data.others_photo.photo_name !== undefined && submit_data.others_photo.photo_name.length > 0) {
                        var others_photo_data_name = []
                        var others_photo_data = []
                        for (var t = submit_data.others_photo.photo_name.length - 1; t >= 0; t--) {
                            if (typeof submit_data.others_photo.photo_name[t] !== 'undefined') {
                                // 写真データ+名称取り出し
                                others_photo_data.push(submit_data.others_photo.photo[t]);
                                others_photo_data_name.push(submit_data.others_photo.photo_name[t]);
                                // 写真の削除
                                submit_data.others_photo.photo.splice(t, 1);
                                submit_data.others_photo.photo_name.splice(t, 1);
                            }
                        }
                        //  配列の順番を逆にする
                        Array.prototype.reverse.call(others_photo_data)
                        Array.prototype.reverse.call(others_photo_data_name)
                    }

                    // 共有事項写真の追加部分の写真データ分離
                    if (submit_data.share.photo_name !== undefined && submit_data.share.photo_name.length > 0) {
                        var share_photo_data_name = []
                        var share_photo_data = []
                        for (var u = submit_data.share.photo_name.length - 1; u >= 0; u--) {
                            if (typeof submit_data.share.photo_name[u] !== 'undefined') {
                                // 写真データ+名称取り出し
                                share_photo_data.push(submit_data.share.photo[u]);
                                share_photo_data_name.push(submit_data.share.photo_name[u]);
                                // 写真の削除
                                submit_data.share.photo.splice(u, 1)
                                submit_data.share.photo_name.splice(u, 1)
                            }
                        }
                        // 配列の順番を逆にする
                        Array.prototype.reverse.call(share_photo_data)
                        Array.prototype.reverse.call(share_photo_data_name)
                    }

                    // 報告書情報送信
                    const param = {
                        method: "PUT",
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + this.$store.state.login_token
                        },
                        // 送信データは施設情報＋共有メモ
                        body: JSON.stringify({
                            report: {
                                institutions: submit_data.institutions,
                                others_photo: submit_data.others_photo.photo,
                                share: {
                                    text: submit_data.share.text,
                                    photo: submit_data.share.photo
                                },
                                internal_memo: submit_data.internal_memo,
                                date: submit_data.info.date,
                                status: submit_data.status
                            }
                        })
                    }
                    // console.log(param)
                    const res = await fetch(process.env.VUE_APP_API_BASE_URL + "/report/update/" + this.$route.query.property_id + this.$route.query.report_id, param)
                    if (res.status == 200) {
                        // 報告一覧に戻る
                    } else if (res.status == 401) {
                        this.unauth()
                    } else {
                        this.other_error(res.status)
                    }

                    // 施設の数＋写真データがあるものだけを送る
                    var i = 0;
                    for (let institution in submit_data.institutions) {
                        // 報告書情報送信
                        var params = {
                            method: "POST",
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': 'Bearer ' + this.$store.state.login_token
                            },
                            // 送信データは施設情報＋共有メモ
                            body: JSON.stringify({
                                institution: institution,
                                photo: photo_data[i],
                                name: photo_data_name[i]
                            })
                        }
                        // 写真データがあるものだけを送信する
                        if (photo_data[i] !== undefined && photo_data[i].length > 0) {
                            console.log("photo trans");
                            const res = await fetch(process.env.VUE_APP_API_BASE_URL + "/photo/add/" + this.$route.query.property_id + this.$route.query.report_id, params)
                            if (res.status == 200) {
                                // 一回でも送信に失敗したら停止
                                console.log("Photo trans success");
                            } else if (res.status == 401) {
                                console.log('失敗しました')
                                this.unauth()
                            } else {
                                console.log('失敗しました')
                                this.other_error(res.status)
                            }
                        }
                        i++;
                    }
                    // その他写真
                    // 写真データがあるものだけを送信する
                    if (others_photo_data !== undefined && others_photo_data.length > 0) {
                        var params = {
                            method: "POST",
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': 'Bearer ' + this.$store.state.login_token
                            },
                            // 送信データは施設情報＋共有メモ
                            body: JSON.stringify({
                                institution: "others_photo",
                                photo: others_photo_data,
                                name: others_photo_data_name
                            })
                        }
                        const res = await fetch(process.env.VUE_APP_API_BASE_URL + "/photo/add/" + this.$route.query.property_id + this.$route.query.report_id, params)
                        if (res.status == 200) {
                            // 一回でも送信に失敗したら停止
                            console.log("trans success");
                        } else if (res.status == 401) {
                            this.unauth()
                        } else {
                            this.other_error(res.status)
                        }
                    }

                    // 共有
                    // 写真データがあるものだけを送信する
                    if (share_photo_data !== undefined && share_photo_data.length > 0) {
                        var params = {
                            method: "POST",
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': 'Bearer ' + this.$store.state.login_token
                            },
                            // 送信データは施設情報＋共有メモ
                            body: JSON.stringify({
                                institution: "share",
                                photo: share_photo_data,
                                name: share_photo_data_name
                            })
                        }
                        // console.log(this.$route.query.property_id + this.$route.query.report_id) 
                        const res = await fetch(process.env.VUE_APP_API_BASE_URL + "/photo/add/" + this.$route.query.property_id + this.$route.query.report_id, params)
                        if (res.status == 200) {
                            // 一回でも送信に失敗したら停止
                            console.log("trans success");
                        } else if (res.status == 401) {
                            this.unauth()
                        } else {
                            this.other_error(res.status)
                        }
                    }
                    // 物件一覧画面にに戻る
                    this.move_screen('/property/list')
                } else {
                    console.log('報告書追加')
                    // 報告書追加処理
                    // 写真データを分離(institution)
                    var photo_data_name = []
                    var photo_data = []
                    for (let institution in submit_data.institutions) {
                        var data = [];
                        var data_name = [];
                        for (var s = 0; s < submit_data.institutions[institution].items.length; s++) {
                            // 写真データ+名称取り出し
                            data.push(submit_data.institutions[institution].items[s].photo);
                            data_name.push(submit_data.institutions[institution].items[s].photo_name);
                            // 写真キー削除
                            delete submit_data.institutions[institution].items[s].photo;
                            delete submit_data.institutions[institution].items[s].photo_name;
                        }
                        photo_data.push(data);
                        photo_data_name.push(data_name);
                        // console.log(submit_data)
                    }

                    // 報告書情報送信
                    const param = {
                        method: "POST",
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + this.$store.state.login_token
                        },
                        // 送信データは施設情報＋共有メモ
                        body: JSON.stringify({
                            report: {
                                institutions: submit_data.institutions,
                                share: {
                                    text: submit_data.share.text,
                                },
                                internal_memo: submit_data.internal_memo,
                                date: submit_data.date
                            }
                        })
                    }
                    const res = await fetch(process.env.VUE_APP_API_BASE_URL + "/report/add/" + this.$route.query.property_id, param)
                    if (res.status == 200) {
                        // データ受領(レポートID取得)
                        const response_data = await res.json();
                        // console.log(response_data)
                        var report_id = response_data.report_id;

                        // 施設の数＋写真データがあるものだけを送る
                        var i = 0;
                        for (let institution in submit_data.institutions) {

                            // 報告書情報送信
                            var params = {
                                method: "POST",
                                headers: {
                                    'Content-Type': 'application/json',
                                    'Authorization': 'Bearer ' + this.$store.state.login_token

                                },
                                // 送信データは施設情報＋共有メモ
                                body: JSON.stringify({
                                    institution: institution,
                                    photo: photo_data[i],
                                    name: photo_data_name[i]
                                }
                                )
                            }
                            // 写真データがあるものだけを送信する
                            if (photo_data[i] !== undefined && photo_data[i].length > 0) {
                                console.log("photo trans");
                                const res = await fetch(process.env.VUE_APP_API_BASE_URL + "/photo/add/" + report_id, params)
                                if (res.status == 200) {
                                    // 一回でも送信に失敗したら停止
                                    console.log("Photo trans success");
                                } else if (res.status == 401) {
                                    this.unauth()
                                } else {
                                    this.other_error(res.status)
                                }
                            }
                            i++;
                        }

                        // その他写真
                        var params = {
                            method: "POST",
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': 'Bearer ' + this.$store.state.login_token
                            },
                            // 送信データは施設情報＋共有メモ
                            body: JSON.stringify({
                                institution: "others_photo",
                                photo: this.others_photo,
                                name: this.others_photo_name
                            })
                        }
                        // 写真データがあるものだけを送信する
                        if (this.others_photo !== undefined && this.others_photo.length > 0) {
                            const res = await fetch(process.env.VUE_APP_API_BASE_URL + "/photo/add/" + report_id, params)
                            //this.status_res(res.status)
                            if (res.status == 200) {
                                // 一回でも送信に失敗したら停止
                                console.log("trans success");
                            } else if (res.status == 401) {
                                this.unauth()
                            } else {
                                this.other_error(res.status)
                            }
                        }
                        // 共有
                        var params = {
                            method: "POST",
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': 'Bearer ' + this.$store.state.login_token
                            },
                            // 送信データは施設情報＋共有メモ
                            body: JSON.stringify({
                                institution: "share",
                                photo: this.share_photo,
                                name: this.share_photo_name
                            })
                        }
                        // 写真データがあるものだけを送信する
                        if (this.share_photo !== undefined && this.share_photo.length > 0) {
                            const res = await fetch(process.env.VUE_APP_API_BASE_URL + "/photo/add/" + report_id, params)
                            if (res.status == 200) {
                                // 一回でも送信に失敗したら停止
                                console.log("trans success");
                            } else if (res.status == 401) {
                                this.unauth()
                            } else {
                                this.other_error(res.status)
                            }
                        }
                        // 物件一覧画面にに戻る
                        this.move_screen('/property/list')

                    } else if (res.status == 401) {
                        this.unauth()
                    } else {
                        this.other_error(res.status)
                    }
                }
            }
        },
        pdfmake: async function () {
            // DOMをHTMLで取得
            var html = document.getElementsByClassName('pdf')[0].cloneNode(true);

            // href や src に指定されたURLを絶対パスに変換
            var nodes = html.querySelectorAll('[href],[src]');
            for (var i = 0, n = nodes.length; i < n; i++) {
                if (nodes[i].href) { nodes[i].href = nodes[i].href; }
                if (nodes[i].src) { nodes[i].src = nodes[i].src; }

            }

            // ソースコードをテキストで取得
            var src = html.innerHTML;

            // 上記の src には DOCTYPE が含まれていないので別途用意
            var name = document.doctype.name;
            var publicId = document.doctype.publicId;
            var systemID = document.doctype.systemId;
            var doctype = '<!DOCTYPE ' + name
                + (publicId ? ' PUBLIC "' + publicId + '"' : '')
                + (systemID ? ' "' + systemID + '"' : '')
                + '>';
            // <html> タグを再構成
            var htmlTag = '<html';
            var attrs = html.attributes;
            for (var i = 0, n = attrs.length; i < n; i++) {
                var attr = attrs[i];
                htmlTag += ' ' + attr.nodeName + (attr.nodeValue ? '="' + attr.nodeValue + '"' : '');
            }
            htmlTag += '>';

            pdfMake.vfs = pdfFonts.pdfMake.vfs;
            pdfMake.fonts = {
                // 日本語＋Robotが使用可能なフォントを設定
                MeiryoWithMircosoftYahei: {
                    normal: 'MeiryoWithMircosoftYahei.ttf',
                    bold: 'MeiryoWithMircosoftYahei.ttf'
                },
                Roboto: {
                    normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
                    bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
                    italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
                    bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
                }
            };
            const defaultStyle = 'MeiryoWithMircosoftYahei';
            const ret = htmlToPdfmake(htmlTag + src + '</html>', {
                imagesByReference: true,
                tableAutoSize: true,
            })
            const docDefinition = {
                content: ret.content,
                images: ret.images,
                defaultStyle: { font: defaultStyle },
                pageSize: 'A4',
                // 改ページ
                // pageBreakBefore: function (currentNode) {
                //     return currentNode.style && currentNode.style.indexOf('pdf-pagebreak-before') > -1;
                // },
            };
            // console.log(docDefinition.content);
            var table_length = 0;
            for (var j = 0; j < docDefinition.content[1].stack.length; j++) {
                if (docDefinition.content[1].stack[j].nodeName == 'DIV') {
                    table_length += 1;
                }
            }
            console.log(table_length);
            for (var i = 0; i < table_length - 1; i++) {
                docDefinition.content[1].stack[i].stack[0].stack[0].table.headerRows = 1;
                docDefinition.content[1].stack[i].stack[0].stack[0].table.keepWithHeaderRows = true;
            }
            // docDefinition.content[1].stack[1].stack[0].stack[0].table.dontBreakRows = true;
            // console.log(docDefinition.content)
            // PDF変換
            // pdfMake.createPdf(docDefinition).download(this.info.name + this.today + ".pdf");
            const pdfDocGenerator = pdfMake.createPdf(docDefinition);
            let promise = new Promise((resolve) => {
                pdfDocGenerator.getBuffer((buffer) => {
                    return resolve(buffer)
                });
            })
            return await promise
        }
    },
    created: async function () {
        // indexedDBからデータを取得
        const back_data = await db.report_db.get(1)
        var json_data = JSON.stringify(back_data)
        var report_data = JSON.parse(json_data)
        // console.log(report_data)
        this.info = report_data.info
        this.institutions = report_data.institutions
        this.share_photo_name = report_data.share.photo_name
        this.share_photo = report_data.share.photo
        this.share_text = report_data.share.text
        this.others_photo_name = report_data.others_photo.photo_name
        this.others_photo = report_data.others_photo.photo
        if (this.$route.query.mode == 'update') {
            this.date = report_data.info.date
        } else{
            this.date = report_data.date
            console.log('報告書作成')
        }

        for (let institution in this.institutions) {
            if (this.institutions[institution].items.length !== 0) {
                // 1要素ごとの処理
                for (let i = this.institutions[institution].items.length - 1; i >= 0; i--) {
                    // ""の状態だとpdf出力の時に写真が反映されないため、" "を挿入した。
                    if (!this.institutions[institution].items[i].text) {
                        this.institutions[institution].items[i].text = " ";
                    }
                    // 報告書に記載の項目抽出
                    if (!this.institutions[institution].items[i].select) {
                        this.institutions[institution].items.splice(i, 1)
                    }
                }
            }
            // itemsがない時は項目を削除する
            if (this.institutions[institution].items.length == 0) {
                delete this.institutions[institution]
            }
        }
    },
})
</script>

<style scoped>
.list {
    font-size: 0.8em;
}

.wrapper {
    width: 95%;
}

th {
    width: 20%;
}

.wid70 {
    width: 70%;
}

td {
    width: 70%;
    text-align: left;
}

ul {
    padding: 0;
}

li {
    list-style: none;
}

h3 {
    margin: 50px 0 30px;
}

.text_left {
    text-align: left;
}

#share_items {
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    width: 100%;
}

.share_item {
    width: 48%;
    margin: 0.5%;
    border: solid 5px rgba(26, 63, 102);
    border-radius: 10px;
}

#btn_list {
    display: flex;
    justify-content: space-around;
}

.orange_btn {
    margin: 15px;
    width: 45%;
}
</style>