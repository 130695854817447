<template>
    <main>
        <pc_head title="報告入力画面（過去)" path="/pc/report/list"></pc_head>
        <div class="wrapper">
            <div class="pc_input_overview">
                <p>　日付：{{ info.date }}</p>
                <p>担当者：{{ info.person }}</p>
                <p>物件名：{{ info.name }}</p>
            </div>
            <!-- 施設項目 -->
            <ul id="item_info" v-for="(institution,key) in institutions" :key="institution">
                <input_item :institution="institution" :past_institution="past_institutions[key]"
                    :past_report_check="past_report_flag"></input_item>
            </ul>
            <!-- その他写真 -->
            <div class="title">
                <h2>その他写真</h2>
                <button class="flg_btn" @click="flg_change('others_photo_flg')">{{others_photo_flg_text}}</button>
            </div>
            <div v-if="others_photo_flg">
                <ul id="others_photo_items">
                    <li v-for="photo of others_photo" :key="photo" style="width:23%;">
                        <img :src="photo">
                    </li>
                </ul>
            </div>
            <!-- 共有事項 -->
            <div class="title margin">
                <h2>共有事項</h2>
                <button class="flg_btn" @click="flg_change('share_flg')">{{share_flg_text}}</button>
            </div>
            <div v-if="share_flg">
                <ul id="share_items">
                    <li v-for="(share_item,index) of share_photo" :key="share_item" style="width:23%;">
                        <p>写真{{index+1}}</p>
                        <img :src="share_item" alt="">
                    </li>
                </ul>
                <h3>共有事項内容</h3>
                <p>{{share_text}}</p>
                <h3>社内向け共有事項</h3>
                <p>{{internal_memo}}</p>
            </div>
            <!-- ボタン -->
            <div id="btn_list">
                <button class="orange_btn" @click="move_screen({ path: '/pc/report/input/update', query: { property_id: this.$route.query.property_id, report_id: this.$route.query.report_id, data_keep_flg: 'false' } })">修正する</button>
                <button class="orange_btn" @click="flg_change('delete_flg')">削除する</button>
                <div v-if="delete_flg" class="modal">
                    <div class="modal_content">
                        <h3 class="modal_title">報告書を削除する</h3>
                        <h4 style="color:red;">下記の物件を本当に削除しますか？</h4>
                        <p>物件名：{{ info.name }}</p>
                        <p>日付：{{ info.date }}</p>
                        <button class="orange_btn" @click="report_delete()">削除する</button>
                        <button class="modal_close" @click="flg_change('delete_flg')">キャンセル</button>
                    </div>
                </div>
                <button class="orange_btn" @click="move_screen('/pc/report/list')" style="background-color: #1a3f66ff">戻る</button>
            </div>
        </div>
    </main>
</template>
<script scoped>
import { Mixin } from "../mixin";
import input_item from "./pc_report_input_past_component.vue"
import pc_head from "./pc_header.vue"

export default ({
    mixins: [Mixin],
    components: {
        input_item,
        pc_head
    },
    data() {
        return {
            info: {},
            institutions: {},
            past_institutions: {},
            flg_btn_text: "＋",
            others_photo: [],
            others_photo_flg: false,
            others_photo_flg_text: "＋",
            share_photo: [],
            share_text: '',
            share_flg: false,
            share_flg_text: "＋",
            past_report_flag: true,
            delete_flg: false,
        }
    },
    methods: {
        flg_change(flg) {
            this[flg] = !this[flg]
            if (this[flg + '_text'] == "＋") {
                this[flg + '_text'] = "－";
            } else {
                this[flg + '_text'] = "＋";
            }
        },

    },
    created: async function () {
        const param = {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + this.$store.state.login_token
            }
        };
        // 報告書情報取得
        const res = await fetch(process.env.VUE_APP_API_BASE_URL + "/report/" + this.$route.query.property_id + this.$route.query.report_id, param)
        if (res.status == 200) {
            var data = await res.json()
            // console.log(data)
            this.info = data.report.info
            // 施設ごとの処理
            for (let institution in data.report.institutions) {
                var institution_flg = false
                // 1要素ごとの処理
                for (let i = 0; i < data.report.institutions[institution].items.length; i++) {
                    // items要素がない場合項目を消去する
                    if (data.report.institutions[institution].items.length !== 0) {
                        institution_flg = true
                    }
                }
                // メモが空でない時表示させる
                if (data.report.institutions[institution].memo) {
                    institution_flg = true
                }
                // 全要素、メモが空でなければ表示させる
                if (institution_flg) {
                    this.institutions[institution] = data.report.institutions[institution]
                }
            }
            this.others_photo = data.report.others_photo
            this.share_text = data.report.share.text
            // internal_memoプロパティがない（既存の報告書）場合の処理
            if(!data.report.hasOwnProperty('internal_memo')){
                this.internal_memo = ''
                console.log('追加しました')
            }else{
                this.internal_memo = data.report.internal_memo
            }
            this.share_photo = data.report.share.photo

            // 前回の報告書情報取得
            this.last_report_info();
        } else if (res.status == 401) {
            this.unauth()
        } else {
            this.other_error(res.status)
        }
    },
})
</script>

<style scoped>
h2 {
    margin: 0;
    padding: 10px 0;
    width: 100%;
    color: #fff;
}

.title {
    display: flex;
    background-color: #1a3f66ff;
    position: relative;
}

.flg_btn {
    font-size: 40px;
    background-color: #1a3f66ff;
    border: none;
    color: #fff;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 0;
    height: 56px;
    width: 56px;
}

#share_items,
#others_photo_items {
    display: flex;
    flex-wrap: wrap;
}

ul {
    padding: 0
}

li {
    list-style: none;
}

a {
    text-decoration: none;
}

img {
    width: 90%;
}

.margin {
    margin-top: 15px;
}
</style>